export const API: Record<string, string> = {
  LOGIN: 'auth/v1/login/',
  USER_PROFILE: 'auth/v1/profile_login/',
  GUEST_lOGIN: '/auth/v1/searchlogin/',
  LOGOUT: 'auth/v1/logout/',
  EMAIL_VERIFICATION: 'auth/v1/email_verification/',
  SIGN_UP: 'auth/v1/signup/',
  TELL_ME_MORE: 'auth/v1/email_verification/',
  FORGET_PASSWORD: 'auth/v1/forgot_password/',
  GET_NOTIFICATIONS_ALERTS: 'digest/v1/push_notifications/',
  GET_NOTIFICATIONS_FILTERS: 'digest/v1/push_notification_filters/',
  SAVE_WEB_DEVICE_TOKEN: 'auth/v1/save_web_device_token/',
  GET_LAUNCHPAD_COMPANIES: 'digest/v1/launchpad_companies/',
  GET_RECOMMENDED_COMPANIES: 'digest/v1/recommended_companies/',
  FOLLOW_COMPANIES: 'company/v1/add/',
  REMOVE_COMPANIES: 'company/v1/remove/',
  COMPANY_PROFILE: 'company/v1/company_profile/',
  COMPANY_SEC_FILINGS: 'company/v1/company_sec_filing/',
  COMPANY_PULSE: 'company/v1/company_pulse/',
  COMPANY_INTENT_DATA: 'company/v1/company_intent/',
  COMPANY_TOP_ENTITIES: 'company/v1/company_executives_competitors/',
  COMPANY_DOWNLOAD_PROFILE: 'company/v1/download_company_ppt_pdf/',
  COMPANY_STOCK_CHART: 'company/v1/stock_chart/',
  GLOBAL_SEARCH_RESULTS: 'search/v1/search_content/',
  GLOBAL_SEARCH_CONTENT: 'search/v1/search_topics/',
  UPDATE_GILROY_TOOLTIP: 'search/v1/update_gilroy_tooltip/',
  GET_SPOTLIGHT: 'digest/v1/spotlight/',
  GET_SPOTLIGHT_PULSE: 'digest/v1/spotlight_pulse/',
  GET_TOP_ENTITIES: 'digest/v1/spotlight_top_entities/',
  GET_FEED_INTENT_DATA: 'digest/v1/spotlight_intent/',
  GET_FEED_ALL_ACTIVITIES: 'digest/v1/account_activity_all/',
  GET_ACTIVE_GROUPS: 'digest/v1/most_active_group/',
  CHANGE_PROFILE_PIC: 'auth/v1/change_profile_pic/',

  COMPANY_NEWS: 'company/v1/company_news/',
  COMPANY_SOCIAL: 'company/v1/company_social/',
  COMPANY_VIDEOS: 'company/v1/company_video/',
  COMPANY_BENEFITS: 'company/v1/company_benefits/',
  GET_NOTABLE_STAFF: 'company/v1/notable_staff/',
  GET_COMPANY_PROFILE_WB_STATS: 'person/v1/company_stats/',
  COMPANIES_EXPORT: 'digest/v1/launch_pad_companies_export/',
  NOTABLE_STAFF_EXPORT: 'company/v1/notable_staff_download/',
  COMPARE_COMPANIES: 'company/v1/compare_companies/',
  GET_SWOT_ANALYSIS: 'company/v1/swot_analysis/',
  REFRESH_SWOT_ANALYSIS: 'company/v1/swot_refresh/',
  ACCOUNT_PLAN_FORM: 'account_plan/v1/account_plan_form/',
  CREATE_ACCOUNT_PLAN: 'account_plan/v1/create_account_plan/',
  RECOMMENDED_DESIGNATIONS: '/account_plan/v1/recommended_designations/',
  ACCOUNT_PLAN_HISTORY: '/account_plan/v1/account_plan_history/',
  DELETE_ACCOUNT_PLAN: 'account_plan/v1/delete_history/',
  COMPARE_COMPANY_EXPORT: 'company/v1/compare_companies_export/',
  GET_ACCOUNT_PLAN_STATUS: 'account_plan/v1/check_account_plan_status/',

  GET_RECOMMENDED_PEOPLE: 'digest/v1/recommended_people/',
  GET_PERSON_PROFILE_DATA: 'person/v1/card/',
  UPDATE_PERSON_PROFILE_FOLLOW: 'person/v1/edit_person_network/',
  GET_PERSON_EXTENDED_SEARCH: 'search/v1/person_extended_search/',
  GET_COMPANY_EXTENDED_SEARCH: 'search/v1/company_extended_search/',
  GET_SOCIAL_ACTIVITIES: 'digest/v1/social_activities/',
  GET_NEWS_ACTIVITIES: 'digest/v1/news_activities/',
  GET_FEED_PULSE_BOARD: 'digest/v1/companies_pulse/',
  GET_FOLLOWED_PEOPLE: 'digest/v1/followed_people/',
  GET_EXPORT_FOLLOWED_PEOPLE: 'digest/v1/followed_people_export/',
  GET_PERSON_PROFILE_HIGHLIGHTS: 'person/v1/highlights/',
  GET_PERSON_PROFILE_INTENT_DATA: 'person/v1/intent/',
  GET_PERSON_PROFILE_PERSONAL_DETAILS: 'person/v1/personal_details/',
  GET_ARTICLE_DEEPLINK: 'digest/v1/share_article/',
  ADD_REMOVE_FAVORITE_ARTICLE: 'digest/v1/add_remove_favorite_article/',
  GET_FAVORITE_ARTICLE: 'digest/v1/favorite_articles/',
  GET_PERSON_PROFILE_CAREER_DETAILS: 'person/v1/career/',
  GET_PERSON_PROFILE_INSIGHTS: 'person/v1/insights/',
  GET_SIMILAR_PEOPLE: 'person/v1/similar_people/',
  GET_PERSON_PROFILE_NEWS: 'person/v1/news/',
  GET_PERSON_PROFILE_SOCIAL: 'person/v1/headline/',
  GET_PERSON_PROFILE_VIDEO: 'person/v1/video/',
  GET_PERSON_PROFILE_WB_STATS: 'person/v1/person_stats/',
  GET_PERSON_NOTES: 'person/v1/get_notes/',
  ADD_PERSON_NOTES: 'person/v1/add_note/',
  EDIT_PERSON_NOTES: 'person/v1/update/note/',
  DELETE_PERSON_NOTES: 'person/v1/delete/note/',
  GET_PERSON_CAREER_COMPANY_ID: 'person/v1/company_redirection/',
  COMPANY_STAFF: 'company/v1/company_staff/',
  GET_EXPORT_SIMILAR_PEOPLE: 'person/v1/similar_people_export/',
  EDIT_PHONE_EMAIL: 'person/v1/edit_email_phone/',

  // ChatGPT APIs
  GENERATE_EMAIL: 'chat/v1/person_email_generate/',
  COMPANY_CHATBOT: 'chat/v1/chatgpt_company/',
  BUYER_GROUP_CHATBOT: 'chat/v1/buyer_group_chatgpt/',
  DOCUMENT_HUB_CHATBOT: 'chat/v1/hub_chatgpt/',
  EMAIL_FEEDBACK: 'chat/v1/feedback_ai/',
  CHATBOT_PROMPTS: 'person/v1/chatbot_prompts/',
  CHATBOT_HISTORY: 'chat/v1/chat_history/',
  CLEAR_CHAT_HISTORY: 'chat/v1/clear_chat_history/',
  EDIT_CHAT: 'chat/v1/edit_generated_response/',
  GET_CONVERSATIONS: 'chat/v1/get_channels/',
  EDIT_CONVERSATION: 'chat/v1/edit_channel/',
  PROMPTS: 'chat/v1/prompts/',
  PROMPT_CATEGORIES: 'chat/v1/prompt_categories/',
  DELETE_CONVERSATION: 'chat/v1/delete_channel/',
  CHATBOT: 'chat/v1/conversations/',
  CHAT_FEEDBACK: 'chat/v1/feedback_ai/',
  PERSON_SETUP: 'chat/v1/person_setup/',
  COMPANY_SETUP: 'chat/v1/company_setup/',
  GROUP_SETUP: 'chat/v1/group_setup/',
  RECENT_TAGS: 'chat/v1/recent_tags/',

  // APIs for groups Browser
  GET_ALL_GROUPS: '/buyer_groups/v1/user_groups/',
  DELETE_GROUP: '/buyer_groups/v1/delete_group/',
  CREATE_GROUP: '/buyer_groups/v1/create_group/',
  GET_ORG_EXECUTIVES: '/buyer_groups/v1/list_org_people/',
  SHARE_GROUP: '/buyer_groups/v1/share_group/',
  EXPORT_GROUP_MEMBERS: '/buyer_groups/v1/group_members_export/',

  // APIs for buyer groups page
  BUYER_GROUPS_PAGE_TOP_PEOPLE: 'buyer_groups/v1/top_group_people/',
  BUYER_GROUPS_PAGE_HIGHLIGHTS: 'buyer_groups/v1/group_highlights/',
  BUYER_GROUPS_PAGE_MEMBERS: 'buyer_groups/v1/group_members/',
  BUYER_GROUPS_RENAME_GROUP: 'buyer_groups/v1/rename_group/',
  BUYER_GROUPS_CHANGE_CATEGORY: 'buyer_groups/v1/change_category/',
  BUYER_GROUPS_LIST_REASONS: 'buyer_groups/v1/list_reasons/',
  BUYER_GROUPS_EDIT_REASON: 'buyer_groups/v1/edit_reason/',
  BUYER_GROUP_RECOMMENDED_PEOPLE: 'buyer_groups/v1/recommended_people_group/',
  // APIs for buyer groups page

  ARTICLE_DETAIL: 'digest/v1/article_detail/',
  ARTICLE_SUMMARY: 'digest/v1/article_summary/',
  SIMILAR_ARTICLES: 'digest/v1/similar_articles/',
  UPDATE_PERSON_INFO: 'auth/v1/change_settings/',
  GET_DOWNLOAD_PERSON_PROFILE: 'person/v1/download/',
  API_BRANCH: 'https://api.branch.io/v1/url',
  SUBMIT_VERIFICATION_CODE: 'auth/v1/user_verify/',
  SEND_EMAIL_VERIFICATION_CODE: 'auth/v1/send_verification/',

  // SRI API
  GET_SRI_USER_OVERVIEW: 'sri/v1/sriuseroverview/',
  GET_SRI_USER_SCORE: 'sri/v1/sriuserscore/',
  GET_SRI_OVERVIEW_ENGAGEMENT: 'sri/v1/srioverviewengagement/',
  GET_SRI_OVERVIEW_COMPANY_VIEW: 'sri/v1/sriusercompanyview/',
  GET_SRI_OVERVIEW_PEOPLE_VIEW: 'sri/v1/sriuserpeopleview/',
  GET_SRI_OVERVIEW_ARTICLES_READ: 'sri/v1/sri_articles_read/',
  GET_SRI_OVERVIEW_PLATFORM_SESSION: 'sri/v1/sri_platform_sessions/',
  GET_SRI_OVERVIEW_EMAIL_OPENED: 'sri/v1/sri_emails_opened/',
  GET_SRI_MY_ENGAGEMENT: 'sri/v1/sriuserengagement/',
  GET_SRI_USER_TEAM_READINESS_DETAILS: 'sri/v1/sriuserteamdetails/',
  GET_SRI_TEAM_READINESS_GRAPH: 'sri/v1/sriteamdetails_graph/',
  GET_SRI_TEAM_READINESS_DETAILS: 'sri/v1/sriteamdetails/',
  GET_SRI_TEAM_READINESS_BELT_GRAPH: 'sri/v1/sriteamdetailsbelts/',
  GET_SRI_TEAM_READINESS_TEAMS_GRAPH: 'sri/v1/sriteamdetails_graph/',
  GET_SRI_LEADER_BOARD: 'sri/v1/sritopperformers/',
  GET_SRI_USEROVER_VIEW_ALL: 'sri/v1/sriuseroverviewall/',
  GET_LEADER_BOARD_SUMMARY_GRAPH: 'sri/v1/leaderboardsummary/',
  GET_SALES_SIGMA_LEADERBOARD: 'sri/v1/sri_feed_page/',
  GET_COMPLIANCE_RECORD: 'analytics/v1/compliance_record/',
  EXPORT_COMPLIANCE_RECORD: 'analytics/v1/export_compliance_record/',

  // Signup API
  RESET_PASSWORD: 'auth/v1/reset_password/',

  // onboarding APIs
  GET_RECOMMENDED_USER_ONBOARDING: 'onboarding/v1/user_onboarding/',
  GET_ONBOARDING_RECOMMENDED_PEOPLE: 'onboarding/v1/onboarding_recommended_people/',

  // Flurry Events
  FLURRY_EVENTS_API: 'digest/v1/flurry_events_insertion/',

  // Industry
  GET_INDUSTRIES: 'industry/v1/user_industries/',
  REMOVE_INDUSTRIES: 'industry/v1/remove/',
  ADD_INDUSTRIES: 'industry/v1/add/',
  GET_INDUSTRY_NEWS: 'industry/v1/news/',
  GET_INDUSTRY_SOCIALS: 'industry/v1/social/',

  // notification alerts person designation
  GET_PERSON_DESIGNATION_ALERT: 'digest/v1/people_notification/',
  GET_NOTIFICATION_OPENED: 'digest/v1/people_notification_read_api/',
  GET_PEOPLE_NOTIFICATION_FILTER: 'digest/v1/people_notification_filters/',

  // ServiceLine
  GET_SERVICELINE: 'digest/v1/servicelines/',
  REMOVE_SERVICELINE: 'digest/v1/remove_service_lines/',
  ADD_SERVICELINE: 'digest/v1/add_service_lines/',
  GET_SERVICELINEWIDGETS: 'digest/v1/service_lines_widgets/',
  GET_SERVICELINEDETAILS: 'digest/v1/service_lines_details/',

  // Custom Widgets API
  GET_CUSTOM_WIDGET_DATA: 'digest/v1/widgets/',
  GET_WIDGET_NEWS: 'digest/v1/widgets_information/',

  // Certification API
  GET_CERTIFICATION_DATA: 'auth/v1/user_certification/',
  EDIT_NAME: 'auth/v1/change_certification_name/',
  GET_CERTIFICATE_PDF: 'auth/v1/download_certificate_pdf/',

  // Maketing Popup Read
  MARKETING_COMPAGIN: 'search/v1/update_popup/',

  // Profile Confirmation API
  SELF_MAPPING: 'person/v1/self_mapping/',

  // Document Hub API
  GET_DOCUMENTS: 'chat/v1/hub_view/',
  UPLOAD_DOCUMENT: 'chat/v1/upload_file/',
  DELETE_DOCUMENT: 'chat/v1/delete_doc/',
  RENAME_DOCUMENT: 'chat/v1/rename_file/',
  DOWNLOAD_DOCUMENT: 'chat/v1/hub_download/',
  UPLOAD_DOCUMENT_STATUS: 'chat/v1/validate_filename/',

  // Translation Supportive languages,
  SUPPORTED_LANGUAGE: 'translation_services/v1/supportedLanguages/',
  TRANSLATION_HTML: 'translation_services/v1/translateHtml/',

  COMPLIANCE_ACKNOWLEDGEMENT: 'company/v1/compliance/acknowledge/',
};

export const LOGIN_ERROR_MESSAGES: Record<string, string> = {
  EMAIL_EMPTY: 'Email Required',
  PASSWORD_EMPTY: 'Password Required',
  INVALID_EMAIL: 'Invalid Email',
};

export const RESET_PASSWORD_MESSAGES: Record<string, string> = {
  PASSWORD_EMPTY: 'Password Required',
  CONFIRM_PASSWORD_EMPTY: 'Confirm Password Required',
  PASSWORD_MISMATCH: 'Passwords do not match',
};
export const SAFARI_NOTIFY_ID = 'web.onesignal.auto.3c6dd35c-6a89-4a57-8823-48d881ba6e9f';
export const GOOGLE_MAP_KEY = 'AIzaSyAT7sz_AQBt0R6P3wtXQad-e6A12KV-e-4';
export const FRESHCHAT_TOKEN = '1f9215d1-96af-4a4d-9634-cecb117ff337';
export const FRESHCHAT_URL = 'https://wchat.freshchat.com';
export const BOOK_A_MEETING_LINK = 'https://xiqinc.com/book-a-meeting/';
// guest username
export const GUEST_USERNAME = 'guest@xiqinc.com';
export const SUPPORT_MAIL = 'mailto:support@xiqinc.com';
// app store links
export const ANDROID_STORE_URL = 'https://play.google.com/store/apps/details?id=com.xiq.app&hl=en';
export const IOS_STORE_URL = 'https://apps.apple.com/pk/app/xiq/id869245291';

// Route Constants
export const PASSWORD = '/password';
export const WEBAPP = '/webapp';
export const SEARCH = 'search';
export const ONBOARDING = 'onboarding';
export const COMPANY = 'company';
export const FEED = 'feed';
export const PEOPLE = 'people';
export const PERSON = 'person';
export const SRI = 'sri';
export const INDUSTRY = 'industry';
export const GROUPDETAILS = 'groupdetails';
export const COMPANIES = 'companies';
export const INDUSTRIES = 'industries';
export const DOCUMENT = 'document';
export const ALERTS = 'alerts';
export const NOTABLESTAFF = 'notablestaff';
export const SERVICELINE = 'serviceline';
export const HIGHLIGHTS = 'highlights';
export const MEMBERS = 'members';
export const ARCTYPES = 'arctypes';

export const SLCOMPANIES = 'serviceline_companies';
export const SLALERTS = 'serviceline_alerts';
export const ACCOUNTNEWS = 'accountnews';
export const MARKETNEWS = 'marketnews';
export const ACCOUNTNEWSTITLE = 'ACCOUNT NEWS';
export const MARKETNEWSTITLE = 'MARKET NEWS';
export const NEWS = 'news';

// Routes Consts
export const GROUP_DETAIL_ROUTE = '/groupdetails/members/?groupId=';
export const PERSONALITY_INSIGHTS_ROUTE = '/person/personalityinsights?personId=';
export const COMPANY_OVERVIEW_ROUTE = '/company/overview?companyId=';
export const COMPANY_NEWS_ROUTE = '/company/news?companyId=';
export const COMPANY_SWOT_ROUTE = '/company/swot?companyId=';
export const COMPANY_FINANCIALS_ROUTE = '/company/financials?companyId=';
export const COMPANY_STAFF_ROUTE = '/company/staff?companyId=';
export const COMPANY_COMPETITORS_ROUTE = '/company/competitors?companyId=';
export const SIMILAR_PEOPLE_ROUTE = '/similarpeople?personId=';
export const SERVICELINE_NEWS_ROUTE = '/serviceline/news?id=';
export const SERVICELINE_ACCOUNTNEWS_ROUTE = '/serviceline/accountnews?id=';
export const INDUSTRY_NEWS_ROUTE = '/industry/news?id=';
export const COMPARE_COMPANY_ROUTE = '/comparecompany';
export const COMPANIES_ROUTE = '/companies';
export const COMPANY_ROUTE = '/company/';
export const ONBOARDING_ROUTE = '/onboarding';
export const LOGIN_ROUTE = '/login';
export const DOCUMENTS_ROUTE = '/documents';
export const DOCUMENTS_UPLOAD_ROUTE = '/documents?isUpload=true';
export const FEED_ROUTE = '/feed/';
export const FEED_GILROY_ROUTE = '/feed/gilroy';
export const FEED_SPOTLIGHT_ROUTE = '/feed/spotlight';
export const FEED_NEWS_ROUTE = '/feed/news';
export const NOTABLE_STAFF_ROUTE = '/notablestaff?companyId=';
export const SRI_LEADERBOARD_ROUTE = '/sri/leaderboard';
export const SEARCH_ROUTE = '/search?query=';
export const USER_EXPIRED_ROUTE = '/userExpired';
export const PEOPLE_ROUTE = '/people';

export const TAG_CHARACTER = '@';
export const TAG_LIMIT = 5;
export const TAG_LIMIT_MESSAGE = 'Only 5 unique tags are allowed. Please remove an existing tag to add a new one.';
export const DEFAULT_GILROY_IMAGE = 'https://s3.us-west-2.amazonaws.com/media.xiqonline.com/salesApp/xiQgptGlobal/gilRoyConversional.png';
export const DEFAULT_GROUP_GILROY_IMAGE = 'https://s3.us-west-2.amazonaws.com/media.xiqonline.com/salesApp/xiQgptGlobal/bot-group.png';
export const DEFAULT_DOCUMENT_GILROY_IAMGE = 'https://media.xiqonline.com/salesApp/xiQgptGlobal/document-taging-icon-c.png';

export const TOOLTIP_SPOTLIGHT = 'Spotlight acts as your holistic dashboard. You can view the most recent news articles, trending social media posts and financial standing of your followed accounts.\n\nWatch the tutorial to learn how to fully utilize your spotlight.';
export const VIDEO_SPOTLIGHT = 'https://www.youtube.com/embed/OEICa4VfUUw';
export const TOOLTIP_TOPACCOUNT = 'Top Accounts displays your followed companies with the most activity or overall footprint. Selecting one from here you can check their detailed profile.';
export const TOOLTIP_TOPPEOPLE = 'Top People shows you the profiles for the top individuals you follow or the most engaged executives amongst the company’s you follow. Proceeding here you can check their detailed profile. ';
export const TOOLTIP_PULSE = 'Pulse lists your top 10 followed companies and enables you to quickly identify their online activity and news being  their in the order of their footprints in the past 24hrs, 1 week or 1 month. Each company has a color-coded bar next to it that illustrates all the sales triggers associated with the company. \n\nFeel free to watch the tutorial in order to gain more knowledge about the sales triggers.';
export const VIDEO_PULSE = 'https://www.youtube.com/embed/B6vrLvcAh9s';
export const TOOLTIP_ACCOUNTACTIVITY = 'The Account Activity, Sales Triggers, and Social sections show stories and tweets in chronological order of the company’s you are interested in. You can filter the activities according to your required triggers.\n\nFeel free to watch the tutorial in order to dig into the account activity section.';
export const VIDEO_ACCOUNTACTIVITY = 'https://www.youtube.com/embed/HKAihdRZfto';
export const TOOLTIP_BUYERGROUP = 'This section organizes Buyer Groups for your past and upcoming engagements. Add decision-makers from your target company to a Buyer Group for effective interaction. \n\nExplore the tutorial for a video guide on using Buyer Groups. If you have any questions, feel free to reach out at <a href="mailto:support@xiqinc.com" className="tooltip-email-link">support@xiqinc.com</a>.';
export const VIDEO_BUYERGROUP = 'https://www.youtube.com/embed/vE6TbOWJ_90';
export const TOOLTIP_PEOPLEIFOLLOW = 'Here you can view all the people you follow. Open their profiles and see what they are up to and how you can connect with them.';
export const TOOLTIP_MYACCOUNTS = 'Here you will find all the company accounts you follow. In recommendations below we have gathered a list of companies that matches your interest, and you would like to connect in the future.';
export const TOOLTIP_INDUSTRIES = 'In the Industry tab, you can view all the added industries based on the accounts you are following and get recommendations.\n\nFeel free to watch the tutorial of what insights you can get from a industry’s profile. ';
export const VIDEO_INDUSTRIES = 'https://www.youtube.com/embed/xQzTuE6pHFU';
export const TOOLTIP_BELTS = 'xiQ users earn 6 belts, based on the frequency and depth of use and upon achieving xiQ certifications. This gamification is built into sales sigma and users earn badges as they collect points for usage and certification. This creates healthy competition amongst users who compete to earn higher tiers. \n\nFeel free to watch the tutorial to enhance your knowledge about Sales Sigma Measurement Index.';
export const VIDEO_BELTS = 'https://www.youtube.com/embed/F23csJfcafE';
export const TOOLTIP_LEADERBOARD = 'Every user can see their own rank and how they compare with their teammates on the leaderboard. Managers can use the leaderboard to monitor their sellers’ performance and provide remedial advice.';
export const TOOLTIP_SWOT = 'SWOT analysis provides you with valuable insights that can help you make informed decisions about your prospects business, such as identifying opportunities the prospect holds for you. This information is regularly updated using xiQ AI algorithms.';
export const TOOLTIP_SIMILAR_PEOPLE = 'Discover professionals who share common traits and backgrounds with the person you’re viewing. These people are determined based on factors such as job role, company, industry, work history, education history, and personality type. Use the filters to narrow down the list by specific companies or export the entire list as a CSV file for further analysis or prospecting opportunities.';
export const TOOLTIP_DOCUMENT_HUB = `Enhance Your Conversations: Upload Custom Documents for Contextual Insights. With the Document Upload Hub, integrate your own files into xiQ's ecosystem, allowing ChatGPT to consider the text for more informative outputs.
Overcome limitations of limited company information and boost collaboration.Easily upload and reference documents within the chat interface to ensure accurate, contextually informed, and efficient conversations, elevating your user experience in ChatGPT.`;

export const NODATAFOUND = 'No Data Found.';
export const NO_GROUP_FOUND = 'No Group Found.';
export const NO_PERSON_FOUND = 'No Person Found.';
export const NO_COMPANY_FOUND = 'Select Companies to Compare';

export const NODATABASEDONFILTERS = 'No updates available based on this filter. Please choose a different option';

export const WIDGET = 'widget';

export const SHARE_GROUP_CONFIRMATION = 'Are you sure you want to share';

// Certification Constants
export const HIGHLIGHT_TIME = 3000;
export const CERTIFICATION_COMP_POPUPS = 'showCertificationPopups';
export const CERTIFICATION_START_POPUP = 'isShowCertification';
export const SALES_X_CERTIFICATION = 'Sales Xelerator<sup>TM</sup> Certification';
export const GILROY_CERTIFICATION = 'xiQ Gen AI Certificate for Sales';
export const SALES_X_GILROY_CERTIFICATION = 'Generative AI for Sales';
export const SALES_X_GILROY_BANNER_PARAGRAPH = 'This certification acts as guide to effectively utilizing Gilroy to understand your accounts and prospects, identify their business challenges and tailor precise solutions to overcome them.';
export const CERTIFICATION_FOOTER_PARAGRAPH = 'xiQ’s certification allows users to be aware of major platform features and the best practices to maximize its usability, increasing platform utilization and credibility as a seller.';
export const CERTIFICATION_FOOTER_PARAGRAPH_GILROY = 'xiQ’s certification allows users to be aware of our invaluable tool Gilroy and the best practices to maximize its usability, increasing platform utilization and credibility as a seller.';
export const CERTIFICATE_MAIL_TEXT = 'I have successfully completed my training for the xiQ Xelerator platform';
export const DISCLAIMER_MESSAGE = 'This information is for licensed use and can only be downloaded and shared through the platform. Copying, screen grabbing and snipping are strictly not allowed';
export const PROFILE: Record<string, string> = {
  TIM_COOK: `${PERSONALITY_INSIGHTS_ROUTE}1051&personType=EXECUTIVE`,
  MICHAEL_DELL: `${PERSONALITY_INSIGHTS_ROUTE}272947&personType=EXECUTIVE`,
  ELON_MUSK: `${PERSONALITY_INSIGHTS_ROUTE}832&personType=EXECUTIVE`,
  APPLE: `${COMPANY_OVERVIEW_ROUTE}238`,
  DELL: `${COMPANY_OVERVIEW_ROUTE}154`,
  MICROSOFT: `${COMPANY_OVERVIEW_ROUTE}196`,
  GOOGLE: `${COMPANY_OVERVIEW_ROUTE}198`,
};
export const COMPANY_TOASTER = 'You are navigated to your latest followed company profile to complete this task. If the necessary information / tabs are not available in this profile, please search or select another profile.';
export const PERSON_TOASTER = 'You are navigated to your latest followed person profile to complete this task. If the necessary information / tabs are not available in this profile, please search or select another profile.';
export const GROUP_TOASTER = 'You are navigated to your latest created Buyer Group to complete this task. If this Buyer Group does not have the necessary information / tabs then please create a new buyer group.';

// Document Hub
export const DOCUMENT_STATUS: string[] = ['Processing', 'Ready', 'Error', 'Error'];
export const ALLOWED_FILE_TYPES = ['text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/pdf', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'docx', 'ppt', 'pptx', 'pdf', 'txt'];
export const MAX_FILE_SIZE = 100 * 1024 * 1024;
export const MAX_FILES_ALLOWED = 10;
export const WRONG_FILE_TYPE_UPLOAD = 'We currently accept the following file types: PDF, DOC, PPT, TXT.';
export const WRONG_FILE_SIZE_UPLOAD = 'Your file size exceed the limit "100MB"';
export const WRONG_FILE_UPLOAD_LIMIT = 'We currently accept only 10 files at a time.';
export const LINK_REGEX = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const INVALID_RENAME_REGEX = /[<>"*:/\\|?]/g;
export const DOCUMENT_DELETE_SUCCESS = 'Document deleted successfully';
export const DOCUMENTS_DELETE_SUCCESS = 'Documents deleted successfully';
export const NO_DOCUMENT_DELETED = 'No document found uploaded by you';
export const DOCUMENT_DELETE_FAIL = 'Document delete failed';
export const DOCUMENT_DOWNLOAD_SUCCESS = 'Document downloaded successfully';
export const DOCUMENT_DOWNLOAD_FAIL = 'Document download failed. Please check your pop-up blocker';
export const DOCUMENT_RENAME_SUCCESS = 'Document renamed successfully';
export const DOCUMENT_RENAME_FAIL = 'Document renamed failed';
export const FILE_SIZE_ERROR = 'Exceeded File Size';
export const FILE_TYPE_ERROR = 'Incorrect File Type';
export const FILE_UPLOAD_ERROR = 'Uploading failed';
export const FILE_UPLOAD_SUCCESS = 'Uploaded Successfully';
export const CANCEL_UPLOAD_POPUP = 'Are you sure you want to cancel documents upload?';
export const CANCEL_FAILED_TOASTER = 'Previous cancellation is already in queue';

// Salesforce App
export const cypherKey = '83yos67DqY3tTEpJdRvs6XtnJ7E9pfKi';
export const FILE_DOWNLOAD_SUCCESS = 'File downloaded successfully';
export const FILE_DOWNLOAD_FAIL = 'File download failed. Please check your pop-up blocker';
export const FILE_DOWNLOAD_WARNING = 'This is a private company, therefore, it may include limited information';
export const COMPANY_LIMIT = 'Cannot compare more than 4 Companies';
export const UP_PRICE = '+';
export const DOWN_PRICE = '-';
export const COMPANY_ACTION_TITLE = 'Compare Companies';

// Sales Sigma LeaderBoard
export const SALES_SIGMA_HEADING = 'Sales Sigma Leaderboard';
export const WORK_ON_THESE = 'You can work on these engagements in order to improve your company rank further.';
export const CURRENT_XIQ_STATS = 'Your current xiQ usage statistics are as follows:';
export const SCORE_IS_CALCULATED = 'Score is calculated according to the following breakdown.';
export const HOW_TO_IMPROVE = 'How to improve your rank?';
export const YOU_CAN_BOOST = 'You can boost your rank by getting';

export const SWOT_ANALYSIS = 'SWOT Analysis';
export const VIEW_PAIN_POINTS = 'View Pain Points';

// Smart Account Consts
export const SMART_REDIRECTION_TEXT = 'Account Plan';
export const SMART_LOADER_HEADING = 'Preparing your <br /> Smart Account Plan';
export const SMART_LOADER_PARAGRAPH = 'I\'m putting together your customized Smart Account Plan. This process could take about 10 to 12 minutes.<br /> <br /> You don\'t have to wait here, though. I\'ll drop you an email with the Account Plan attached as soon as it\'s ready. Additionally, you can always download it directly from here as well.';
export const SMART_POPUP_TITLE = 'Are you sure you want to create a new Smart Account Plan for ';
export const PRODUCT_DESCRIPTION = 'For a more precise document, please describe your product or service, highlighting its key features, benefits, and any unique selling points that set it apart from competitors.';
export const DESIGNATION_QUESTION = 'Unlock sales success by considering these key account designations. Customize or expand as needed!';
export const EVALUATION_QUESTION = 'How often will the plan be evaluated and adjusted?';

// chatbotv2
export const DISCLAIMER_MESSAGE_M1 = 'Possibility of information that may not be entirely accurate. Might have incomplete information on the product or service.';
export const PLACEHOLDER_CONTENT_EDITABLE = 'to start tagging people, companies and buyer groups';

// onboarding
export const WELCOME_SCREEN_FIRST_TEXT = 'Go beyond the profile with <br />';
export const WELCOME_SCREEN_LAST_TEXT = 'AI-powered personality insights!';

export const CONGRATS_SCREEN_FIRST_TEXT = 'Congratulations your account has been set up.';
export const CONGRATS_SCREEN_LAST_TEXT = 'You can start using Sales Xelerator <sup>TM</sup>';
export const translateSkipAreas = 'skiptranslate';
// chatbotV2 Sample Text
export const USER_OUTPUT_TEXT = {
  chat: '<span class="weight-900">Subject: </span>\nIntroducing [Product Name] - Set Up a Demo Call\n\n<span class="weight-900">Body: </span>\nHi Usman,\n\nI wanted to introduce you to our new and exciting product, [Product Name]. I believe it has the potential to revolutionize the industry and bring significant benefits to xiQ, Inc.\n\nHere are a few key points about [Product Name]:\n\n1. [Feature 1]: Describe the most compelling and unique feature.\n2. [Feature 2]: Highlight another key feature that sets it apart.\n3. [Feature 3]: Emphasize a third benefit or advantage.\n\nI would love to schedule a demo call at your convenience to give you a closer look at [Product Name] and discuss how it can specifically benefit xiQ, Inc. Please let me know a time that works best for you.\n\nLooking forward to your response.\n\nBest regards,\n[Your Name]',
  edited: 0,
  id: 26742,
  prompt: 'Write an email to introduce my product <span class="prompt-input-style">[insert product description]</span>. The objective is to set up a demo call.',
  feedbackSubmitted: false,
  responseId: '241180',
};

export const PERSON_INFO = {
  affinityDescription: 'Sundar succeeds best in a relaxed, upbeat setting and is eager to investigate new concepts or ventures.',
  affinityPoints: ['steady', 'independent', 'invaluable', 'highly skilled'],
  archeType: {
    name: 'Persuader', color: '#FA8C16', image: 'https://media.xiq.io/personality_disc_images_v3/Id.png', personality: 'Id',
  },
  companyId: 8115,
  companyLogo: 'https://s3-us-west-2.amazonaws.com/xiqapp-static/company-cropped-logos/Company8115/8115.jpg',
  companyName: 'Alphabet Inc.',
  companyPersonality: [
    {
      color: '#ffb236', percentage: 50, start: '0%', end: '50%',
    },
    {
      color: '#51b4e0', percentage: 25, start: '50%', end: '75%',
    },
    {
      color: '#ed5944', percentage: 25, start: '75%', end: '100%',
    },
  ],
  confidence: '75',
  executiveType: 'Executive',
  headline: '',
  isAdmin: false,
  location: 'Los Altos, California',
  notification: 'Profile is ready to view.',
  personAdded: true,
  personDesignation: 'CEO, Google and Alphabet',
  personId: 7531,
  personImage: 'https://s3-us-west-2.amazonaws.com/xiqapp-static/Executives/7531.jpg',
  personName: 'Sundar Pichai',
  personRefresh: 0,
  personSearch: 0,
  personType: 'EXECUTIVE',
  personalityTypes: [
    {
      cardColor: '#d48806',
      color: '#FA8C16',
      description: 'Influencing',
      image: 'https://media.xiq.io/personality_type_images/id.png',
      key: 'Id',
      value: 'Id',
    },
  ],
  profileStatus: 1,
  email: '',
  phone: '',
  companySubscribers: 0,
  companyPulse: [],
  isSelected: false,
};
// disclaimer text for person page DISC
export const DISCLAIMER_MESSAGE_PERSON_PAGE = `
<div class="disclaimer-text">
<span class="starting-span">
<span class="primary-color">Disclaimer:</span>
All Personality Analysis are based on the DISC
methodology and are generated using proprietary AI technology
from publicly available data.
<br />
<span class="one-liner-text">xiQ's DISC Personality Analysis are predictive. As such:</span>
</span>
<ol type="A" class="alphabetical-list-text">
  <li>Insights have not been collected for, and is not intended to be indicative of, any person's employability, credit worthiness, credit standing, credit capacity, or other characteristics related to such person's manner or mode of living, as listed in Section 603(d) of the Fair Credit Reporting Act ("FCRA"), 15 USC Section 1681a; and</li>
  <li>Customer shall not use any Insights as a factor in establishing any person's eligibility for:
  <ol type="1" class="numeric-list-text">
    <li>credit or insurance used primarily for personal, family or</li>
    <li>employment purposes, or</li>
    <li>other purposes authorized under Section 604 of the FCRA, 15 USC Section 1681b or any similar statute.</li>
  </ol>
  </li>
</ol>
</div>
`;

export const ChatbotCertificationTypes = ['open_company_profile', 'company_profile_execute_prompt_2', 'company_profile_execute_prompt_3', 'company_profile_execute_prompt_4', 'open_buyer_group', 'buyer_group_execute_prompt_2', 'buyer_group_execute_prompt_3', 'open_person_profile', 'person_profile_execute_prompt_2'];

export const SMART_DESIGNATIONS_POINTS = ['Start with our suggested job titles that align with your product/service details.', 'Search and select individuals.', 'Select and add any pre built buyer groups.'];

export const TOOLTIP_CERTIFICATION_IMPROVED = 'xiQ Sales Sigma certification empowers you with essential platform insights and best practices, enhancing your effectiveness as a seller.';

export const LanguageEnum = {
  ENGLISH: 'English',
  KOREAN: 'Korean',
};
