import React, { useEffect, useState } from 'react';
import { Divider, Popover } from 'antd';
import styled from 'styled-components';
import AlertsSearch from 'Components/alerts/components/AlertsSearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import AddCompanyItem from 'Components/companyComparison/components/AddCompanyItem';
import {
  maxMobile,
  maxTablet, maxWidth, maxWidth1300, minWidthApp, primaryColor, primaryFont,
  primaryHeadingColor, retina, retinaOne, scrollbar, secondaryFont,
  smMobile, wLight, wMBold, wMedium,
} from 'Utils/utilsStyle';
import { IsTablet, valueToObject } from 'Utils/UtilityFunctions';
import { Company } from 'Types/CompaniesTypes';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { actions } from 'Slice/PersonProfileSlice';
import { actions as helperActions } from 'Slice/HelperSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SIMILAR_PEOPLE_ROUTE } from 'Utils/Constants';
import FilterIcon from '../assets/svgIcons/FilterIcon';
import GlobalButton from './GlobalButton';
import DownAngleIcon from '../assets/svgIcons/DownAngleIcon';
import NoPersonsFound from './NoPersonsFound';

const ContentWrapper = styled.div`
  padding: 16px 8px 12px 0px;
  max-width: 400px;
  min-width: 400px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    max-width: 345px;
    min-width: 345px;
  }
  @media all and (max-width: ${smMobile}) {
    max-width: 300px;
    min-width: 300px;
  }
  .similar-people-popover-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px 0px 24px;
    .right-div{
      display: flex;
      align-items: center;
      .filter-unselect-all{
        &.selectAll{
          color: ${primaryColor};
        }
        &.disable {
          color: ${primaryHeadingColor};
          cursor: not-allowed;
        }
        color: ${primaryColor};
        font-size: 16px;
        font-family: ${primaryFont};
        font-weight: ${wMedium};
        line-height: 24px;
        letter-spacing: -0.4px;
        cursor: pointer;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 12px;
        }
      }
      .ant-divider {
        border-left: 1px solid ${primaryHeadingColor};
      }
    }
    .left-div{
      font-family: ${secondaryFont};
      font-weight: ${wMBold};
      font-size: 28px;
      letter-spacing: -0.609px;
      color: ${primaryHeadingColor};
      @media all and (max-width: ${maxWidth}), ${retina} {
        font-size: 22px;
      }
    }
  }
  .similar-popover-search {
    margin-top: 6px;
    margin-bottom: 0px;
    padding: 0px 14px 0px 24px;
    .search-wrapper {
      .cross-icon {
        top: 12px;
        right: 15px;
      }
    }
    @media all and (max-width: ${maxWidth}), ${retina} {
      margin-top: 6px;
      margin-bottom: 0px;
      .search-wrapper {
        .cross-icon {
          top: 6px;
          right: 10px;
        }
        .ant-input {
          font-size: 12px;
          ::placeholder {
            font-size: 12px;
            font-weight: ${wLight};
          }
        }
      }
    }
    @media all and (max-width: ${minWidthApp}){
      .search-wrapper {
       .cross-icon {
        top: 9px;
       }
      }
    }
  }
  .similar-comapnies {
    max-height: 215px;
    min-height: 215px;
    ${scrollbar};
    .add-company-item-wrapper {
      box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.08);      
      margin: 0px 12px 8px 24px;
      padding: 15px 20px 15px 15px;
      border-radius: 4px;
      margin-left: 24px;
      &:first-child {
        margin-top: 8px;
      }
      .addCompanyWidgetItem {
        width: 40px;
        height: 32px;
        margin-right: 15px;
        margin-left: 45px;
        border-radius: 4px;
        .image-placeholder {
          border-radius: 4px;
        }
        .ant-checkbox-wrapper {
          &:hover {
            .ant-checkbox-checked {
              ::after {
                border-radius: 4px;
              }
            }
          }
          top: 12px;
          left: -29px;
          .ant-checkbox-inner {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            ::after {
              left: 20.5%;
              @media all and (max-width: ${maxWidth}), ${retina} {
                left: 21.5%;
              }
              @media all and (max-width: ${maxWidth}) and (${retinaOne}) {
                left: 20.5%;
              }
              @media all and (max-width: ${maxWidth1300}) {
                left: 19.5%;
              }
              @media all and (max-width: ${maxTablet}) {
                left: 20.5%;
              }
            }
          }
        }
      }
      .text-area {
        .heading {
          font-family: ${secondaryFont};
          font-size: 20px;
          font-weight: ${wMedium};
          line-height: 24px;
          color: #000;
          @media all and (max-width: ${maxWidth}), ${retina} {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
    .share-group-dropdown {
      .no-person-found-wrapper {
        height: 215px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          height: 215px;
        }
        .no-person-title {
          font-size: 24px;
          @media all and (max-width: ${maxWidth}), ${retina} {
            font-size: 18px;
          }
        }
      }
    }
  }
  .filter-apply-btn {
    font-family: ${primaryFont};
    font-weight: ${wMBold};
    background-color: ${primaryColor};
    font-size: 20px;
    line-height: 24px;
    width: 90%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 12px 0 11px;
    color: #fff;
    cursor: pointer;
    margin: 12px 24px 0px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      font-size: 14px;
      padding: 7px 0 6px;
    }
    &.disable{
      pointer-events: none;
      opacity: 0.6;
    }
  }
`;

const PopoverWrapper = styled.div`
  .ant-popover-arrow {
    @media all and (max-width: ${maxMobile}) {
      display: none;
    }
  }
  .filter-similar-button {
    &.d-flex {
      display: block;
    }
    &.active {
      background: rgb(222, 246, 230);
      color: ${primaryColor};
      outline: none;
      svg {
        path {
          fill: ${primaryColor};
        }
      }
    }
    .filter-icon-dropdown {
      height: 18px;
      width: 18px;
      min-width: 18px;
      margin-right: 15px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        height: 15px;
        width: 15px;
        min-width: 15px;
      }
    }
    .right-separator-class {
      height: 18px;
      background-color: #DDDDDD;
      margin: 0px 5px -3px 10px;
    }
    .down-angle-icon {
      height: 10px;
      width: 10px;
      min-width: 10px;
      path {
        fill: #7e7e7e;
      }
    }
    @media all and (max-width: ${maxTablet}){ 
      padding: 4px 8px 3px 8px;
      .filter-icon-dropdown { 
        margin-right: 0px;
      }
      .right-separator-class {
        display: none;
      }
      .down-angle-icon {
        display: none;
      }
    }
  }
`;

type Props = {
  filters: Company[]
  personId: number
};

const FilterSimilarPeople = function FilterSimilarPeople(props: Props) {
  const { filters, personId } = props;
  const isTab = IsTablet();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const personType = searchParams.get('personType');
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filtersAll, setFiltersAll] = useState<Company[]>();
  const [filtersToShow, setFiltersToShow] = useState<Company[]>();
  const [disableSelectAll, setDisableSelectAll] = useState(true);
  const [disableUnselectAll, setDisableUnselectAll] = useState(false);
  const [disableApply, setDisableApply] = useState(false);
  const noneFilterSelected = filtersAll?.every((filter) => filter.isSelected === false);
  const anyAppliedFilterSelected = filters.some((filter) => filter.isSelected);
  const allAppliedFilterSelected = filters.every((filter) => filter.isSelected);

  const toggle = () => {
    setOpen(!open);
  };

  const handleChange = () => {
    if (!open) {
      dispatch(helperActions.callFlurryEvent(
        valueToObject('similar_people_filter_click_intent', 0),
      ));
    }
    toggle();
  };

  const checkDisableApply = () => {
    const currentFilters = filtersAll?.filter((filter) => filter.isSelected).map((obj) => obj.companyId).join(',');
    const reduxFilters = filters?.filter((filter) => filter.isSelected).map((obj) => obj.companyId).join(',');
    if (currentFilters === reduxFilters) {
      setDisableApply(true);
    } else {
      setDisableApply(false);
    }
  };

  const onInputChange = (query: string | void) => {
    if (typeof query === 'string') {
      setSearchQuery(query);
    }
  };

  useEffect(() => {
    if (!filtersToShow?.length) {
      const filtersTemp = filters.map((company) => ({
        ...company,
        isSelected: company.isSelected,
      }));
      setFiltersToShow(filtersTemp);
      setFiltersAll(filtersTemp);
    }
    checkDisableApply();
  }, [filters]);

  useEffect(() => {
    checkDisableApply();
  }, [filtersAll]);

  useEffect(() => {
    if (filtersAll?.length) {
      setFiltersToShow(filtersAll?.filter(
        (company) => company.companyName.toLowerCase().includes(searchQuery.toLowerCase()),
      ));
    }
  }, [searchQuery]);

  useEffect(() => {
    let filtersTemp = filtersAll;
    if (searchQuery.length) {
      filtersTemp = filtersTemp?.filter(
        (company) => company.companyName.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }
    setFiltersToShow(filtersTemp);
  }, [filtersAll]);

  useEffect(() => {
    if (filtersToShow?.every((company) => company.isSelected === true)) {
      setDisableSelectAll(true);
    } else {
      setDisableSelectAll(false);
    }
    if (filtersToShow?.every((company) => company.isSelected === false)) {
      setDisableUnselectAll(true);
    } else {
      setDisableUnselectAll(false);
    }
  }, [filtersToShow]);

  const unselectAllHandler = () => {
    setFiltersAll(
      filtersAll?.map((company) => ({
        ...company,
        isSelected: false,
      })),
    );
  };

  const selectAllHandler = () => {
    setFiltersAll(
      filtersAll?.map((company) => ({
        ...company,
        isSelected: true,
      })),
    );
  };

  const handleCompanyClick = (id: number) => {
    setFiltersAll((prevState) => {
      const updatedArray = prevState?.map((company) => {
        if (company.companyId === id) {
          return {
            ...company,
            isSelected: !company.isSelected,
          };
        }
        return company;
      });
      return updatedArray;
    });
    setFiltersToShow((prevState) => {
      const updatedArray = prevState?.map((company) => {
        if (company.companyId === id) {
          return {
            ...company,
            isSelected: !company.isSelected,
          };
        }
        return company;
      });
      return updatedArray;
    });
  };

  const handleApplyFilters = () => {
    if (filtersAll) {
      dispatch(actions.saveProfile(personId));
      if (!window.location.pathname.includes('similarpeople')) {
        navigate(`${SIMILAR_PEOPLE_ROUTE}${personId}&personType=${personType ?? ''}`);
      }
      dispatch(actions.setSimilarPeopleFilters(filtersAll));
      dispatch(actions.setSimilarPeoplePage(1));
      const filtersObj = filtersAll?.filter((filter) => filter.isSelected);
      dispatch(actions.getSimilarPeople({
        personId,
        ...(personType && { type: personType }),
        ...(filtersObj && filtersObj?.length > 0 && {
          companyIds: filtersObj?.map((obj) => obj.companyId).join(','),
        }),
        page: 1,
      }));
      dispatch(helperActions.callFlurryEvent(
        valueToObject('similar_people_filter_click_complete', 0),
      ));
      toggle();
    }
  };

  const content = (
    <ContentWrapper>
      <div className="similar-people-popover-header">
        <div className="left-div">
          Companies
        </div>
        <div className="right-div">
          <span className={classNames('filter-unselect-all', { disable: disableUnselectAll })} onClick={unselectAllHandler} onKeyPress={unselectAllHandler} tabIndex={0} role="button">Unselect all</span>
          <Divider type="vertical" />
          <span className={classNames('filter-unselect-all selectAll', { disable: disableSelectAll })} onClick={selectAllHandler} onKeyPress={selectAllHandler} tabIndex={0} role="button">Select all</span>
        </div>
      </div>
      <div className="similar-popover-search">
        <AlertsSearch
          placeholderText="Search companies..."
          query={searchQuery}
          loading={false}
          onSearching={() => { }}
          onInputChange={onInputChange}
          margin="0px"
        />

      </div>
      <div className="similar-comapnies">
        <InfiniteScroll
          hasMore={false}
          dataLength={filters.length}
          next={() => { }}
          loader={false}
          className="share-group-dropdown"
          height="auto"
        >
          {
            filtersToShow?.map((company) => (
              <AddCompanyItem
                companyLogo={company.companyLogo}
                companyPersonality={company.companyPersonality}
                companyPulse={company.companyPulse}
                onClick={() => handleCompanyClick(company.companyId)}
                isChecked={company.isSelected}
                companyId={company.companyId}
                companyName={company.companyName}
                companyWebsite=""
                selectedCount={0}
                tooltip
              />
            ))
          }
          {
            !filtersToShow?.length ? <NoPersonsFound title="No Companies Found" /> : null
          }
        </InfiniteScroll>
      </div>
      <span
        tabIndex={0}
        role="button"
        className={classNames('filter-apply-btn', { disable: disableApply || noneFilterSelected })}
        onClick={handleApplyFilters}
        onKeyDown={handleApplyFilters}
      >
        Apply filter
      </span>
    </ContentWrapper>
  );

  return (
    <PopoverWrapper>
      <Popover
        content={content}
        trigger="click"
        open={open}
        onOpenChange={handleChange}
        placement="bottomRight"
        overlayClassName="filter-similar-people-popover index-one"
        getPopupContainer={(e: HTMLElement) => e}
      >
        <div
          aria-hidden="true"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <GlobalButton
            title="Filter"
            textColor="#7E7E7E"
            bgColor="#fff"
            outlineColor="#DADADA"
            innerSpace="6px 15px 6px 15px"
            PaddingSm="5px 15px"
            LeftSpace="7px"
            IconLeft={<FilterIcon />}
            IconRight={<DownAngleIcon />}
            buttonClassName={classNames('filter-similar-button', { active: anyAppliedFilterSelected && !allAppliedFilterSelected })}
            RightSeparator
            rightSeparatorClassName="right-separator-class"
            onButtonClick={toggle}
            disabled={false}
            noTitle={isTab}
          />
        </div>
      </Popover>
    </PopoverWrapper>
  );
};
export default FilterSimilarPeople;
