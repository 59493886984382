import { primaryColor } from 'Utils/utilsStyle';
import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  &:hover {
    path {
      fill: ${primaryColor};
      &:nth-child(3) {
        stroke: ${primaryColor};
      }
    }
    ellipse {
      fill: ${primaryColor};
      stroke: ${primaryColor};
    }
  }
`;

type Props = {
  onClickAction?: () => void;
};

const defaultProps = {
  onClickAction() { },
};

const ThumbsUpIcon = function ThumbsUpIcon(props: Props) {
  const { onClickAction } = props;
  return (
    <SVG width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClickAction} id="thumbs-up-am">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.51491 17.4645H4.07822C4.78859 17.4645 5.36709 16.8784 5.36709 16.1576V8.32491C5.36709 7.60463 4.78859 7.01807 4.07822 7.01807H1.51491C0.804543 7.01807 0.226562 7.60463 0.226562 8.32491V16.1576C0.226562 16.8784 0.804543 17.4645 1.51491 17.4645ZM1.25687 8.32471C1.25687 8.18002 1.37277 8.0625 1.51495 8.0625H4.07826C4.22095 8.0625 4.33686 8.18002 4.33686 8.32471V16.1574C4.33686 16.3016 4.22095 16.4196 4.07826 16.4196H1.51495C1.37277 16.4196 1.25687 16.3016 1.25687 16.1574V8.32471Z" fill="#667982" />
      <path d="M4.07822 17.2502H1.51491V17.6788H4.07822V17.2502ZM5.1528 16.1576C5.1528 16.7629 4.66751 17.2502 4.07822 17.2502V17.6788C4.90967 17.6788 5.58137 16.994 5.58137 16.1576H5.1528ZM5.1528 8.32491V16.1576H5.58137V8.32491H5.1528ZM4.07822 7.23235C4.66745 7.23235 5.1528 7.72017 5.1528 8.32491H5.58137C5.58137 7.4891 4.90973 6.80378 4.07822 6.80378V7.23235ZM1.51491 7.23235H4.07822V6.80378H1.51491V7.23235ZM0.440848 8.32491C0.440848 7.72006 0.92579 7.23235 1.51491 7.23235V6.80378C0.683296 6.80378 0.0122766 7.48921 0.0122766 8.32491H0.440848ZM0.440848 16.1576V8.32491H0.0122766V16.1576H0.440848ZM1.51491 17.2502C0.925735 17.2502 0.440848 16.763 0.440848 16.1576H0.0122766C0.0122766 16.9939 0.683351 17.6788 1.51491 17.6788V17.2502ZM1.51495 7.84821C1.25136 7.84821 1.04258 8.06476 1.04258 8.32471H1.47115C1.47115 8.29528 1.49419 8.27679 1.51495 8.27679V7.84821ZM4.07826 7.84821H1.51495V8.27679H4.07826V7.84821ZM4.55114 8.32471C4.55114 8.06448 4.34209 7.84821 4.07826 7.84821V8.27679C4.09981 8.27679 4.12257 8.29556 4.12257 8.32471H4.55114ZM4.55114 16.1574V8.32471H4.12257V16.1574H4.55114ZM4.07826 16.6339C4.34236 16.6339 4.55114 16.4169 4.55114 16.1574H4.12257C4.12257 16.1863 4.09954 16.2054 4.07826 16.2054V16.6339ZM1.51495 16.6339H4.07826V16.2054H1.51495V16.6339ZM1.04258 16.1574C1.04258 16.4166 1.25109 16.6339 1.51495 16.6339V16.2054C1.49446 16.2054 1.47115 16.1866 1.47115 16.1574H1.04258ZM1.04258 8.32471V16.1574H1.47115V8.32471H1.04258Z" fill="#667982" />
      <path d="M13.5197 17.4645H7.27629C6.95845 17.4645 6.64061 17.3883 6.35574 17.2441L4.62077 16.3645C4.3663 16.2355 4.26327 15.9216 4.39051 15.6636C4.51775 15.4055 4.82683 15.3011 5.08182 15.4301L6.81679 16.3097C6.95897 16.3818 7.11763 16.4199 7.27629 16.4199H13.5197C13.7077 16.4199 13.8829 16.317 13.9766 16.1509L14.5582 15.1188C15.2851 13.8292 15.6694 12.3614 15.6694 10.876V9.10843C15.6694 8.53179 15.2068 8.06274 14.6381 8.06274H10.7607C10.4191 8.06274 10.1008 7.89142 9.90914 7.60467C9.71803 7.31791 9.67939 6.95438 9.80611 6.63315C10.2785 5.43599 10.518 4.17458 10.518 2.88393V2.27856C10.518 2.01217 10.3042 1.79489 10.041 1.79489H9.7206C9.50888 1.79489 9.32034 1.93905 9.26213 2.14536L9.0602 2.86147C8.62749 4.39553 7.9099 5.80475 6.92703 7.05101C6.42014 7.69398 5.66392 8.06274 4.85155 8.06274H3.30615C3.0218 8.06274 2.79102 7.82874 2.79102 7.54042C2.79102 7.2521 3.0218 7.0181 3.30615 7.0181H4.85155C5.34866 7.0181 5.81228 6.79194 6.1229 6.39811C7.01975 5.26049 7.675 3.97401 8.0696 2.57419L8.27153 1.85809C8.45543 1.20623 9.05144 0.750244 9.7206 0.750244H10.041C10.8724 0.750244 11.5483 1.43553 11.5483 2.27856V2.88393C11.5483 4.3083 11.2835 5.70028 10.7627 7.02123L14.6381 7.0181C15.775 7.0181 16.6996 7.95567 16.6996 9.10843V10.876C16.6996 12.5432 16.2685 14.1901 15.4525 15.6374L14.8709 16.6696C14.5943 17.16 14.0766 17.4645 13.5197 17.4645Z" fill="#667982" stroke="#667982" strokeWidth="0.428572" />
      <ellipse cx="2.79077" cy="14.8529" rx="0.515134" ry="0.522321" fill="#667982" stroke="#667982" strokeWidth="0.428572" />
    </SVG>

  );
};

ThumbsUpIcon.defaultProps = defaultProps;
export default ThumbsUpIcon;
