/* eslint-disable no-nested-ternary */
import React, {
  lazy, Suspense, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { Popover, Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBelts, selectDisclessPersonality, selectShowGlobalGilroy, selectShowMappingTooltip,
} from 'Selector/GlobalSearchContentSelector';
import {
  getLocalStorageValue, isSalesforce, isSalesforceRoute, MinWidthApp, showCertificationInApp,
  valueToObject,
} from 'Utils/UtilityFunctions';
import CongratulationBadgeIcon from 'Components/alerts/assets/svg/CongratulationBadgeIcon';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { SALES_X_CERTIFICATION, SALES_X_GILROY_CERTIFICATION, TOOLTIP_CERTIFICATION_IMPROVED } from 'Utils/Constants';
import { selectShowtooltip, selectActiveModule } from 'Selector/CertificationSelector';
import classNames from 'classnames';
import { actions as flurryActions } from 'Slice/HelperSlice';
import HTMLReactParser from 'html-react-parser';
import { actions as globalContentActions } from '../../../Slice/GlobalSearchContentSlice';
import {
  primaryFont, secondaryFont, primaryHeadingColor, wMedium,
  userPlaceHolder, maxWidth, retina, maxTablet, primaryColor, wMBold, greyColor,
  nutenixGradientColor,
} from '../../../Utils/utilsStyle';
import UserInfoCard from './UserInfoCard';

// svgs
import GearIcon from '../assets/svgIcons/GearIcon';
import FavIcon from '../assets/svgIcons/FavIcon';
import CrossCircle from '../assets/svgIcons/CrossCircle';
import CertificationGilroySvg from '../assets/svgIcons/CertificationGilroySvg';
import ArrowLineIcon from '../assets/svgIcons/ArrowLineIcon';

// import FreshDeskIcon from '../assets/svgIcons/FreshDeskIcon';

const UserIcon = lazy(() => import('../assets/svgIcons/UserIcon'));

const ContentWrapper = styled.div`
  max-width: 350px;
  h2 {
    font-size: 28px;
    color:${primaryHeadingColor};
    font-weight: 800;
    font-family: ${secondaryFont};
    letter-spacing: -0.45px;
    line-height: 1.111 !important;
    margin: 0;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 20px;
    }
  }
  .favIcon{
    width:14px;
  }
  .user-icon svg{
    @media all and (max-width: ${maxWidth}) , ${retina} {
      width:12px;
    }
  }
  p {
    font-size: 16px;
    color: #7E7E7E !important;
    font-weight: ${wMedium};
    font-family: ${primaryFont};
    letter-spacing: -0.4px;
    margin: 0;
    @media all and (max-width: ${maxWidth}) , ${retina} {
     font-size: 11px;
    }
  }
  .upperPart{
    padding: 20px 20px 8px 20px;
  }
  .lowerPart{
    padding: 0 47px 20px 23px;
  }
  .userInfoSection {
    margin-top: 18px;
  }
  .linksSection {
    margin-top: 5px;
    background-color: #F8F8F8;
    border-radius: 0 0 20px 20px;
    padding: 18px 47px 20px 23px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      border-radius: 0 0 10px 10px;
      padding: 12px 20px 10px 16px;
    }
    svg {
      width: 16px;
      path {
        fill: #7E7E7E;
      }
    }
    .xiq-certification-button {
      &.gilroy-certification-btn {
        svg {
          width: 18px;
          min-width: 18px;
        }
        .new-tag {
          font-size: 10px;
          color: ${primaryColor};
          background-color: #DEF6E6;
          padding: 4px 5px;
          border-radius: 3px;
          margin-left: 8px;
          letter-spacing: normal;
          font-weight: ${wMBold};
          line-height: 10px;
        }
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #7E7E7E !important;
      font-weight: ${wMedium};
      font-family: ${primaryFont};
      letter-spacing: -0.53px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
       font-size: 11px;
      }
      sup {
        font-size: 10px;
        margin-right: 3px;
      }
      svg {
      min-width: 16px;
      width: 16px;
      height: 19px;
      margin-right: 10px;
      }
    }
  }
  .linkMarginTop {
    padding-top: 10px;
  }
  .belt-image {
    width: 28px;
  }
`;

const Wrapper = styled.div`
  display:flex;
  margin-right: 40px;
  &.notBelt {
    margin-right: 0px;
  }
  @media all and (max-width: ${maxTablet}) {
    margin-right: 0px;
  }
  .ant-popover-inner {
    border-radius: 20px;
    padding: 0px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      border-radius: 10px;
    }
    .ant-popover-inner-content {
      width: 100%;
    }
  }
  .ant-popover {
    min-width: 295px;
    max-width: 350px;
    max-height: 342px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      min-width: 210px;
      max-width: 210px;
    }
    .ant-popover-content {
      height: fit-content;
    }
    .ant-popover-inner-content {
      height: fit-content !important;
    }
  }
  .cross-circle-svg {
    position: absolute;
    height: 20px;
    top: 11px;
    circle {
      fill: #fff;
      fill-opacity: unset;
    }
    path {
      stroke: #C3C2C1;
    }
  }
`;

const Image = styled.div<{ image: string, }>`
 width: 35px;
 height: 35px;
 border-radius: 50%;
 padding: 2px;
 background-image: url(${(props) => props.image});
 background-position:center;
 background-size:cover;
 background-color: #fff;
`;

const BeltDataWrapper = styled.span`
  display: flex;
  .skeleton-box {
    margin: 0px !important;
  }
  .belt-link {
    height: fit-content;
    display: inline-flex;
    position: relative;
    @media all and (max-width: ${maxTablet}) {
     display: none;
    }
  }
  video {
    position: absolute;
    top: -5px;
    left: -30px;
    @media all and (max-width: 1298px) and (min-width: 1280px) {
      left: -42px;
    }
    @media all and (max-width: 1280px) and (min-width: 1278px) {
      left: -37px;
    }
    @media all and (max-width: 1100px) {
      top: -7px;
      left: -36px;
    }
    @media all and (max-width: ${maxTablet}) {
      width: 30px;
    }
  }
`;

const Persona = styled.div<{ border: string }>`
 background: conic-gradient(from -70deg, ${(props) => props.border});
 width: 39px;
 height: 39px;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
`;

const CertificationTooltipWrapper = styled.div`
display: flex;
flex-direction: column;
.cross-circle-svg {
  position: absolute;
  height: 20px;
  top: 8px;
  right: 4px;
  circle {
    fill: #C9C9C9;
    fill-opacity: unset;
  }
  path {
    stroke: #fff;
  }
}
.cert-title {
  color: ${primaryHeadingColor};
  font-family: ${primaryFont};
  font-size: 16px;
  font-weight: ${wMedium};
  line-height: 20.757px;
  margin-bottom: 0px;
  &.cert-paragraph {
    color: ${greyColor};
    font-size: 10px;
    line-height: 12px;
    white-space: normal;
    margin-top: 4px;
  }
  &.cert-button {
    border-radius: 28.541px;
    background: ${primaryColor};
    color: #FFF;
    font-size: 14px;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0px;
    cursor: pointer;
    margin-top: 8px;
    height: 28px;
    svg {
      margin-left: 6px;
      width: 9px;
      height: 9px;
    }
  }
}
`;

type PropsForLinkComp = {
  link: string,
  Icon: () => JSX.Element,
};

const linksArray = [
  {
    link: 'View Favorites',
    Icon: FavIcon,
  },
  {
    link: 'Settings',
    Icon: GearIcon,
  },
];

const returnLinksComponent = ({ link, Icon }: PropsForLinkComp, index: number) => (
  <Link to={`/${link.toLowerCase().split(' ').pop() || ''}`}>
    <div
      key={link + String(index)}
      className={`d-flex align-items-center ${index !== 0 ? 'linkMarginTop' : ''}`}
    >
      <div
        className="d-flex align-items-center justify-content-start user-icon"
        style={{
          marginRight: '10px',
          minWidth: '16px',
        }}
      >
        <Icon />
      </div>
      <div>
        <p className="primary-color text-center">
          {link}
        </p>
      </div>
    </div>
  </Link>
);

const Content = function Content() {
  const dispatch = useDispatch();
  const flurryObject = useSelector(selectActiveModule);
  const allowGlobalGilroy = useSelector(selectShowGlobalGilroy);
  const disclessPersonality = useSelector(selectDisclessPersonality);

  const openCertification = (flag?: boolean) => {
    if (flag) {
      dispatch(certificationActions.setOpenGilroyDrawer(true));
      dispatch(certificationActions.getCertificationData({
        type: 'ai_cert',
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('gen_ai_certification_panel', 0, {
          ...flurryObject,
        }),
      ));
    } else {
      dispatch(certificationActions.setOpenGilroyDrawer(false));
      dispatch(certificationActions.getCertificationData({}));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('certification_start_profile', 0, {
          ...flurryObject,
        }),
      ));
    }
    dispatch(certificationActions.setOpenDrawer(true));
  };

  return (
    <ContentWrapper>
      <div className="upperPart">
        <div className="d-flex align-items-center">
          <div
            className="d-flex align-items-center"
            style={{
              marginRight: '10px',
            }}
          >
            <Suspense
              fallback={<div />}
            >
              <UserIcon />
            </Suspense>
          </div>
          <div>
            <h2 className="primary-color text-center">
              Account
            </h2>
          </div>
        </div>
        <div className="userInfoSection">
          <UserInfoCard />
        </div>
      </div>
      <div className="linksSection">
        {isSalesforce()
          ? null : (
            linksArray.slice(0, 1).map((link, index) => returnLinksComponent(link, index))
          )}
        {showCertificationInApp() && !disclessPersonality && allowGlobalGilroy && (
          <span
            role="button"
            tabIndex={0}
            onClick={() => openCertification(true)}
            onKeyPress={() => openCertification(true)}
            className="xiq-certification-button gilroy-certification-btn linkMarginTop"
          >
            <CertificationGilroySvg />
            {HTMLReactParser(SALES_X_GILROY_CERTIFICATION)}
            <span className="new-tag">New</span>
          </span>
        )}
        {showCertificationInApp() && !disclessPersonality && (
          <span
            role="button"
            tabIndex={0}
            onClick={() => openCertification()}
            onKeyPress={() => openCertification()}
            className="xiq-certification-button linkMarginTop"
          >
            <CongratulationBadgeIcon />
            {HTMLReactParser(SALES_X_CERTIFICATION)}
          </span>
        )}
        {isSalesforce()
          ? null : (
            linksArray.slice(1, 2).map((link) => returnLinksComponent(link, 2))
          )}
      </div>
    </ContentWrapper>
  );
};

const AccountPopOver = function AccountPopOver() {
  const belt = useSelector(selectBelts);
  const [image, setImage] = useState<string>('');
  const [colors, setColors] = useState<string>('');
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const showCertificationTooltip = useSelector(selectShowtooltip);
  const showMappingTooltip = useSelector(selectShowMappingTooltip);
  const dispatch = useDispatch();
  const flurryObject = useSelector(selectActiveModule);
  const isMinWidthApp = MinWidthApp();

  const handleCrossClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setPopoverOpen(true);
    if (showCertificationTooltip) dispatch(certificationActions.setTooltip(0));
    if (showMappingTooltip) dispatch(globalContentActions.setShowMappingTooltip(false));
  };

  const openCertification = () => {
    dispatch(certificationActions.setOpenGilroyDrawer(false));
    dispatch(certificationActions.getCertificationData({}));
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('certification_start_profile', 0, {
        ...flurryObject,
      }),
    ));
    dispatch(certificationActions.setOpenDrawer(true));
  };

  const tooltipContent = showCertificationTooltip ? (
    <CertificationTooltipWrapper onClick={openCertification}>
      <span
        onClick={(e) => handleCrossClick(e)}
        onKeyPress={() => { }}
        role="button"
        tabIndex={0}
      >
        <CrossCircle />
      </span>
      <h4 className="cert-title">Get Certified!</h4>
      <p className="cert-title cert-paragraph">{TOOLTIP_CERTIFICATION_IMPROVED}</p>
      <span
        className="cert-title cert-button"
        onClick={() => openCertification()}
        onKeyPress={() => openCertification()}
        role="button"
        tabIndex={0}
      >
        Start your Certification
        <ArrowLineIcon />
      </span>
    </CertificationTooltipWrapper>
  ) : showMappingTooltip ? (
    <span>
      You can map your profile from your settings.
      <span
        onClick={(e) => handleCrossClick(e)}
        onKeyPress={() => { }}
        role="button"
        tabIndex={0}
      >
        <CrossCircle />
      </span>
    </span>
  ) : '';

  useEffect(() => {
    if (getLocalStorageValue('profilePic') && getLocalStorageValue('profilePic') !== '') { setImage(getLocalStorageValue('profilePic') || ''); }
    if (getLocalStorageValue('personalitycolors') && getLocalStorageValue('personalitycolors') !== '') { setColors(getLocalStorageValue('personalitycolors') || ''); }
  }, [getLocalStorageValue('profilePic') || getLocalStorageValue('personalitycolors')]);

  useEffect(() => {
    const handleStorageChange = () => {
      setImage(getLocalStorageValue('profilePic') || '');
      setColors(getLocalStorageValue('personalitycolors') || '');
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const openBehavior = (open: boolean) => {
    if (open) {
      setPopoverOpen(true);
      dispatch(certificationActions.setTooltip(0));
      if (showCertificationTooltip) dispatch(certificationActions.setTooltip(0));
      else if (showMappingTooltip) dispatch(globalContentActions.setShowMappingTooltip(false));
    } else {
      setPopoverOpen(false);
    }
  };

  return (
    <BeltDataWrapper className="profileIndex">
      <Wrapper className={classNames('account-popover-wrapper', { notBelt: Object.keys(belt || {}).length === 0 })}>
        <Popover
          placement="bottomRight"
          content={<Content />}
          trigger="click"
          getPopupContainer={(e: HTMLElement) => e}
          onVisibleChange={openBehavior}
        >
          <Tooltip
            placement="bottomRight"
            title={tooltipContent}
            getPopupContainer={(e: HTMLElement) => e}
            overlayClassName={classNames('tooltip-account-popover', {
              mapProfile: showMappingTooltip && !showCertificationTooltip,
              addCrossIcon: showMappingTooltip || showCertificationTooltip,
              certificationNewToolTip: showCertificationTooltip,
            })}
            color="#fff"
            visible={!popoverOpen && (!!showCertificationTooltip || showMappingTooltip)
              && !isMinWidthApp}
          >
            <Button
              className="dropdown-user-profile"
              aria-label="Profile picture for menu"
            >
              <Persona border={disclessPersonality ? nutenixGradientColor : colors} className="persona-place">
                {image !== '' ? (
                  <Image
                    className="User_Image"
                    image={image}
                  />
                ) : (
                  <Image
                    className="User_Image"
                    image={userPlaceHolder}
                  />
                )}
              </Persona>
            </Button>
          </Tooltip>
        </Popover>
      </Wrapper>

      {belt?.id ? (
        !isSalesforceRoute()
          ? (
            <Link
              to="/sri/overview"
              className="belt-link"
            >
              <video width="40" height="40" autoPlay loop muted playsInline key={belt?.gif}>
                <source src={belt?.gif} type="video/mp4" />
              </video>
            </Link>
          ) : null
      ) : null}
    </BeltDataWrapper>
  );
};

export default AccountPopOver;
