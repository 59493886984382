import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import {
  maxTablet,
  maxWidth,
  primaryFont, primaryHeadingColor, retina, sadGilroyFace, secondaryFont, wMBold,
} from 'Utils/utilsStyle';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/ChatbotV2Slice';
import { selectChatHistory } from 'Selector/ChatBotV2Selector';
import { IsTablet, getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import classNames from 'classnames';

const ClearPromptModal = styled(Modal)`
&.clearPromptV3Modal {
  width: 420px !important;
  @media all and (max-width: ${maxTablet}) {
    width: 320px !important;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 49px !important;
      padding-right: 60px !important;
      padding-left: 60px !important;
      .gilroy-white-svg {
        width: 75px;
        height: 60px;
      }
      .clear-text-modal {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .ant-modal-footer {
      padding-bottom: 52px;
      .ant-btn {
        font-size: 12px;
        min-width: 76px;
      }
    }
  }
}
@media all and (max-width: ${maxWidth}), ${retina} {
  width: 335px !important;
}
@media all and (max-width: ${maxTablet}) {
  width: 320px !important;
}
.ant-modal-content {
    border-radius: 18px;
    @media all and (max-width: ${maxTablet}){
      border-radius: 10px;
    }
    .ant-modal-close{
      display: none;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 84px;
      padding: 84px 115px 0px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        padding-top: 32px !important;
        padding: 32px 35px 0px !important;
      }
      .gilroy-white-svg {
        width: 101.31px;
        height: 82.247px;
        flex-shrink: 0;
        margin-bottom: 16px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 75px;
          height: 60px;
        }
        @media all and (max-width: ${maxTablet}) {
          width: 55px !important;
          height: 45px !important;
        }
      }
      .clear-text-modal {
        font-family: ${secondaryFont};
        font-weight: ${wMBold};
        font-size: 28px;
        line-height: 40px;
        color: ${primaryHeadingColor};
        text-align: center;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 18px;
          line-height: 24px;
        }
        @media all and (max-width: ${maxTablet}) {
          font-size: 16px !important;
          line-height: 22px !important;
        }
      }
    }
    .ant-modal-footer {
      padding: 15px 10px 48px !important;
      @media all and (max-width: ${maxWidth}), ${retina} {
        padding: 15px 10px 24px !important; 
      }
      .ant-btn {
        font-family: ${primaryFont};
        font-weight: ${wMBold};
        font-size: 16px;
        border-radius: 23px;
        line-height: 16px;
        min-width: 92px;
        max-width: 92px;
        padding: 8px 0px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 12px;
          min-width: 85px !important;
          padding: 5px 0px;
        }
        &:hover {
          outline: none !important;
        }
      }
    }
  }
`;
type Props = {
  id: number;
  isVisible?: boolean;
  isSingleChat?: boolean;
  chatId?: number;
  setIsVisible: (a: boolean) => void;
} & typeof defaultProps;

const defaultProps = {
  isVisible: false,
  isSingleChat: false,
  chatId: 0,
};
const GilroyDeleteChatPopup = function GilroyDeleteChatPopup(props: Props) {
  const {
    isVisible, setIsVisible, isSingleChat, id, chatId,
  } = props;
  const host = window.location.href;
  const dispatch = useDispatch();
  const startTime = getCurrentTime();
  const chatHistory = useSelector(selectChatHistory);
  const popupText = isSingleChat ? 'Are you sure you want to delete this prompt?' : 'Are you sure you want to delete this chat?';
  const isGilroyV3 = !window.location.pathname.includes('feed');
  const isTab = IsTablet();
  const dontMap = !isGilroyV3 && isTab;
  const handleDelete = () => {
    if (isSingleChat && chatHistory?.length > 1) {
      dispatch(actions.deleteChat({
        isStopGenerating: false,
        channelId: id,
        chatIds: chatId.toString(),
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_deleteResponse_confirm', startTime, { chatIds: chatId.toString(), host }),
      ));
    } else {
      dispatch(actions.deleteConversation({
        channelId: id,
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject(isSingleChat ? 'xcgpt_deleteResponse_confirm' : 'xcgpt_deleteConversation_confirm_conversationHistory', startTime, { channelId: id, host }),
      ));
    }
    setIsVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsVisible(false);
    if (isSingleChat) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_deleteResponse_cancel', startTime, {
          host,
        }),
      ));
    }
  };

  return (
    <ClearPromptModal
      getContainer={dontMap ? document.body : () => document.querySelector('.container-inner-area') || document.body}
      visible={isVisible}
      onOk={handleDelete}
      onCancel={handleDeleteCancel}
      centered
      cancelText="Cancel"
      okText="Yes"
      closeIcon={null}
      width="582px"
      className={classNames('clear-prompt-modal', { clearPromptV3Modal: isGilroyV3 })}
    >
      <img src={sadGilroyFace} alt="sad-gilroy" className="gilroy-white-svg" />
      <h2 className="clear-text-modal">
        {popupText}
      </h2>
    </ClearPromptModal>
  );
};
GilroyDeleteChatPopup.defaultProps = defaultProps;
export default GilroyDeleteChatPopup;
