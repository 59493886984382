import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/LoginSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.login || initialState;

export const selectLoading = createSelector([selectDomain], (state) => state.loading);
export const selectErrorLogin = createSelector([selectDomain], (state) => state.errorLogin);
export const selectUserData = createSelector([selectDomain], (state) => state.userData);
export const selectSuccess = createSelector([selectDomain], (state) => state.success);
export const selectErrorReset = createSelector([selectDomain], (state) => state.errorReset);
export const selectCompliancePopup = createSelector(
  [selectDomain],
  (state) => state.compliancePopup,
);

export const selectUserId = createSelector(
  [selectDomain],
  (state) => state.userData,
);

export const selectResetPassword = createSelector([selectDomain], (state) => state.resetPassword);
