import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
cursor: pointer;
  &:hover {
    path {
      fill: red;
    }
  }
`;

type Props = {
  onClick?: () => void,
  fllColor?: string,
} & typeof defaultProps;

const defaultProps = {
  onClick: () => { },
  fllColor: '#667982',
};
const DeleteIconGilroy = function DeleteIconGilroy({ onClick, fllColor }: Props) {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      onClick={onClick}
      className="delete-icon-gilroy-svg"
    >
      <path d="M10.8942 9.06781C10.8942 7.74924 10.9005 6.43023 10.8905 5.11166C10.8874 4.6756 11.1936 4.35002 11.6057 4.33423C12.0175 4.3189 12.3408 4.66387 12.3408 5.11302C12.3417 7.76187 12.3439 10.4103 12.3394 13.0591C12.3372 14.4476 11.3113 15.6444 9.95529 15.8532C9.79475 15.878 9.63061 15.8875 9.46827 15.8875C7.54453 15.8897 5.62125 15.8884 3.69751 15.8888C2.26486 15.8888 1.09736 14.9206 0.843478 13.5146C0.815519 13.3604 0.809657 13.2007 0.809657 13.0433C0.807402 10.4116 0.807402 7.77991 0.807853 5.14774C0.807853 4.7252 1.01168 4.44471 1.37604 4.35723C1.81752 4.25171 2.24817 4.58766 2.25088 5.04582C2.25449 5.64874 2.25223 6.25165 2.25223 6.85502C2.25223 8.91765 2.25223 10.9803 2.25268 13.0429C2.25268 13.8203 2.86417 14.439 3.63754 14.4395C5.59509 14.4408 7.5522 14.4408 9.50975 14.4395C10.2822 14.4395 10.8942 13.819 10.8946 13.0402C10.8951 11.7158 10.8946 10.3913 10.8946 9.06691L10.8942 9.06781Z" fill={fllColor} />
      <path d="M6.55639 3.61705C4.64934 3.61705 2.74229 3.61796 0.835243 3.61615C0.390611 3.61615 0.0835166 3.29643 0.0929865 2.85676C0.101554 2.46759 0.432098 2.16862 0.859594 2.16817C1.72045 2.16681 2.58085 2.16817 3.44171 2.16817C3.69379 2.16817 3.69424 2.16817 3.69469 1.90842C3.69559 1.54767 3.69469 1.18691 3.69559 0.826153C3.6974 0.288174 3.98284 0.00272593 4.51586 0.00272593C5.88854 0.00272593 7.26122 0.00858822 8.63345 2.02559e-05C9.07177 -0.00268542 9.48077 0.265627 9.45507 0.827956C9.43929 1.17654 9.44831 1.52692 9.45191 1.87595C9.45552 2.21506 9.41539 2.16591 9.73511 2.16726C10.5847 2.17042 11.4338 2.16726 12.2834 2.16862C12.7429 2.16952 13.0577 2.48112 13.0527 2.9235C13.0487 3.28471 12.7416 3.60353 12.3786 3.6148C12.1594 3.62156 11.9398 3.6166 11.7206 3.61705C9.99936 3.61705 8.27765 3.61705 6.55639 3.61705ZM6.583 1.45297C6.14423 1.45297 5.70546 1.45567 5.26669 1.45116C5.16613 1.45026 5.12419 1.47867 5.1287 1.58374C5.13501 1.72985 5.13411 1.87686 5.1287 2.02341C5.12509 2.12893 5.15801 2.17313 5.273 2.17042C5.56521 2.16321 5.85788 2.16907 6.15054 2.16907C6.71873 2.16907 7.28692 2.16546 7.85511 2.17087C7.98138 2.17222 8.02602 2.13254 8.01791 2.00673C8.00889 1.8719 8.01024 1.73571 8.01926 1.60133C8.02738 1.48138 7.98183 1.44891 7.86549 1.45071C7.43799 1.45703 7.01049 1.45342 6.583 1.45297Z" fill={fllColor} />
      <path d="M8.73281 9.046C8.73281 9.86853 8.72108 10.6915 8.73777 11.5136C8.74588 11.9073 8.42436 12.2689 8.04196 12.2752C7.62123 12.2824 7.30376 11.974 7.28933 11.5357C7.28482 11.4063 7.28888 11.2764 7.28888 11.147C7.28888 9.62592 7.28753 8.10488 7.28888 6.58384C7.28888 6.17844 7.4661 5.9214 7.81288 5.81272C8.25661 5.67383 8.72965 6.01249 8.73145 6.47742C8.73506 7.33376 8.73236 8.19011 8.73236 9.04646H8.73281V9.046Z" fill={fllColor} />
      <path d="M4.41316 9.02937C4.41316 8.19557 4.41226 7.36177 4.41316 6.52797C4.41361 6.10543 4.71845 5.78571 5.11844 5.78346C5.53286 5.7812 5.85754 6.09416 5.85844 6.51625C5.8616 8.18971 5.8607 9.86271 5.85664 11.5362C5.85574 11.9614 5.53241 12.278 5.12205 12.2753C4.72341 12.2726 4.41361 11.9492 4.41271 11.5308C4.41136 10.697 4.41271 9.86316 4.41271 9.02937H4.41316Z" fill={fllColor} />
    </SVG>
  );
};
DeleteIconGilroy.defaultProps = defaultProps;
export default DeleteIconGilroy;
