import React from 'react';

type Props = {
  color?: string,
};
const defaultProps = {
  color: '#000',
};

const WorldIcon = function WorldIcon(props: Props) {
  const { color } = props;
  return (
    <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" className="world-icon-svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Public-company-without-workbench-updated" transform="translate(-856.000000, -196.000000)" fill={color} fillRule="nonzero">
          <g id="noun-website-3068289" transform="translate(856.000000, 196.000000)">
            <path d="M12.5,0 C5.59644063,0 0,5.59644063 0,12.5 C0,19.4035594 5.59644063,25 12.5,25 C19.4035594,25 25,19.4035594 25,12.5 C24.9920329,5.59974338 19.4002566,0.00796705174 12.5,0 Z M22.6202868,11.3433958 L17.7567661,11.3433958 C17.6411057,8.16273421 17.0541291,5.09195003 16.0218598,2.9406662 C19.6274117,4.27658507 22.1774349,7.52389768 22.6202868,11.3433958 L22.6202868,11.3433958 Z M12.5,22.6867916 C11.5197779,22.6867916 9.77330557,19.2892667 9.5593338,13.6566042 L15.4406662,13.6566042 C15.2266944,19.2892667 13.4802221,22.6867916 12.5,22.6867916 Z M9.5593338,11.3433958 C9.77330557,5.71073329 11.5197779,2.31320842 12.5,2.31320842 C13.4802221,2.31320842 15.2266944,5.71073329 15.4406662,11.3433958 L9.5593338,11.3433958 Z M8.98103169,2.9406662 C7.94297941,5.09195003 7.35600278,8.17430025 7.24612538,11.3433958 L2.37971316,11.3433958 C2.82358107,7.5224969 5.37611451,4.27483394 8.9839232,2.9406662 L8.98103169,2.9406662 Z M2.37971316,13.6566042 L7.24323387,13.6566042 C7.35889429,16.8372658 7.94587092,19.90805 8.97814018,22.0593338 C5.37258829,20.7234149 2.82256511,17.4761023 2.37971316,13.6566042 Z M16.0160768,22.0593338 C17.0541291,19.90805 17.6411057,16.8256997 17.7509831,13.6566042 L22.6202868,13.6566042 C22.1764189,17.4775031 19.6238855,20.7251661 16.0160768,22.0593338 L16.0160768,22.0593338 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  );
};
WorldIcon.defaultProps = defaultProps;
export default WorldIcon;
