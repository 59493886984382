/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoginAuthState } from 'Types/GlobalTypes';
import { CompliancePopup, User } from 'Types/LoginTypes';
import {
  LoginRequest, ForgetPasswordRequest, ResetPasswordRequest, UserProfileRequest,
} from 'Types/RequestBodies';
import { GlobalResponse } from 'Types/ResponseBodies';

export const initialState: LoginAuthState = {
  userData: null,
  loading: false,
  errorLogin: '',
  success: false,
  errorReset: '',
  resetPassword: undefined,
  compliancePopup: {} as CompliancePopup,
};

const userAuthSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginUser(state, action: PayloadAction<LoginRequest>) {
      state.loading = true;
      state.errorLogin = '';
      state.userData = null;
    },
    loginUserLoaded(state, action: PayloadAction<User>) {
      const user = action.payload;
      state.userData = user;
      state.loading = false;
    },
    loginError(state, action: PayloadAction<string>) {
      state.errorLogin = action.payload;
      state.loading = false;
    },
    forgetPassword(state, action: PayloadAction<ForgetPasswordRequest>) {
      state.loading = true;
      state.success = false;
    },
    forgetPasswordLoaded(state, action: PayloadAction<boolean>) {
      state.loading = false;
      state.success = action.payload;
    },
    resetForgetPassword(state, action: PayloadAction<boolean>) {
      state.success = action.payload;
      state.errorReset = '';
    },

    resetPassword(state, action: PayloadAction<ResetPasswordRequest>) {
      state.resetPassword = {
        loading: true,
        loaded: false,
        errorMessage: '',
        success: false,
        error: false,
      };
    },
    resetPasswordLoaded(state, action: PayloadAction<boolean>) {
      state.resetPassword = {
        loading: false,
        loaded: true,
        errorMessage: '',
        success: true,
        error: false,
      };
    },
    resetPasswordError(state, action: PayloadAction<string>) {
      state.resetPassword = {
        loading: false,
        loaded: true,
        errorMessage: action.payload,
        success: false,
        error: true,
      };
    },
    resetResetPassword(state, action: PayloadAction<boolean>) {
      state.resetPassword = undefined;
    },
    getUserProfile(state, action: PayloadAction<UserProfileRequest>) {
      state.loading = true;
      state.errorLogin = '';
      state.userData = null;
    },
    setCompliancePopup(state, action: PayloadAction<CompliancePopup>) {
      state.compliancePopup = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = userAuthSlice;
