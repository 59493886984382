import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/SettingsSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.setting || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.updateLoading,
);

export const selectUpdated = createSelector(
  [selectDomain],
  (state) => state.settingUpdated,
);

export const selectSubscription = createSelector(
  [selectDomain],
  (state) => state.subscription,
);
