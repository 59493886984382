import React from 'react';

type Props = {
  color?: string,
} & typeof defaultProps;

const defaultProps = {
  color: '#fff',
};

const UploadDocumentIcon = function UploadDocumentIcon(props: Props) {
  const { color } = props;
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" className="upload-document-svg upload-alignment">
      <path d="M1.35755 2.00391H11.4377C11.7481 2.00391 12 1.75222 12 1.44159C12 1.13112 11.7483 0.879272 11.4377 0.879272H1.35755C1.04707 0.879272 0.795227 1.13096 0.795227 1.44159C0.795227 1.75222 1.04691 2.00391 1.35755 2.00391Z" fill={color} stroke={color} strokeWidth="0.403369" />
      <path d="M6.91636 3.92988C6.8902 3.90372 6.86136 3.88039 6.83063 3.85975C6.8166 3.85029 6.80132 3.84383 6.78666 3.83595C6.76917 3.82634 6.75246 3.81578 6.73386 3.80821C6.7326 3.80774 6.7315 3.80679 6.7304 3.80632C6.71274 3.79923 6.69415 3.79545 6.67602 3.79025C6.66026 3.78568 6.64529 3.77953 6.62906 3.77638C6.5564 3.76188 6.48139 3.76188 6.40873 3.77638C6.3925 3.77969 6.37753 3.78568 6.36177 3.79025C6.34238 3.79592 6.32268 3.80049 6.30377 3.80821C6.28517 3.81594 6.26847 3.82649 6.25097 3.83595C6.23632 3.84399 6.22103 3.85045 6.207 3.85975C6.17612 3.88039 6.14743 3.90372 6.12127 3.92988L3.95426 6.09673C3.73473 6.31627 3.73473 6.67228 3.95426 6.89198C4.1738 7.11152 4.52982 7.11152 4.74952 6.89198L5.95658 5.68492V12.0476C5.95658 12.3582 6.20827 12.6099 6.5189 12.6099C6.82953 12.6099 7.08121 12.3582 7.08121 12.0476V5.68492L8.28828 6.89198C8.50781 7.11152 8.86383 7.11152 9.08353 6.89198C9.30306 6.67244 9.30306 6.31642 9.08353 6.09673L6.91636 3.92988Z" fill={color} stroke={color} strokeWidth="0.403369" />
    </svg>

  );
};
UploadDocumentIcon.defaultProps = defaultProps;
export default UploadDocumentIcon;
