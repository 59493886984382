import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import {
  maxTablet,
  maxWidth,
  primaryFont,
  primaryHeadingColor, retina, secondaryFont, wBold, wLight, wMBold,
} from 'Utils/utilsStyle';
import { useDispatch } from 'react-redux';
import { actions } from 'Slice/ChatbotV2Slice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import CrossCircle from '../assets/svgIcons/CrossCircle';

const FeedBackModal = styled(Modal)`
  &.feedback-modal-c {
   @media all and (max-width: ${maxWidth}), ${retina} {
    width: 437px !important;
   }
   @media all and (max-width: ${maxTablet}) {
    width: 320px !important;
   }
  }
  .ant-modal-content {
    border-radius: 18px !important;
    text-align: left;
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body{
      padding: 24px 24px 0px !important;
      @media all and (max-width: ${maxWidth}), ${retina} {
        padding: 18px 24px 24px !important;
      }
      @media all and (max-width: ${maxTablet}) {
        padding-bottom: 0px !important;
        padding-top: 14px !important;
      }
      h4.heading_logout_popup{
        font-family: ${secondaryFont};
        font-size: 18px;
        margin-bottom: 16px;
        color: ${primaryHeadingColor};
        font-weight: ${wBold};
        @media all and (max-width: ${maxTablet}) {
          font-size: 16px;
          line-height: normal;
          margin-bottom: 8px;
        }
      }
      .textarea-input {
        resize: none;
        height: 130px;
        width: 100%;
        padding: 8px 10px;
        width: 100%;
        overflow: hidden;
        font-family: ${primaryFont};
        color: #000;
        font-style: normal;
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 6px;
        &:focus-visible {
          outline: none;
        }
        &::placeholder {
         font-size: 12px;
         font-weight: ${wLight};
         font-style: italic;
         color: #5E7079;
         opacity: 0.4;
        }
      }

    }
    grammarly-extension {
      display: none;
    }
    .ant-modal-footer{
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 18px 24px 24px !important;
      @media all and (max-width: ${maxTablet}) {
        padding: 8px 24px 24px !important;
      }
      .ant-btn {
        border-radius: 25px;
        padding: 5px;
        min-width: 75px;
        font-size: 14px;
        height: 30px;
        margin: 0px 4px;
        font-size: 12px;
        font-weight: ${wMBold};
        font-family: ${primaryFont};
        @media all and (max-width: ${maxTablet}) {
          font-size: 12px;
          min-width: 85px;
        }
      }
    }
  }
`;

type Props = {
  isVisible?: boolean;
  chatId: string;
  isFeedbackPositive: boolean;
  setIsVisible: (a: boolean) => void;
} & typeof defaultProps;

const defaultProps = {
  isVisible: false,
};

const GilroyFeedbackChatPopup = function GilroyFeedbackChatPopup(props: Props) {
  const {
    isVisible, setIsVisible, chatId, isFeedbackPositive,
  } = props;
  const [feedback, setFeedback] = useState('');
  const dispatch = useDispatch();
  const startTime = getCurrentTime();
  const popupText = isFeedbackPositive ? 'What did you like about this output?' : 'What was the issue with the output?';
  const host = window.location.href;

  const handleFeedback = (isOk: boolean) => {
    dispatch(actions.sendFeedback({
      responseId: chatId,
      reason: isOk ? feedback : '',
      responseQuality: isFeedbackPositive ? 'True' : 'False',
    }));
    if (isOk) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_feedbackSend_confirmed', startTime, {
          responseId: chatId,
          reason: feedback,
          responseQuality: isFeedbackPositive ? 'True' : 'False',
          host,
        }),
      ));
    }
    setIsVisible(false);
    setFeedback('');
  };
  return (
    <FeedBackModal
      getContainer={() => document.querySelector('.container-inner-area') || document.body}
      visible={isVisible}
      onOk={() => handleFeedback(true)}
      // confirmLoading={sending}
      onCancel={() => handleFeedback(false)}
      centered
      cancelText="Skip"
      okText="Send"
      closeIcon={<CrossCircle />}
      width="420px"
      className="logout-modal-c feedback-modal-c"
    >
      <h4 className="heading_logout_popup">{popupText}</h4>
      <textarea
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="Type here"
        rows={4}
        value={feedback}
        className="textarea-input"
      />
    </FeedBackModal>
  );
};
GilroyFeedbackChatPopup.defaultProps = defaultProps;
export default GilroyFeedbackChatPopup;
