import DeleteIconGilroy from 'Components/common/assets/svgIcons/DeleteIconGilroy';
import Edit from 'Components/common/assets/svgIcons/Edit';
import EditableInputGlobal from 'Components/common/components/EditableInputGlobal';
// import IconTooltipGlobal from 'Components/common/components/IconTooltipGlobal';
import GilroyDeleteChatPopup from 'Components/common/modal/GilroyDeleteChatPopup';
import { selectEditConversationLoading, selectedConversationId } from 'Selector/ChatBotV2Selector';
import { actions } from 'Slice/ChatbotV2Slice';
import { Conversation } from 'Types/ChatBotV2Types';
import {
  maxTablet,
  maxWidth,
  nutenixColor,
  primaryColor,
  primaryFont, retina, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actions as UiAction } from 'Slice/UISlice';
import {
  IsTablet, getCurrentTime, valueToObject,
} from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import HTMLReactParser from 'html-react-parser';
import { translateSkipAreas } from 'Utils/Constants';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import SideMenuIcon from 'Components/common/assets/svgIcons/SideMenuIcon';
import { Popover, Tooltip } from 'antd';
import { isExpandFlag } from 'Selector/UISelector';

const StyledWrapper = styled.span<{ color: string }>`
&.noBorder {
 .img-title-div {
  .image-persona {
    border: 0px;
    .history-img {
      padding: 0px;
    }  
  }
 }
}
&.editAreaWrapper {
  padding: 2px 8px 2px 20px;
  .img-title-div {
    width: 100% !important;
  }
  .action-div-wrapper {
    display: none !important;
   }
  .editable-input-global {
    margin-left: 8px;
  }
}
 display: flex;
 align-items: center;
 position: relative;
 margin-bottom: 4px;
 padding: 8px 20px;
 cursor: pointer;
 &:not(.editAreaWrapper):hover {
   background: #DEF6E6;
  }
  &.active {
   padding: 8px 16px 8px 20px;
   background: #DEF6E6;
   justify-content: space-between;
   .img-title-div {
    width: calc(100% - 24px);
  }
   .action-div-wrapper {
    display: flex;
   }
  }
 .img-title-div {
    display: flex;
    align-items: center;
    width:100%;
    .image-persona {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      border: 2px solid ${(props) => props.color};
      border-radius: 50%;
      .history-img {
        width: inherit;
        height: inherit;
        border-radius: 50%;
        padding: 2px;
      }
    }
    .title-text {
      width: calc(100% - 25px);
      font-family: ${primaryFont};
      font-weight: ${wMedium};
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.4px;
      color: #616161;
      margin-left: 8px;
      margin-bottom: 0px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        line-height: 20px;
      }
    }
  }
 .action-div-wrapper {
  display: none;
  align-items: center;
  .side-menu-popover {
    height: 23px;
    .side-menu-icon {
      transform: rotate(90deg);
      width: 23px;
    }
    .ant-popover {
      left: calc(100% - 94px) !important;
      @media all and (max-width: ${maxTablet}) {
        left: calc(100% - 96px) !important;
      }
      &.ant-popover-placement-bottomRight {
        padding-top: 0px !important;
      }
      .ant-popover-content{
        .ant-popover-inner {
          border-radius: 8px;
          .ant-popover-inner-content {
            height: unset !important;
            width: unset !important;
            padding: 9px 16px;
            .action-div {
              display: flex;
              flex-direction: column;
              .action-point {
                cursor: pointer;
                display: flex;
                align-items: center;
                color: #667982;
                font-family: ${primaryFont};
                font-size: 14px;
                font-weight: ${wMedium};
                line-height: 16px;
                letter-spacing: -0.4px;
                &:first-child {
                  &:hover {
                    .edit-icon-svg {
                      path {
                        fill: ${primaryColor};
                      }
                    }
                  }
                }
                &:nth-child(2) {
                  &:hover {
                    .delete-icon-gilroy-svg {
                      path {
                        fill: red;
                      }
                    }
                  }
                }
                .edit-icon-svg {
                  min-width: 18px;
                }
                .delete-icon-gilroy-svg {
                  min-width: 14px;
                  width: 18px;
                }
                svg {
                  margin-right: 5px;
                }
                &:first-child {
                  margin-bottom: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
 }
`;

type Props = {
  conversation: Conversation;
};

type PopOverProps = {
  onDelete: () => void;
  onRename: () => void;
};

const PopoverContent = function PopoverContent(props: PopOverProps) {
  const {
    onDelete, onRename,
  } = props;

  return (
    <div className={classNames('action-div')}>
      <span tabIndex={0} role="button" className="action-point" onClick={onRename} onKeyDown={onRename}>
        <Edit fllColor="#667982" />
        Edit
      </span>
      <span tabIndex={0} role="button" className="action-point" onClick={onDelete} onKeyDown={onDelete}>
        <DeleteIconGilroy onClick={onDelete} />
        Delete
      </span>
    </div>
  );
};

const ChatBotHistoryTitleTile = function ChatBotHistoryTitleTile(props: Props) {
  const { conversation } = props;
  const { avatar, channelHeading, channelId } = conversation;
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [editable, setEditable] = useState(false);
  const editLoading = useSelector(selectEditConversationLoading);
  const selectedConvoId = useSelector(selectedConversationId);
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const startTime = getCurrentTime();
  const isTab = IsTablet();
  const host = window.location.href;
  const isExpand = useSelector(isExpandFlag);
  const isGilroyV3 = disclessPersonality ? (!window.location.pathname.includes('feed') && !window.location.pathname.includes('person'))
    : !window.location.pathname.includes('feed'); // add ere neutanix check

  const dispatch = useDispatch();

  useEffect(() => {
    setEditable(false);
  }, [isExpand]);

  useEffect(() => {
    if (!editLoading) {
      setEditable(false);
    }
  }, [editLoading]);

  const handleConversationClick = () => {
    if (selectedConvoId !== channelId) {
      dispatch(actions.resetChatHistory());
      dispatch(actions.setSelectedConversationId(channelId));
      dispatch(actions.setShowLibrary(false));
      dispatch(actions.setIsRegenerate(false));
      dispatch(UiAction.isEditChatOpenInput(false));
      dispatch(UiAction.isDrawerPanelOpen(false));
      if (isTab) {
        dispatch(actions.setHistoryPanelOpened(false)); // check this
      }
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_previousConversation_clicked_conversationHistory', startTime, { channelId, host }),
      ));
    }
  };

  useEffect(() => {
    setEditable(false);
  }, [selectedConvoId]);

  const handleEdit = () => {
    setEditable(true);
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_editSubject_open_conversationHistory', startTime, {
        host,
      }),
    ));
  };

  const handleDelete = () => {
    setShowDeletePopup(true);
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_deleteConversation_clicked_conversationHistory', startTime, {
        host,
      }),
    ));
  };

  return (
    <StyledWrapper
      color={disclessPersonality ? nutenixColor : avatar.color}
      className={classNames('chat-bot-history-title-tile', {
        editAreaWrapper: editable,
        active: selectedConvoId === channelId,
        noBorder: !avatar.color.length,
      })}
      onClick={handleConversationClick}
    >
      <div className="img-title-div">
        {isExpand && !isGilroyV3 ? (
          <Tooltip
            placement="right"
            getPopupContainer={(e) => document.querySelector('.chatbot-v2') || e}
            className="triggers-bullet-tooltip imageHover"
            overlayClassName="image-title-tooltip-gilroy"
            color="#fff"
            title={HTMLReactParser(channelHeading)}
            zIndex={0}
          >
            <div className="image-persona">
              <img src={avatar.image} alt="history-img" className="history-img" />
            </div>
          </Tooltip>
        ) : (
          <div className="image-persona">
            <img src={avatar.image} alt="history-img" className="history-img" />
          </div>
        )}
        {editable && selectedConvoId === channelId ? (
          <EditableInputGlobal
            defaultText={channelHeading}
            id={channelId}
            setIsEditing={setEditable}
            disableTick={editLoading}
          />
        ) : (
          <h3 className={`title-text text-truncate ${translateSkipAreas}`}>{HTMLReactParser(channelHeading)}</h3>
        )}
      </div>
      <div className={classNames('action-div-wrapper')}>
        {!showDeletePopup
          ? (
            <Popover
              getPopupContainer={(e: HTMLElement) => e}
              content={(
                <PopoverContent
                  onDelete={handleDelete}
                  onRename={handleEdit}
                />
              )}
              trigger="click"
              placement="bottomRight"
              className="side-menu-popover"
            >
              <span>
                <SideMenuIcon color="#667982" />
              </span>
            </Popover>
          )
          : null}
      </div>
      <GilroyDeleteChatPopup
        id={channelId}
        isVisible={showDeletePopup}
        setIsVisible={setShowDeletePopup}
      />
    </StyledWrapper>
  );
};

export default ChatBotHistoryTitleTile;