import { useEffect, useState } from 'react';
import AffinitySearchResultItemSkeleton from 'Components/common/skeleton/AffinitySearchResultItemSkeleton';
import AffinitySearchResultItem from 'Components/person/components/AffinitySearchResultItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectExecutivesEnded,
  selectExecutivesLoading, selectExecutivesSearched,
  selectOrganizationExecutives,
  selectSearchedEnded, selectSearchedPage,
} from 'Selector/GroupsSelector';
import { Executive } from 'Types/PersonTypes';
import { actions } from 'Slice/GroupsSlice';
import NoDataFound from 'Components/common/components/NoDataFound';
import { NO_PERSON_FOUND } from 'Utils/Constants';
import styled from 'styled-components';

const Wrapper = styled.div`
  .no_data_found{
    height: 140px;
  }
`;

type Props = {
  handleTag: (tagPerson: Executive) => void
  query: string
};

const PersonDropdown = function PersonDropdown(props: Props) {
  const { handleTag, query } = props;
  const dispatch = useDispatch();
  const [executivesToDisplay, setExecutivesToDisplay] = useState<Executive[]>([]);
  const executivesLoading = useSelector(selectExecutivesLoading);
  const executivesSearched = useSelector(selectExecutivesSearched);
  const searchedEnded = useSelector(selectSearchedEnded);
  const organizationExecutives = useSelector(selectOrganizationExecutives);
  const executivesEnded = useSelector(selectExecutivesEnded);
  const searchedPage = useSelector(selectSearchedPage);

  const personLoader = function personLoader() {
    return (
      <>
        {[1, 2, 3].map((item) => <AffinitySearchResultItemSkeleton key={item} />)}
      </>
    );
  };

  const fetchMorePeople = () => {
    if (executivesSearched?.length && !searchedEnded && !executivesLoading) {
      dispatch(actions.getOrganizationExecutives({
        page: searchedPage,
        query,
      }));
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!executivesSearched?.length) {
        dispatch(actions.getOrganizationExecutives({
          page: searchedPage,
          query,
        }));
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  useEffect(() => {
    if (organizationExecutives.length || executivesSearched.length) {
      if (!query.length) {
        const size = organizationExecutives.length > 4 ? 4 : organizationExecutives.length;
        const exec = organizationExecutives.slice(0, size);
        setExecutivesToDisplay(exec || []);
      } else setExecutivesToDisplay(executivesSearched);
    }
  }, [organizationExecutives, executivesSearched]);

  return (
    <Wrapper>
      <InfiniteScroll
        hasMore
        dataLength={executivesSearched?.length ?? 0}
        next={fetchMorePeople}
        className="o-x-hidden o-y-hidden"
        loader={false}
        scrollableTarget="scrollableDiv"
      >
        {executivesToDisplay.map((tagPerson) => (
          <AffinitySearchResultItem
            key={tagPerson.personId}
            person={tagPerson}
            onClickHandler={() => handleTag(tagPerson)}
          />
        ))}
        {executivesLoading && personLoader()}
        {!executivesLoading && !executivesSearched.length && (searchedEnded || executivesEnded)
          && !executivesToDisplay.length
          ? <NoDataFound title={NO_PERSON_FOUND} /> : ''}
      </InfiniteScroll>
    </Wrapper>
  );
};

export default PersonDropdown;
