import React from 'react';

type Props = {
  onButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & typeof defaultProps;

const defaultProps = {
  onButtonClick() { },
};

const ClosePanelIcon = function ClosePanelIcon(props: Props) {
  const {
    onButtonClick,
  } = props;

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onButtonClick} className="close-panel-icon">
      <circle opacity="0.543816" cx="8" cy="8.61328" r="8" fill="#606060" fillOpacity="0.620013" />
      <path d="M5.2462 11.5022L10.8865 5.86195" stroke="white" strokeWidth="1.54896" strokeLinecap="square" />
      <path d="M5.24589 5.86296L10.8862 11.5032" stroke="white" strokeWidth="1.54896" strokeLinecap="square" />
    </svg>
  );
};

ClosePanelIcon.defaultProps = defaultProps;
export default ClosePanelIcon;
