import React from 'react';

type Props = {
  fillColor?: string,
};
const defaultProps = {
  fillColor: '#424B50',
};

const HistoryIcon = function HistoryIcon(props: Props) {
  const { fillColor } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="history-icon">
      <path d="M0.943848 7.5872C0.943848 5.84642 0.943848 4.11658 0.943848 2.37579C0.943848 1.13863 1.67739 0.251812 2.8817 0.0218967C2.99119 0 3.11162 0 3.23205 0C5.94724 0 8.67338 0 11.3886 0C12.6586 0 13.6549 1.00725 13.6549 2.27726C13.6549 4.02899 13.6549 5.78073 13.6549 7.53246C13.6549 7.65289 13.6877 7.74048 13.7863 7.82807C14.6183 8.6054 15.122 9.55791 15.2643 10.6746C15.5927 13.2694 13.7972 15.5248 11.3886 15.9298C10.2061 16.1269 9.10036 15.9079 8.08217 15.2839C7.98363 15.2182 7.87415 15.1963 7.76467 15.1963C6.26474 15.1963 4.75387 15.1963 3.25395 15.1963C2.20291 15.1963 1.33799 14.5394 1.04238 13.5322C0.976693 13.3022 0.954796 13.0614 0.954796 12.8096C0.954796 11.0688 0.954796 9.31704 0.954796 7.57625H0.943848V7.5872ZM12.4287 6.94125C12.4287 6.80987 12.4287 6.72228 12.4287 6.62375C12.4287 5.18952 12.4287 3.76623 12.4287 2.332C12.4287 1.6751 11.9907 1.23716 11.3448 1.23716C10.5346 1.23716 9.71347 1.23716 8.9033 1.23716C7.02018 1.23716 5.12612 1.23716 3.243 1.23716C2.69558 1.23716 2.27955 1.59846 2.20291 2.14588C2.15911 2.46338 2.18101 2.76993 2.18101 3.08743C2.18101 6.32814 2.18101 9.5798 2.18101 12.8205C2.18101 13.5212 2.59705 13.9591 3.2649 13.9701C4.33783 13.9701 5.41077 13.9701 6.49466 13.9701C6.63699 13.9701 6.70268 13.9701 6.59319 13.8059C6.11147 13.0285 5.84871 12.1746 5.8706 11.2658C5.8925 9.99584 6.34138 8.89006 7.23915 7.99229C8.10407 7.11642 9.16606 6.64565 10.4032 6.5909C11.093 6.55806 11.7608 6.67849 12.4287 6.9522V6.94125ZM10.5893 7.80617C8.66243 7.80617 7.10777 9.33894 7.10777 11.2987C7.10777 13.2694 8.69528 14.7803 10.6222 14.7803C12.4944 14.7803 14.0819 13.2037 14.0709 11.2877C14.0709 9.27325 12.4177 7.79522 10.5893 7.80617Z" fill={fillColor} />
      <path d="M7.2938 2.46338C8.36674 2.46338 9.43967 2.46338 10.5236 2.46338C10.9286 2.46338 11.2024 2.71519 11.1914 3.08743C11.1914 3.43778 10.9177 3.68959 10.5126 3.68959C8.35579 3.68959 6.19896 3.68959 4.04214 3.68959C3.58231 3.68959 3.2867 3.2845 3.42903 2.86847C3.51662 2.61666 3.74654 2.46338 4.02024 2.46338C5.10413 2.46338 6.19896 2.46338 7.28285 2.46338H7.2938Z" fill={fillColor} />
      <path d="M5.66259 6.15297C5.12612 6.15297 4.58965 6.15297 4.05318 6.15297C3.68094 6.15297 3.40723 5.87926 3.40723 5.53987C3.40723 5.18952 3.69188 4.92676 4.05318 4.92676C5.12612 4.92676 6.18811 4.92676 7.26104 4.92676C7.64424 4.92676 7.92889 5.18952 7.92889 5.53987C7.92889 5.89021 7.64424 6.15297 7.26104 6.15297C6.73552 6.15297 6.19905 6.15297 5.67353 6.15297H5.66259Z" fill={fillColor} />
      <path d="M4.42523 8.61635C4.3048 8.61635 4.18437 8.61635 4.06393 8.61635C3.69169 8.61635 3.39608 8.33169 3.40703 7.9923C3.40703 7.64195 3.69169 7.39014 4.06393 7.39014C4.32669 7.39014 4.58945 7.39014 4.85221 7.39014C5.19161 7.39014 5.46532 7.66385 5.47627 7.9923C5.47627 8.33169 5.20256 8.6054 4.86316 8.61635C4.72083 8.61635 4.57851 8.61635 4.44713 8.61635H4.42523Z" fill={fillColor} />
      <path d="M8.66005 11.3404L8.37012 11.3404C8.2928 11.3404 8.24931 11.4322 8.29763 11.4951L8.35079 11.5724C8.49092 11.7657 8.64555 11.9541 8.80985 12.1232L8.81468 12.1281C8.863 12.1909 8.95965 12.1909 9.00797 12.1281C9.1771 11.9541 9.33173 11.7705 9.47186 11.5724L9.52501 11.4999C9.57334 11.4371 9.52985 11.3453 9.45253 11.3453L9.16743 11.3453C9.18193 10.5624 9.82461 9.93426 10.6123 9.93426C11.4096 9.93426 12.0571 10.5818 12.0571 11.3791C12.0571 12.1764 11.4096 12.8143 10.6123 12.8143C10.3465 12.8143 10.0856 12.7418 9.86327 12.6065C9.74246 12.534 9.583 12.5726 9.51052 12.6934C9.43804 12.8143 9.47669 12.9737 9.5975 13.0462C9.90193 13.2298 10.2547 13.3313 10.6123 13.3313C11.6898 13.3313 12.5693 12.4518 12.5693 11.3743C12.5693 10.2967 11.6947 9.41238 10.6123 9.41238C9.54434 9.41238 8.67455 10.2725 8.66005 11.3404Z" fill={fillColor} stroke={fillColor} strokeWidth="0.4" />
    </svg>
  );
};
HistoryIcon.defaultProps = defaultProps;
export default HistoryIcon;
