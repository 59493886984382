import {
  SecondaryColor,
  hiGilroyHeaderGifv2,
  maxMobile,
  maxTablet,
  primaryColor,
  secondaryFont,
  wMBold,
} from 'Utils/utilsStyle';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;
const StyledWrapper = styled.div`
border-radius: 8px 8px 0px 0px;
background: #FFF;
box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
min-height: 68px;
height: 68px;
padding-right: 50px;
@media all and (max-width: ${maxTablet}) {
  position: relative;
  z-index: 0;
}
.header-v3-heading {
  color: ${SecondaryColor};
  font-family: ${secondaryFont};
  font-size: 30px;
  font-weight: ${wMBold};
  line-height: 40px;
  letter-spacing: -0.792px;
  margin-bottom: 0px;
  padding: 14px 0px 14px 24px;
  @media all and (max-width: ${maxTablet}) {
    width: 0px;
    z-index: 1;
  }
  @media all and (max-width: ${maxMobile}) {
    padding: 14px 0px 14px 10px;
    font-size: 20px !important;
  }
  &.primary-color {
    color: ${primaryColor};
    padding-left: 5px;
  }
}
.image-container-gif {
  width: 273px;
  height: 100%;
  @media all and (max-width: ${maxTablet}) {
    width: 240px;
  }
}
`;

const GilroyHeaderV3 = function GilroyHeaderV3() {
  return (
    <Wrapper className="header-gill-roy">
      <StyledWrapper className="gill-header-inner">
        <h3 className="header-v3-heading">
          Ask
          <span className="header-v3-heading primary-color">
            Gilroy
          </span>
        </h3>
        <img className="image-container-gif image-container-gif-v3" src={hiGilroyHeaderGifv2} alt="container-gif" />
      </StyledWrapper>
    </Wrapper>
  );
};
export default GilroyHeaderV3;
