/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import { PayloadAction } from '@reduxjs/toolkit';
import { actions } from 'Slice/ChatbotV2Slice';
import { ChatV2, SetChat, TagItem } from 'Types/ChatBotV2Types';
import {
  ChatFeedback,
  DeleteConversationRequest, EditConversationRequest,
  ChatbotRequest,
  GetConversationsRequest, GetPromptsRequest,
  PromptCategoriesRequest, EditChatV2Request, ChatHistoryV2Request,
  DeleteChatRequest, GetPersonProfileRequest, TagSetupRequest,
} from 'Types/RequestBodies';
import {
  ChatBotResponse,
  ConversationsResponse, GetPromptsResponse, GlobalResponse, PromptCategoryResponse,
  RecentTagSetupResponse,
} from 'Types/ResponseBodies';
import { actions as helperActions } from 'Slice/HelperSlice';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';
import {
  call, delay, fork, put, takeEvery, takeLatest, takeLeading,
} from 'redux-saga/effects';
import {
  GlobalSearchResults, PersonLinkedinSearchResponse, SearchResultSection, SearchResultsRequest,
} from 'Types/GlobalSearchResultsTypes';
import { PersonDataResponse } from 'Types/PersonTypes';
import _ from 'lodash';
import { getResultArray, handleException, valueToObject } from 'Utils/UtilityFunctions';
import { Base64 } from 'js-base64';

export function* getConversations(dispatched: PayloadAction<GetConversationsRequest>) {
  const { payload } = dispatched;
  try {
    const response: ConversationsResponse = yield call(
      axios.post,
      API.GET_CONVERSATIONS,
      payload,
    );

    if (response?.status === true) {
      yield put(actions.setConversations({
        sections: response?.response[0].sections,
        offset: payload?.offset,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* deleteConversation(dispatched: PayloadAction<DeleteConversationRequest>) {
  const { payload } = dispatched;
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.DELETE_CONVERSATION,
      payload,
    );

    if (response?.status === true) {
      yield put(actions.setDeleteConversation(payload.channelId));
      yield put(helperActions.setNotification({
        message: 'Chat deleted successfully',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
        getContainerEle: 'chatContainer',
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPromptCategories(dispatched: PayloadAction<PromptCategoriesRequest>) {
  const { payload } = dispatched;
  try {
    const response: PromptCategoryResponse = yield call(
      axios.post,
      API.PROMPT_CATEGORIES,
      payload,
    );

    if (response?.status === true) {
      yield put(actions.setPromptCategories(response?.response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getPrompts(dispatched: PayloadAction<GetPromptsRequest>) {
  const { payload } = dispatched;
  try {
    const response: GetPromptsResponse = yield call(
      axios.post,
      API.PROMPTS,
      payload,
    );

    if (response?.status === true) {
      yield put(actions.setPrompts(response?.response[0].prompts));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* editConversation(dispatched: PayloadAction<EditConversationRequest>) {
  const { payload } = dispatched;
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.EDIT_CONVERSATION,
      payload,
    );

    if (response?.status === true) {
      yield put(actions.setEditConversation(payload));
      if (payload?.channelId) {
        yield put(actions.setConvoToLatest(payload?.channelId));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* chatbot(dispatched: PayloadAction<ChatbotRequest>) {
  const { payload } = dispatched;
  const { id, ...updatedPayload } = payload;
  try {
    const response: ChatBotResponse = yield call(
      axios.post,
      API.CHATBOT,
      updatedPayload,
    );
    const {
      taggedCompanies, taggedDocuments, taggedGroups, taggedPersons, prompt,
    } = updatedPayload;

    if (response?.response?.popupText && response?.response?.showPlanPopup) {
      const {
        popupText, showPlanPopup, callToAction, contactUsEmail,
      } = response.response;
      const data = {
        popupText,
        showPlanPopup,
        callToAction: callToAction || '',
        contactUsEmail: contactUsEmail || '',
      };
      yield put(actions.setChatbotPopup(data));
    } else {
      const chat = response.response.result as ChatV2[];
      const cArray = getResultArray(taggedCompanies ?? '', 'company');
      const dArray = getResultArray(taggedDocuments ?? '', 'document');
      const gArray = getResultArray(taggedGroups ?? '', 'group');
      const pArray = getResultArray(taggedPersons ?? '', 'person');
      const final = [...cArray, ...dArray, ...pArray, ...gArray].join(',');
      if (response?.status === true && chat[0]?.responseId) {
        const data = {
          chat,
          id,
          isNewChat: !payload?.channelId,
          channelId: response.response.channelId || payload?.channelId,
          channelHeading: Base64.decode(payload.prompt),
          image: payload.avatarImage,
          color: payload.avatarColor,
          source: payload.source,
        };
        yield put(actions.setChat(data as SetChat));
        if (payload?.channelId) {
          yield put(actions.setConvoToLatest(payload?.channelId));
        }
        yield put(helperActions.callFlurryEvent(
          valueToObject('xcgpt_generate_successful', 0, {
            tags_input: final,
            search_query: prompt,
          }),
        ));
      } else {
        yield put(helperActions.callFlurryEvent(
          valueToObject('xcgpt_generate_failure', 0, {
            tags_input: final,
            search_query: prompt,
          }),
        ));
        yield put(actions.setChatLoading(false));
        handleException();
      }
    }
  } catch (err) {
    yield put(actions.setChatLoading(false));
    handleException();
    console.log(err);
  }
}

export function* getChatHistory(dispatched: PayloadAction<ChatHistoryV2Request>) {
  const { payload } = dispatched;
  try {
    const response: ChatBotResponse = yield call(
      axios.post,
      API.CHATBOT_HISTORY,
      payload,
    );

    if (response?.status === true) {
      const chat = {
        chat: response?.response?.result as ChatV2[],
      };
      yield put(actions.setChat(chat));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* sendFeedback(dispatched: PayloadAction<ChatFeedback>) {
  const { payload } = dispatched;
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.CHAT_FEEDBACK,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.setFeedbackSubmitted(payload?.responseId));
      yield put(helperActions.setNotification({
        message: 'Feedback Recieved',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
        getContainerEle: 'chatContainer',
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* editChatResponse(dispatched: PayloadAction<EditChatV2Request>) {
  const { payload } = dispatched;
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.EDIT_CHAT,
      payload,
    );
    if (response?.status === true) {
      yield put(actions.updateChatResponse(payload));
    } else {
      yield put(actions.setEditChatLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* globalSearchResults(dispatched: PayloadAction<SearchResultsRequest>) {
  try {
    const refinedQuery = { ...dispatched.payload };
    const response: GlobalSearchResults = yield call(
      axios.post,
      API.GLOBAL_SEARCH_RESULTS,
      refinedQuery,
    );
    if (response?.status === true) {
      const results: SearchResultSection[] = response?.response?.searchResult;
      yield put(actions.globalSearchResultsLoaded(results));
    } else {
      yield put(actions.globalSearchResultsError());
    }
  } catch (err) {
    console.log(err);
  }
}

export function* deleteChat(dispatched: PayloadAction<DeleteChatRequest>) {
  const { payload } = dispatched;
  const { isStopGenerating, ...updatedPayload } = payload;
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.CLEAR_CHAT_HISTORY,
      updatedPayload,
    );
    if (response?.status === true) {
      yield put(actions.setDeleteChat(payload?.chatIds));
      if (!isStopGenerating) {
        yield put(helperActions.setNotification({
          message: 'Prompt deleted successfully',
          proTip: false,
          rightPlacement: false,
          groupId: 0,
          classnameFlag: true,
          getContainerEle: 'chatContainer',
        }));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* personLinkedinSearch(dispatched: PayloadAction<SearchResultsRequest>) {
  try {
    const response: PersonLinkedinSearchResponse = yield call(
      axios.post,
      API.GET_PERSON_EXTENDED_SEARCH,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.personLinkedinSearch(response?.response));
    } else {
      yield put(actions.globalSearchResultsError());
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getSearchResultsFlow(dispatched: PayloadAction<SearchResultsRequest>) {
  const { query } = dispatched.payload;
  const querySplit = query.split(':');
  const isDetailedSearch = querySplit.length > 1;
  let isCompanySearch = false;
  let isGroupSearch = false;
  let isDocumentSearch = false;
  if (isDetailedSearch) {
    isCompanySearch = querySplit[0].includes('company');
    isGroupSearch = querySplit[0].includes('group');
    isDocumentSearch = querySplit[0].includes('document');
  }
  yield fork(globalSearchResults, dispatched);
  yield delay(200);
  if (!isGroupSearch && !isCompanySearch && !isDocumentSearch) {
    yield fork(personLinkedinSearch, dispatched);
  }
}

export function* getPersonProfile(dispatched: PayloadAction<GetPersonProfileRequest>) {
  try {
    const { payload } = dispatched;
    const parameters = { ...payload };
    if ('source' in parameters) {
      _.unset(parameters, 'source');
    }
    if ('entityName' in parameters) {
      _.unset(parameters, 'entityName');
    }
    const response: PersonDataResponse = yield call(
      axios.post,
      API.GET_PERSON_PROFILE_DATA,
      parameters,
    );
    if (response.status === true) {
      yield delay(2000);
      const executiveId = 'personId' in response.response.result[1].details[0] ? response.response.result[1].details[0].personId : 0;
      const color = 'archeType' in response.response.result[1].details[0] ? response.response.result[1].details[0].archeType.color : '';
      const personName = 'personName' in response.response.result[1].details[0] ? response.response.result[1].details[0].personName : '';
      const personImage = 'personImage' in response.response.result[1].details[0] ? response.response.result[1].details[0].personImage : '';
      yield put(actions.setLivePersonTag({
        livesearchId: payload.personId,
        executiveId: 'personId' in response.response.result[1].details[0] ? response.response.result[1].details[0].personId : 0,
        color: 'archeType' in response.response.result[1].details[0] ? response.response.result[1].details[0].archeType.color : '',
      }));
      const taggedItem: TagItem = {
        type: 'person',
        id: executiveId,
        name: personName,
        image: personImage,
        color,
        personType: 'EXECUTIVE',
      };
      yield put(actions.changeOrderRecentTags([taggedItem]));
    } else {
      yield put(actions.setPersonCardLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* personSetup(dispatched: PayloadAction<TagSetupRequest>) {
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.PERSON_SETUP,
      dispatched?.payload,
    );
    if (response) {
      if (response.status) {
        yield delay(2000);
      }
      yield put(actions.setTagSetupLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* groupSetup(dispatched: PayloadAction<TagSetupRequest>) {
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.GROUP_SETUP,
      dispatched?.payload,
    );
    if (response) {
      if (response.status) {
        yield delay(2000);
      }
      yield put(actions.setTagSetupLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* companySetup(dispatched: PayloadAction<TagSetupRequest>) {
  try {
    const response: GlobalResponse = yield call(
      axios.post,
      API.COMPANY_SETUP,
      dispatched?.payload,
    );
    if (response) {
      if (response.status) {
        yield delay(2000);
      }
      yield put(actions.setTagSetupLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* recentTags() {
  try {
    const response: RecentTagSetupResponse = yield call(
      axios.post,
      API.RECENT_TAGS,
      {},
    );
    if (response.status) {
      yield put(actions.recentTags(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* ChatBotV2Saga() {
  yield takeEvery(actions.getConversations, getConversations);
  yield takeEvery(actions.deleteConversation, deleteConversation);
  yield takeLatest(actions.getPromptCategories, getPromptCategories);
  yield takeLatest(actions.getPrompts, getPrompts);
  yield takeEvery(actions.editConversation, editConversation);
  yield takeEvery(actions.chatbot, chatbot);
  yield takeLatest(actions.getChatHistory, getChatHistory);
  yield takeEvery(actions.sendFeedback, sendFeedback);
  yield takeEvery(actions.editChatResponse, editChatResponse);
  yield takeLatest(actions.globalSearchResults, getSearchResultsFlow);
  yield takeEvery(actions.deleteChat, deleteChat);
  yield takeLeading(actions.tagLiveSearchPerson, getPersonProfile);
  yield takeEvery(actions.personSetup, personSetup);
  yield takeEvery(actions.companySetup, companySetup);
  yield takeEvery(actions.groupSetup, groupSetup);
  yield takeEvery(actions.getRecentTags, recentTags);
}
