/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PayloadAction } from '@reduxjs/toolkit';
import {
  call, put, fork, delay, takeLatest,
} from 'redux-saga/effects';
// import { Company } from 'Types/CompaniesTypes';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { API } from '../Utils/Constants';
import axios from '../Utils/Axios';
import { actions } from '../Slice/GlobalSearchResultsSlice';
import {
  SearchResultsRequest,
  GlobalSearchResults,
  SearchResultSection,
  PersonLinkedinSearchResponse,
  CompanyLinkedinSearchResponse,
} from '../Types/GlobalSearchResultsTypes';

export function* globalSearchResults(dispatched: PayloadAction<SearchResultsRequest>) {
  const startTime = getCurrentTime();
  const host = window.location.href;
  try {
    const {
      query, page, screenName, groupId,
    } = dispatched.payload;
    const querySplit = query.split(':');
    const isArticleSearch = querySplit.length > 1 && querySplit[0].includes('article');
    const refinedQuery = { ...dispatched.payload };
    if (isArticleSearch) {
      refinedQuery.page = page;

      const [, searchTerm] = querySplit;
      refinedQuery.query = searchTerm;
    }
    if (groupId && groupId > 0) refinedQuery.groupId = groupId;
    const response: GlobalSearchResults = yield call(
      axios.post,
      API.GLOBAL_SEARCH_RESULTS,
      refinedQuery,
    );
    if (response?.status === true) {
      const results: SearchResultSection[] = response?.response?.searchResult;
      if (screenName === 'Onboarding') {
        yield put(actions.onboardingGlobalSearchResultsLoaded(results));
      } else {
        yield put(actions.globalSearchResultsLoaded(results));
      }
      yield put(flurryActions.callFlurryEvent(
        valueToObject(
          'searchPage_load',
          startTime,
          {
            search_query: query,
            screen_name: screenName,
            ...page && { page },
            host,
          },
        ),
      ));
    } else {
      yield put(actions.globalSearchResultsError(response?.message));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.globalSearchResultsError('Connection Issue'));
  }
}

export function* personLinkedinSearch(dispatched: PayloadAction<SearchResultsRequest>) {
  try {
    const response: PersonLinkedinSearchResponse = yield call(
      axios.post,
      API.GET_PERSON_EXTENDED_SEARCH,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.personLinkedinSearch(response?.response));
    } else {
      yield put(actions.globalSearchResultsError(response?.message));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.globalSearchResultsError('Connection Issue'));
  }
}

export function* companyLinkedinSearch(dispatched: PayloadAction<SearchResultsRequest>) {
  try {
    const response: CompanyLinkedinSearchResponse = yield call(
      axios.post,
      API.GET_COMPANY_EXTENDED_SEARCH,
      dispatched?.payload,
    );
    if (response?.status === true) {
      yield put(actions.companyLinkedinSearch(response?.response));
    } else {
      yield put(actions.globalSearchResultsError(response?.message));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.globalSearchResultsError('Connection Issue'));
  }
}

export function* globalSearchResultsFlow(dispatched: PayloadAction<SearchResultsRequest>) {
  const { query } = dispatched.payload;
  const querySplit = query.split(':');
  const isDetailedSearch = querySplit.length > 1;
  let isCompanySearch = false;
  let isArticleSearch = false;
  let isGroupSearch = false;
  if (isDetailedSearch) {
    isCompanySearch = querySplit[0].includes('company');
    isArticleSearch = querySplit[0].includes('article');
    isGroupSearch = querySplit[0].includes('group');
  }

  yield fork(globalSearchResults, dispatched);
  yield delay(2000);

  const isOnboardingCompanySearch = dispatched.payload.screenName === 'onboardingFollowCompany';
  const isOnboardingPeopleSearch = dispatched.payload.screenName === 'onboardingFollowPeople';

  if (!isCompanySearch && !isArticleSearch && !isGroupSearch && !isOnboardingCompanySearch) {
    yield fork(personLinkedinSearch, dispatched);
  } else {
    yield put(actions.searchPersonExtendedLoaded());
  }

  if (!isOnboardingPeopleSearch) {
    yield put(actions.searchCompaniesExtendedLoaded());
  }

  if (isGroupSearch) {
    yield put(actions.searchBuyerGroupExtendedLoaded());
  }
}

export function* useGlobalSearchResultsSaga() {
  yield takeLatest(actions.globalSearchResults.type, globalSearchResultsFlow);
}
