import LibraryIcon from 'Components/common/assets/svgIcons/LibraryIcon';
import { actions } from 'Slice/ChatbotV2Slice';
import {
  maxMobile,
  maxTablet,
  maxWidth,
  primaryColor, retina, secondaryFont, wMBold, wMLight,
} from 'Utils/utilsStyle';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledWrapper = styled.span`
  font-family: ${secondaryFont};
  font-weight: ${wMLight};
  font-size: 22px;
  line-height: 48px;
  letter-spacing: -1.264px;
  color: #667982;
  width: 455px;
  display: inline;
  text-align: center;
  align-self: center;  
  margin: 11% 0px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    margin: 8% 0px;
  }
  @media all and (min-height: ${maxTablet}) {
    margin: 11% 0px;
  }
  @media all and (max-width: ${maxTablet}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 190px);
  }
  @media all and (max-width: ${maxMobile}) {
    height: calc(100% - 0px);
  }
  .empty-inner-state {
    display: inline;
    align-self: center;  
    text-align: center;
  }
  @media all and (min-height: ${maxTablet}) {
    margin: 28% 0px;
  }
  @media all and (max-width: ${maxTablet}) {
    font-size: 14px;
    line-height: 20px;
    margin: 10% 0px;
    width: 320px;
  }
  .text-decor {
    color: ${primaryColor};
    border-bottom: 2px solid ${primaryColor};
    padding-bottom: 3px;
    font-weight: ${wMBold};
    cursor: pointer;
    svg {
      width: 32px;
      height: 22px;
      @media all and (max-width: ${maxTablet}) {
        width: 22px;
        height: 12px;
      }
    }
  }
`;

const EmptyStateHistory = function EmptyStateHistory() {
  const dispatch = useDispatch();
  const handleLibraryClick = () => {
    dispatch(actions.setShowLibrary(true));
    dispatch(actions.setShowExamplePrompts(false));
  };

  return (
    <StyledWrapper className="history-empty-state">
      <div className="empty-inner-state">
        Explore our vast
        {' '}
        <span
          className="text-decor"
          onClick={handleLibraryClick}
          onKeyPress={handleLibraryClick}
          tabIndex={0}
          role="button"
        >
          <LibraryIcon fillColor={primaryColor} />
          {' '}
          Library
        </span>
        {' '}
        of pre-built prompts or write your own prompt
      </div>
    </StyledWrapper>
  );
};

export default EmptyStateHistory;
