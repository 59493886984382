/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CertificationState } from 'Types/GlobalTypes';
import {
  CertificationData, CertificationPrompt, CertificationTask, Event, GetCertificationData,
  PDFResponse, SetIndexRequest, SetScoreRequest,
} from 'Types/CertificationTypes';
import _ from 'lodash';
import { CertificationActionPayload, EditNameRequest } from 'Types/RequestBodies';

export const initialState: CertificationState = {
  certificationData: {
    score: 0,
    earned_score: 0,
    percentage: 0,
    data: [],
    fullname: '',
    completed: false,
    certification_complete_date: '',
    persons: [],
    personId: 0,
    companyId: 0,
    companyName: 'your company',
    groupId: 0,
    personCount: 0,
    showTooltip: 0,
    personToaster: 0,
    companyToaster: 0,
    groupToaster: 0,
  },
  aiCertificationData: {} as CertificationData,
  activeTask: null,
  activeModule: null,
  loading: false,
  aiCertificationLoading: false,
  scrollToId: '',
  isScroll: false,
  scrollToPage: '',
  openDrawer: false,
  gilroyDrawer: false,
  popupData: {
    completed: false,
    type: '',
    points: 0,
    text: '',
    isAi: false,
  },
  nameLoading: false,
  certificateLoading: false,
  aiCertificateLoading: false,
  certificatePDFLink: '',
  certificateImgLink: '',
  aiCertificatePDFLink: '',
  aiCertificateImgLink: '',
  searchedPersonClicked: false,
  searchedCompanyClicked: false,
  pulseCompanyClicked: false,
  currentType: '',
  showPopup: false,
  highlight: false,
  isHighlighted: false,
  taskIndex: -1,
  moduleIndex: -1,
  aiTaskIndex: -1,
  aiModuleIndex: -1,
  certificationDone: false,
  toasterFlag: false,
  showAccordion: false,
  isCompletionPopupOpen: false,
  certificationPrompt: {
    eventType: '',
    prompt: '',
    isPasted: false,
    source: '',
  },
};

const CertificationSlice = createSlice({
  name: 'certification',
  initialState,
  reducers: {
    getCertificationData(state, action: PayloadAction<GetCertificationData>) {
      if (action.payload.type === 'ai_cert') {
        if (!state.aiCertificationData?.data?.length) {
          state.aiCertificationLoading = true;
        }
      } else if (!state.certificationData.data.length || state.certificationDone) {
        state.loading = true;
      }
    },
    setCertificationData(state, action: PayloadAction<CertificationData>) {
      if (!state.certificationData.data.length || state.certificationDone) {
        state.certificationData = action.payload;
      } else {
        const { payload } = action;
        state.certificationData.companyId = payload.companyId;
        state.certificationData.personId = payload.personId;
        state.certificationData.groupId = payload.groupId;
        state.certificationData.companyName = payload.companyName.length ? payload.companyName : 'your company';
      }
      state.loading = false;
    },
    setAiCertificationData(state, action: PayloadAction<CertificationData>) {
      // if (!state.aiCertificationData.data.length || state.certificationDone) {
      if (!state.aiCertificationData?.data?.length) {
        state.aiCertificationData = action.payload;
      } else {
        const { payload } = action;
        state.aiCertificationData.companyId = payload.companyId;
        state.aiCertificationData.personId = payload.personId;
        state.aiCertificationData.groupId = payload.groupId;
        state.aiCertificationData.companyName = payload.companyName.length ? payload.companyName : 'your company';
      }
      state.aiCertificationLoading = false;
    },
    setScrollToId(state, action: PayloadAction<string>) {
      state.scrollToId = action.payload;
    },
    setIndex(state, action:PayloadAction<SetIndexRequest>) {
      const { module, task } = action.payload;
      if (state.certificationData.completed) {
        state.taskIndex = -1;
        state.moduleIndex = -1;
      } else {
        let breakpoint = false;
        let l = task;
        let j = module;
        for (let i = 0; i < state.certificationData.data.length; i += 1, j += 1) {
          if (j === state.certificationData.data.length) {
            j = 0;
          }
          for (let k = 0; k < state.certificationData.data[j].dataList.length;
            k += 1, l += 1) {
            if (l === state.certificationData.data[j].dataList.length) {
              l = 0;
              break;
            }
            if (!state.certificationData.data[j].dataList[l].completed) {
              state.taskIndex = l;
              state.moduleIndex = j;
              breakpoint = true;
              break;
            }
          }
          if (breakpoint) {
            break;
          }
        }
      }
    },
    setScore(state, action: PayloadAction<SetScoreRequest>) {
      const {
        module, task, event,
      } = action.payload;
      const { score, percentage } = state.certificationData;
      let newPercent = percentage;
      const mTask = state.certificationData.data[module].dataList[task];
      const taskE = mTask.events[event];
      if (!mTask.completed && taskE.status === 0) {
        taskE.status = 1;
        mTask.events[event] = taskE;
      }
      if ((mTask.events).every((e:Event) => e.status === 1)) {
        mTask.completed = true;
        state.certificationData.earned_score += mTask.score;
        newPercent = (state.certificationData.earned_score / score) * 100;
        if (taskE.type === state.currentType) {
          state.showPopup = true;
        } else {
          state.showPopup = false;
        }
      }
      if (state.activeTask?.source === mTask.source
          && state.activeTask.events[event]?.type === taskE.type) {
        state.activeTask = mTask;
        state.activeModule = state.certificationData.data[module];
      }
      state.certificationData.percentage = newPercent;
      state.certificationData.data[module].dataList[task] = mTask;
      if (mTask.completed) {
        state.showAccordion = false;
      }
      const moduleCompleted = _.every(state.certificationData.data[module].dataList, 'completed');
      state.certificationData.data[module].completed = moduleCompleted;
      const certificateCompleted = _.every(state.certificationData.data, 'completed');
      if (certificateCompleted) {
        const date = new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
        state.certificationData.certification_complete_date = date;
        state.certificationData.completed = true;
        state.popupData.completed = true;
        state.popupData.text = 'Certification Completed';
        state.popupData.points = state.certificationData.score;
        state.popupData.type = 'Certificate';
        state.popupData.isAi = false;
        state.moduleIndex = -1;
        state.taskIndex = -1;
      } else if (moduleCompleted) {
        state.popupData.completed = true;
        state.popupData.text = state.certificationData.data[module].header;
        state.popupData.points = state.certificationData.data[module].score;
        state.popupData.type = 'Module';
        state.popupData.isAi = false;
      } else if (mTask.completed) {
        state.popupData.completed = true;
        state.popupData.text = mTask.header;
        state.popupData.points = mTask.score;
        state.popupData.type = 'Task';
        state.popupData.isAi = false;
      }
      if (mTask.completed && !certificateCompleted) {
        let taskIndex = task + 1;
        let moduleIndex = module;
        for (let j = 0; ; j += 1) {
          if (state.certificationData.data[moduleIndex]?.completed) {
            moduleIndex += 1;
            taskIndex = 0;
          } else if (moduleIndex >= state.certificationData.data.length) {
            moduleIndex = 0;
            taskIndex = 0;
          } else {
            for (let l = 0; ;l += 1) {
              if (state.certificationData.data[moduleIndex]?.dataList[taskIndex]?.completed) {
                taskIndex += 1;
              } else if (taskIndex >= state.certificationData.data[moduleIndex]?.dataList.length) {
                moduleIndex += 1;
                taskIndex = 0;
                break;
              }
              if (!state.certificationData.data[moduleIndex]?.dataList[taskIndex]?.completed) {
                state.taskIndex = taskIndex;
                state.moduleIndex = moduleIndex;
                return;
              }
            }
          }
        }
      }
    },
    setAiScore(state, action: PayloadAction<SetScoreRequest>) {
      const {
        module, task, event,
      } = action.payload;
      const { score, percentage } = state.aiCertificationData;
      let newPercent = percentage;
      const mTask = state.aiCertificationData.data[module].dataList[task];
      const taskE = mTask.events[event];
      if (!mTask.completed && taskE.status === 0) {
        taskE.status = 1;
        mTask.events[event] = taskE;
      }
      if ((mTask.events).every((e:Event) => e.status === 1)) {
        mTask.completed = true;
        state.aiCertificationData.earned_score += mTask.score;
        newPercent = (state.aiCertificationData.earned_score / score) * 100;
        if (taskE.type === state.currentType) {
          state.showPopup = true;
        } else {
          state.showPopup = false;
        }
      }
      if (state.activeTask?.source === mTask.source
          && state.activeTask.events[event]?.type === taskE.type) {
        state.activeTask = mTask;
        state.activeModule = state.aiCertificationData.data[module];
      }
      state.aiCertificationData.percentage = newPercent;
      state.aiCertificationData.data[module].dataList[task] = mTask;
      if (mTask.completed) {
        state.showAccordion = false;
      }
      const moduleCompleted = _.every(state.aiCertificationData.data[module].dataList, 'completed');
      state.aiCertificationData.data[module].completed = moduleCompleted;
      const certificateCompleted = _.every(state.aiCertificationData.data, 'completed');
      if (certificateCompleted) {
        const date = new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: 'numeric' });
        state.aiCertificationData.certification_complete_date = date;
        state.aiCertificationData.completed = true;
        state.popupData.completed = true;
        state.popupData.text = 'Certification Completed';
        state.popupData.points = state.aiCertificationData.score;
        state.popupData.type = 'Certificate';
        state.popupData.isAi = true;
        state.aiModuleIndex = -1;
        state.aiTaskIndex = -1;
      } else if (moduleCompleted) {
        state.popupData.completed = true;
        state.popupData.text = state.aiCertificationData.data[module].header;
        state.popupData.points = state.aiCertificationData.data[module].score;
        state.popupData.type = 'Module';
        state.popupData.isAi = true;
      } else if (mTask.completed) {
        state.popupData.completed = true;
        state.popupData.text = mTask.header;
        state.popupData.points = mTask.score;
        state.popupData.type = 'Task';
        state.popupData.isAi = true;
      }
      if (mTask.completed && !certificateCompleted) {
        let taskIndex = task + 1;
        let moduleIndex = module;
        for (let j = 0; ; j += 1) {
          if (state.aiCertificationData.data[moduleIndex]?.completed) {
            moduleIndex += 1;
            taskIndex = 0;
          } else if (moduleIndex >= state.aiCertificationData.data.length) {
            moduleIndex = 0;
            taskIndex = 0;
          } else {
            for (let l = 0; ;l += 1) {
              if (state.aiCertificationData.data[moduleIndex]?.dataList[taskIndex]?.completed) {
                taskIndex += 1;
              } else
              if (taskIndex >= state.aiCertificationData.data[moduleIndex]?.dataList.length) {
                moduleIndex += 1;
                taskIndex = 0;
                break;
              }
              if (!state.aiCertificationData.data[moduleIndex]?.dataList[taskIndex]?.completed) {
                state.aiTaskIndex = taskIndex;
                state.aiModuleIndex = moduleIndex;
                return;
              }
            }
          }
        }
      }
    },
    setIsScroll(state, action: PayloadAction<boolean>) {
      state.isScroll = action.payload;
    },
    setScrollToPage(state, action: PayloadAction<string>) {
      state.scrollToPage = action.payload;
    },
    setOpenDrawer(state, action: PayloadAction<boolean>) {
      state.openDrawer = action.payload;
    },
    setOpenGilroyDrawer(state, action: PayloadAction<boolean>) {
      state.gilroyDrawer = action.payload;
    },
    resetPopupData(state) {
      state.popupData = initialState.popupData;
    },
    editName(state, action: PayloadAction<EditNameRequest>) {
      if (action.payload.certificationName.length) {
        state.nameLoading = true;
      }
    },
    setName(state, action: PayloadAction<string>) {
      state.certificationData.fullname = action.payload;
      state.nameLoading = false;
    },
    getCertificatePDF(state, action: PayloadAction<GetCertificationData>) {
      if (action.payload?.type === 'ai_cert') {
        state.aiCertificateLoading = true;
      } else {
        state.certificateLoading = true;
      }
    },
    setCertificatePDF(state, action: PayloadAction<PDFResponse>) {
      const { payload } = action;
      state.certificatePDFLink = payload.link;
      state.certificateImgLink = payload.img_link;
      state.certificateLoading = false;
    },
    setAiCertificatePDF(state, action: PayloadAction<PDFResponse>) {
      const { payload } = action;
      state.aiCertificatePDFLink = payload.link;
      state.aiCertificateImgLink = payload.img_link;
      state.aiCertificateLoading = false;
    },
    setSearchPersonClicked(state, action: PayloadAction<boolean>) {
      state.searchedPersonClicked = action.payload;
    },
    setSearchCompanyClicked(state, action: PayloadAction<boolean>) {
      state.searchedCompanyClicked = action.payload;
    },
    setPulseCompanyClicked(state, action: PayloadAction<boolean>) {
      state.pulseCompanyClicked = action.payload;
    },
    setCurrentType(state, action: PayloadAction<string>) {
      state.currentType = action.payload;
    },
    resetCurrentType(state) {
      state.currentType = '';
    },
    setActiveTask(state, action: PayloadAction<CertificationTask>) {
      state.activeTask = action.payload;
      state.isHighlighted = false;
    },
    resetActiveTask(state) {
      state.activeTask = null;
    },
    setHighlight(state, action: PayloadAction<boolean>) {
      state.highlight = action.payload;
    },
    setIsHighlighted(state, action: PayloadAction<boolean>) {
      state.isHighlighted = action.payload;
    },
    setTooltip(state, action: PayloadAction<number>) {
      state.certificationData.showTooltip = action.payload;
    },
    certificationAction(state, action: PayloadAction<CertificationActionPayload>) {
    },
    aiCertificationAction(state, action: PayloadAction<CertificationActionPayload>) {
    },
    setCertificationDone(state, action: PayloadAction<boolean>) {
      state.certificationDone = action.payload;
    },
    setToasterFlag(state, action: PayloadAction<boolean>) {
      state.toasterFlag = action.payload;
    },
    setShowAccordion(state, action: PayloadAction<boolean>) {
      state.showAccordion = action.payload;
    },
    setModuleIndex(state, action: PayloadAction<string>) {
      state.moduleIndex = parseInt(action.payload, 10);
    },
    setTaskIndex(state, action: PayloadAction<string>) {
      state.moduleIndex = parseInt(action.payload, 10);
    },
    settTaskIndex(state, action: PayloadAction<string>) {
      state.taskIndex = parseInt(action.payload, 10);
    },
    setAiModuleIndex(state, action: PayloadAction<string>) {
      state.aiModuleIndex = parseInt(action.payload, 10);
    },
    setAiTaskIndex(state, action: PayloadAction<string>) {
      state.aiTaskIndex = parseInt(action.payload, 10);
    },
    setCertificationPrompt(state, action: PayloadAction<CertificationPrompt>) {
      state.certificationPrompt = action.payload;
    },
    setIsCompletionPopupOpen(state, action: PayloadAction<boolean>) {
      state.isCompletionPopupOpen = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = CertificationSlice;
