import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import styled from 'styled-components';
import HTMLReactParser from 'html-react-parser';
import { LoadingOutlined } from '@ant-design/icons';
import { IsMobile } from 'Utils/UtilityFunctions';
import classNames from 'classnames';
import Empty from '../assets/svgIcons/Empty';
// import CrossCircle from '../assets/svgIcons/CrossCircle';

const Wrapper = styled.div`
`;

type Props = {
  children?: object,
  title?: string,
  description?: string,
  email?: string,
  width?: string,
  onClickNext?: () => void,
  onClickCancel?: () => void,
  nextText?: string,
  cancelText?: string,
  closePopup?: boolean,
  nextLoading?: boolean,
  borderedButtons?: boolean,
  isChatbotPopup?: boolean,
  wrapperClass?: string,
} & typeof defaultProps;

const defaultProps = {
  children: <div />,
  title: '',
  width: '530px',
  description: '',
  email: '',
  onClickNext() { },
  onClickCancel() { },
  nextText: '',
  cancelText: '',
  closePopup: false,
  nextLoading: false,
  borderedButtons: false,
  isChatbotPopup: false,
  wrapperClass: '',
};

const ModalWidget = function ModalWidget(props: Props) {
  const {
    title, description, width, isChatbotPopup,
    nextText, cancelText, closePopup, onClickCancel, wrapperClass,
    onClickNext, children, nextLoading, borderedButtons, email,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(true);
  const isMobile = IsMobile();
  const isShowModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <Wrapper>
      <span onClick={isShowModal} onKeyPress={isShowModal} role="button" tabIndex={0}>
        {/* &nbsp;Model&nbsp; */}
      </span>
      <Modal
        visible={isModalVisible}
        centered
        closeIcon={<Empty />}
        width={isMobile ? '370px' : width}
        getContainer={isChatbotPopup ? () => document.querySelector('.container-inner-area') || document.body : ''}
        wrapClassName={classNames(`modal-c-widget ${wrapperClass}`, { gilroyV3Popup: isChatbotPopup })}
      >
        <h6 className="title">{title}</h6>
        <p className="description">{description && HTMLReactParser(description)}</p>
        {email ? (
          <a
            className="mail_link"
            href={`mailto:${email}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: '20px' }}
          >
            {email}
          </a>
        ) : null}
        {children}
        <div className="ant-modal-footer custom-footer">
          {
            nextText
            && (
              <button
                className={`ant-btn ${borderedButtons ? 'borderedButton' : ''} ant-btn-primary`}
                type="button"
                aria-label={nextText}
                onClick={onClickNext}
                disabled={nextLoading}
              >
                {nextLoading
                  ? (
                    <Spin indicator={
                      (
                        <LoadingOutlined
                          style={{
                            fontSize: 18,
                            color: '#fff',
                          }}
                          spin
                        />
                      )
                    }
                    />
                  ) : null}
                {nextText}
              </button>
            )
          }
          {
            cancelText
            && (
              <button
                className={`ant-btn ${borderedButtons ? 'borderedButton' : ''} ant-btn-default`}
                type="button"
                aria-label={cancelText}
                onClick={onClickCancel}
              >
                {cancelText}
              </button>
            )
          }
          {
            closePopup
            && (
              <button
                className={`ant-btn ${borderedButtons ? 'borderedButton' : ''} ant-btn-default`}
                type="button"
                aria-label="Close"
                onClick={isShowModal}
              >
                Close
              </button>
            )
          }
        </div>
      </Modal>
    </Wrapper>
  );
};

ModalWidget.defaultProps = defaultProps;
export default ModalWidget;
