import styled from 'styled-components';

type Props = {
  color?: string,
  onClick: () => void,
} & typeof defaultProps;

const defaultProps = {
  color: '#BBB8B1',
};
const SVGWrapper = styled.svg`
cursor: pointer;
&:hover {
  path {
    fill: #616161;
  }
}
`;
const SliderBackwardIcon = function SliderBackwardIcon(props: Props) {
  const { color, onClick } = props;
  return (
    <SVGWrapper xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none" className="slider-backward-icon" onClick={onClick}>
      <path d="M0.00152588 10.9329C0.0158501 10.4919 0.188581 10.0716 0.486896 9.7519L8.95878 0.75371C9.63504 0.0362244 10.7537 0.0135689 11.4574 0.703104C12.161 1.39264 12.1833 2.53326 11.507 3.25074L4.21542 11.0004L11.507 18.7501C12.1833 19.4676 12.161 20.6082 11.4574 21.2977C10.7537 21.9873 9.63504 21.9646 8.95878 21.2471L0.486896 12.2489C0.156808 11.8955 -0.0183659 11.4206 0.00152588 10.9329Z" fill={color} />
    </SVGWrapper>
  );
};
SliderBackwardIcon.defaultProps = defaultProps;
export default SliderBackwardIcon;
