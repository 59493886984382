/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { PersonProps } from 'Components/common/components/PeopleCardLarge';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { actions as groupActions } from 'Slice/GroupsSlice';
import { GroupsProps } from './GroupsDisplayWidget';

type Props = {
  children: JSX.Element
  newGroup?: boolean
  groupTitle?: string
  addPersonGroup?: (a: GroupsProps, b: PersonProps) => void
  createNewGroup?: (a: PersonProps) => void
  addReasonPopup?: (a: PersonProps, b: string) => void
} & typeof defaultProps;
const defaultProps = {
  newGroup: false,
  groupTitle: '',
  addPersonGroup(a: GroupsProps, b: PersonProps) { },
  createNewGroup(a: PersonProps) { },
  addReasonPopup(a: PersonProps, b: string) { },
};
const DropWrapper = function DropWrapper(props: Props) {
  const {
    addPersonGroup, createNewGroup, addReasonPopup, children, newGroup, groupTitle,
  } = props;
  const dispatch = useDispatch();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'PEOPLE',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop(item) {
      dispatch(groupActions.setDropComplete(true));
      if (!newGroup) {
        addPersonGroup(children.props as GroupsProps, item as PersonProps);
      } else {
        createNewGroup(item as PersonProps);
      }
      if (groupTitle) {
        addReasonPopup(item as PersonProps, groupTitle);
      }
    },
  }));

  return (
    <div ref={drop} className={classNames('drop-person-area', { activeDrop: isOver })}>
      {children || null}
    </div>
  );
};
DropWrapper.defaultProps = defaultProps;
export default DropWrapper;
