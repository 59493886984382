import React from 'react';

const GilroyBackSvg = function GilroyBackSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
      <g clipPath="url(#clip0_780_9350)">
        <path d="M2.91496 19.8707L2.91468 19.8704C2.54637 19.3962 1.86344 19.3104 1.38931 19.6787L-2.9523 23.0513C-3.42643 23.4196 -3.51222 24.1025 -3.14392 24.5767L-3.14363 24.577C-2.77533 25.0512 -2.09239 25.137 -1.61826 24.7687L2.72334 21.3961C3.19748 21.0278 3.28327 20.3449 2.91496 19.8707Z" fill="white" stroke="#26CF0A" strokeWidth="1.18175" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M2.83942 21.7399C3.89292 21.7399 4.74696 20.8858 4.74696 19.8323C4.74696 18.7788 3.89292 17.9248 2.83942 17.9248C1.78592 17.9248 0.931885 18.7788 0.931885 19.8323C0.931885 20.8858 1.78592 21.7399 2.83942 21.7399Z" fill="white" stroke="#26CF0A" strokeWidth="1.18175" strokeMiterlimit="10" />
        <path d="M15.6559 2.42389H7.29521C5.9196 2.42389 4.80444 3.53904 4.80444 4.91466V11.9225C4.80444 13.2981 5.9196 14.4133 7.29521 14.4133H15.6559C17.0315 14.4133 18.1467 13.2981 18.1467 11.9225V4.91466C18.1467 3.53904 17.0315 2.42389 15.6559 2.42389Z" fill="white" stroke="#26CF0A" strokeWidth="1.05736" strokeMiterlimit="10" />
        <path d="M11.4755 -3.05176e-05V2.17648" stroke="#26CF0A" strokeWidth="1.3217" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M4.27623 10.7278H3.45129C2.86205 10.7278 2.38452 10.2502 2.38452 9.66098V7.17717C2.38452 6.58793 2.86205 6.1104 3.45129 6.1104H4.27623" fill="white" />
        <path d="M4.27623 10.7278H3.45129C2.86205 10.7278 2.38452 10.2502 2.38452 9.66098V7.17717C2.38452 6.58793 2.86205 6.1104 3.45129 6.1104H4.27623" stroke="#26CF0A" strokeWidth="1.05736" strokeMiterlimit="10" />
        <path d="M18.6753 10.7278H19.5002C20.0895 10.7278 20.567 10.2502 20.567 9.66098V7.17717C20.567 6.58793 20.0895 6.1104 19.5002 6.1104H18.6753" fill="white" />
        <path d="M18.6753 10.7278H19.5002C20.0895 10.7278 20.567 10.2502 20.567 9.66098V7.17717C20.567 6.58793 20.0895 6.1104 19.5002 6.1104H18.6753" stroke="#26CF0A" strokeWidth="1.05736" strokeMiterlimit="10" />
        <path d="M15.6559 2.42389H7.29521C5.9196 2.42389 4.80444 3.53904 4.80444 4.91466V11.9225C4.80444 13.2981 5.9196 14.4133 7.29521 14.4133H15.6559C17.0315 14.4133 18.1467 13.2981 18.1467 11.9225V4.91466C18.1467 3.53904 17.0315 2.42389 15.6559 2.42389Z" fill="white" stroke="#26CF0A" strokeWidth="1.05736" strokeMiterlimit="10" />
        <path d="M11.4755 -3.05176e-05V2.17648" stroke="#26CF0A" strokeWidth="1.3217" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M4.27623 10.7278H3.45129C2.86205 10.7278 2.38452 10.2502 2.38452 9.66098V7.17717C2.38452 6.58793 2.86205 6.1104 3.45129 6.1104H4.27623" fill="white" />
        <path d="M4.27623 10.7278H3.45129C2.86205 10.7278 2.38452 10.2502 2.38452 9.66098V7.17717C2.38452 6.58793 2.86205 6.1104 3.45129 6.1104H4.27623" stroke="#26CF0A" strokeWidth="1.05736" strokeMiterlimit="10" />
        <path d="M18.6753 10.7278H19.5002C20.0895 10.7278 20.567 10.2502 20.567 9.66098V7.17717C20.567 6.58793 20.0895 6.1104 19.5002 6.1104H18.6753" fill="white" />
        <path d="M18.6753 10.7278H19.5002C20.0895 10.7278 20.567 10.2502 20.567 9.66098V7.17717C20.567 6.58793 20.0895 6.1104 19.5002 6.1104H18.6753" stroke="#26CF0A" strokeWidth="1.05736" strokeMiterlimit="10" />
        <path d="M14.2335 15.5064H8.71758C8.37129 15.5064 8.09058 15.7871 8.09058 16.1334C8.09058 16.4797 8.37129 16.7604 8.71758 16.7604H14.2335C14.5798 16.7604 14.8605 16.4797 14.8605 16.1334C14.8605 15.7871 14.5798 15.5064 14.2335 15.5064Z" fill="#26CF0A" />
        <path d="M25.9869 24.5771L25.9872 24.5768C26.3555 24.1026 26.2697 23.4197 25.7956 23.0514L21.454 19.6788C20.9798 19.3105 20.2969 19.3963 19.9286 19.8705L19.9283 19.8708C19.56 20.3449 19.6458 21.0279 20.1199 21.3962L24.4615 24.7687C24.9357 25.137 25.6186 25.0513 25.9869 24.5771Z" fill="white" stroke="#26CF0A" strokeWidth="1.18175" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M22.7833 30.499L22.7837 30.4992C23.3103 30.7875 23.9709 30.5944 24.2592 30.0677L26.8993 25.2455C27.1876 24.7189 26.9944 24.0583 26.4678 23.77L26.4674 23.7698C25.9408 23.4814 25.2802 23.6746 24.9918 24.2012L22.3518 29.0234C22.0634 29.5501 22.2566 30.2107 22.7833 30.499Z" fill="white" stroke="#26CF0A" strokeWidth="1.18175" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M20.0043 21.7399C21.0578 21.7399 21.9119 20.8858 21.9119 19.8323C21.9119 18.7788 21.0578 17.9248 20.0043 17.9248C18.9508 17.9248 18.0968 18.7788 18.0968 19.8323C18.0968 20.8858 18.9508 21.7399 20.0043 21.7399Z" fill="white" stroke="#26CF0A" strokeWidth="1.18175" strokeMiterlimit="10" />
        <path d="M3.9568 17.8444H18.8915C19.1682 17.8444 19.3927 18.0689 19.3927 18.3456V27.4273C19.3927 28.9635 18.1456 30.2111 16.6089 30.2111H6.23935C4.70317 30.2111 3.45557 28.964 3.45557 27.4273V18.3456C3.45557 18.0689 3.68012 17.8444 3.9568 17.8444Z" fill="white" stroke="#26CF0A" strokeWidth="1.18175" strokeMiterlimit="10" />
        <path d="M9.66846 9.92319C10.7663 10.4228 11.8511 10.4678 12.9506 10.1098C12.2194 11.7273 10.5482 12.0244 9.66846 9.92319Z" fill="#26CF0A" />
        <path d="M7.71215 7.14713C7.70887 6.84555 7.71215 6.53539 7.82427 6.2555C7.93598 5.9752 8.18067 5.72845 8.48102 5.70145C8.7691 5.67567 9.04408 5.85735 9.21062 6.09386C9.38862 6.34716 9.50115 6.68392 9.5437 6.98877" stroke="#26CF0A" strokeWidth="0.663714" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M15.0509 7.12562C15.0542 6.82404 15.0509 6.51387 14.9388 6.23398C14.8267 5.95409 14.5824 5.70694 14.282 5.67993C13.994 5.65415 13.719 5.83583 13.5524 6.07235C13.3744 6.32564 13.2619 6.66241 13.2194 6.96726" stroke="#26CF0A" strokeWidth="0.663714" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M-2.97416 22.909L-2.97436 22.9094C-3.22913 23.453 -2.99496 24.1003 -2.45132 24.355L2.52672 26.688C3.07036 26.9428 3.71761 26.7086 3.97238 26.165L3.97258 26.1645C4.22735 25.6209 3.99318 24.9737 3.44954 24.7189L-1.52851 22.3859C-2.07215 22.1312 -2.71939 22.3653 -2.97416 22.909Z" fill="white" stroke="#26CF0A" strokeWidth="1.18175" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M-2.12201 25.2391C-1.0685 25.2391 -0.21447 24.3851 -0.21447 23.3315C-0.21447 22.278 -1.0685 21.424 -2.12201 21.424C-3.17551 21.424 -4.02954 22.278 -4.02954 23.3315C-4.02954 24.3851 -3.17551 25.2391 -2.12201 25.2391Z" fill="white" stroke="#26CF0A" strokeWidth="1.18175" strokeMiterlimit="10" />
      </g>
      <defs>
        <clipPath id="clip0_780_9350">
          <rect width="22" height="23" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default GilroyBackSvg;
