/* eslint-disable no-nested-ternary */
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import { isExpandFlag } from 'Selector/UISelector';
import { IsMobileM } from 'Utils/UtilityFunctions';
import {
  maxTablet, maxWidth, maxWidth1300, retina,
} from 'Utils/utilsStyle';
import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
.row-skelton {
  margin: 0px -30px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      margin: 0px -25px;
    }
    @media all and (max-width: ${maxWidth1300}) {
      margin: 0px -15px;
    }
  .col-skeleton {
    .skeleton-box {
      margin: 0px 30px 10px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        margin: 0px 25px 10px;
        height: 108px !important;
      }
      @media all and (max-width: ${maxWidth1300}) {
        margin: 0px 15px 10px;
      }
       @media all and (max-width: ${maxTablet}) {
        margin: 10px 15px 0px;
        height: 97px !important;
        min-height: 97px;
        min-width: calc(100% - 30px);
        width: calc(100% - 30px);
      }
    }
  }
}
`;

const LibraryCategoryCmpSkeleton = function LibraryCategoryCmpSkeleton() {
  const isGilroyV3 = !window.location.pathname.includes('feed');
  const isMob = IsMobileM() || isGilroyV3;
  const isExpand = useSelector(isExpandFlag) && !isGilroyV3;
  return (
    <Wrapper className="library-category-skeleton">
      <Row className="row-skelton">
        {[1, 2, 3, 4, 5, 6, 7, 8].slice(0, isMob ? 4 : isExpand ? 8 : 6).map(() => (
          <Col span={isMob ? 12 : isExpand ? 6 : 8} className="col-skeleton">
            <SkeletonCustom
              width="calc(100% - 60px)"
              height="115px"
              heightSm="115px"
            />
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};

export default LibraryCategoryCmpSkeleton;
