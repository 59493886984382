import React from 'react';
import styled from 'styled-components';
import { primaryColor } from 'Utils/utilsStyle';

const SVG = styled.svg`
&:hover {
  path {
    fill: ${primaryColor};
    stroke: ${primaryColor};
  }
}
`;
type Props = {
  className: string,
  onClick?: () => void,
} & typeof defaultProps;
const defaultProps = {
  onClick: () => { },
};

const PlusIconSvg = function PlusIconSvg(props: Props) {
  const { onClick, className } = props;
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" onClick={onClick} className={className}>
      <path d="M6.63136 14.7402V14.9246H6.81572H7.98234H8.16669V14.7402V8.54705H13.958H14.1424V8.36269V7.27384V7.08949H13.958H8.16669V1V0.815645H7.98234H6.81572H6.63136V1V7.08949H1.02148H0.837129V7.27384V8.36269V8.54705H1.02148H6.63136V14.7402Z" fill="#001760" stroke="#001760" strokeWidth="0.368711" />
    </SVG>
  );
};
PlusIconSvg.defaultProps = defaultProps;
export default PlusIconSvg;
