import React from 'react';
import ModalWidget from 'Components/common/modal/ModalWidget';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions as personProfileActions } from 'Slice/PersonProfileSlice';
import { actions as companyProfileActions } from 'Slice/CompaniesSlice';
import { actions as onboardingActions } from 'Slice/OnboardingSlice';
import { actions as SRIActions } from 'Slice/SRISlice';
import { actions as ChatbotV2Actions } from 'Slice/ChatbotV2Slice';
import { personProfileName } from 'Selector/PersonProfileSelector';
import {
  selectCompanyProfile,
} from 'Selector/CompaniesSelector';
// import HTMLReactParser from 'html-react-parser';

type Props = {
  title: string;
  description: string;
  contactUs: string;
  type: number;
  isDownloadPopup: boolean;
  callToAction: string;
  isCompany?: boolean;
  personId?: number;
  companyId?: number;
  ispdf?: number;
  loading: boolean;
  isAddCompany?: boolean;
  isOnboarding?: boolean;
  isSRICompany?: boolean;
  isChatbotPopup?: boolean;
  isSAP?: boolean;
};

type PopupTypes = {
  [key: number]: JSX.Element;
};

const Wrapper = styled.div`
  .person-popup {

  }
`;

const defaultProps = {
  companyId: 0,
  personId: 0,
  isCompany: false,
  ispdf: 0,
  isAddCompany: false,
  isOnboarding: false,
  isSRICompany: false,
  isChatbotPopup: false,
  isSAP: false,
  // loading: false,
};

const ProfilePopups: React.FC<Props> = function ProfilePopups(props) {
  const {
    title, description, contactUs,
    type, isDownloadPopup, callToAction,
    personId, companyId, isCompany, ispdf,
    loading, isAddCompany, isOnboarding, isSRICompany,
    isChatbotPopup, isSAP,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const personName = useSelector(personProfileName);
  const companyProfile = useSelector(selectCompanyProfile);
  const defineClass = isCompany ? 'company-wrapper' : 'other-wrapper';
  const onClickContactUs = () => {
    const linkO = document.createElement('a');
    linkO.href = `mailto: ${contactUs}`;
    linkO.dispatchEvent(new MouseEvent('click'));
  };

  const goBackToFeed = () => {
    if (isChatbotPopup) {
      dispatch(ChatbotV2Actions.resetChatbotPopup());
    } else if (isAddCompany) {
      dispatch(companyProfileActions.resetAddCompanyPopup());
    } else if (isOnboarding) {
      dispatch(onboardingActions.resetAddCompanyPopup());
    } else if (isSRICompany) {
      dispatch(SRIActions.resetSRICompanyPopup());
    } else if (isCompany) {
      if (isDownloadPopup) {
        dispatch(companyProfileActions.setPDFProfile(null));
        dispatch(companyProfileActions.setPPTProfile(null));
        dispatch(companyProfileActions.resetCompanyProfilePopup());
      } else {
        dispatch(companyProfileActions.resetCompanyProfilePopup());
        navigate(-1);
      }
    } else if (isDownloadPopup) {
      const data = {
        isDownloadPopup: false,
        popupHeading: '',
        popupHtml: '',
        popupText: '',
        showPlanPopup: 0,
        allowFullView: false,
        contactusEmail: '',
      };
      dispatch(personProfileActions.setPopupValues(data));
    } else if (isSAP) {
      dispatch(companyProfileActions.setShowLimitPopupSAP(false));
    } else {
      if (!personId) navigate(-1);
      dispatch(personProfileActions.resetPersonPopups());
      // navigate('/feed/all/', { replace: true });
    }
  };

  // const goBackCompany = () => {
  //   navigate(-1);
  // }

  const closeAccessRequiredPopup = () => {
    if (isCompany) {
      dispatch(companyProfileActions.resetCompanyProfilePopup());
      dispatch(companyProfileActions.setPDFProfile(null));
      dispatch(companyProfileActions.setPPTProfile(null));
    } else {
      dispatch(personProfileActions.resetPersonPopups());
    }
  };

  const requestDownloadPopup = () => {
    if (isCompany) {
      if (companyId) {
        if (ispdf) {
          const data = {
            companyId,
            ispdf,
            downloadRequest: 'true',
            company_name: companyProfile?.companyName,
          };
          dispatch(companyProfileActions.getPDFProfile(data));
        } else {
          const data = {
            companyId,
            ispdf,
            downloadRequest: 'true',
            company_name: companyProfile?.companyName,
          };
          dispatch(companyProfileActions.getPPTProfile(data));
        }
      }
    } else if (personId) {
      const data = {
        personId,
        downloadRequest: 'true',
        person_name: personName,
      };
      dispatch(personProfileActions.getPersonProfileDownload(data));
    }
  };

  const scheduleDemo = () => {
    const linkO = document.createElement('a');
    linkO.href = `${callToAction}`;
    linkO.target = '_blank';
    linkO.dispatchEvent(new MouseEvent('click'));
  };

  const emailsList = () => {
    const len = contactUs.split(',').length;
    return len > 1
      ? (
        <>
          {contactUs.split(',').map((email, index) => (
            index === len - 1
              ? (
                <a href={`mailto:${email}`} rel="noreferrer">{email}</a>
              ) : (
                <>
                  <a href={`mailto:${email}`} rel="noreferrer">{email}</a>
                  ,
                  <br />
                </>
              )
          ))}
        </>
      ) : <a href={`mailto:${contactUs}`} rel="noreferrer">{contactUs}</a>;
  };

  const makeBold = (item: string, keyword: string) => {
    const re = new RegExp(keyword, 'g');
    return (
      item.replace(re, `<b>${keyword}</b>`)
    );
  };

  const Popups: PopupTypes = {
    1:
      (
        <ModalWidget
          nextText=""
          title={title}
          description={description}
          onClickNext={onClickContactUs}
          onClickCancel={closeAccessRequiredPopup}
          closePopup={false}
          width="530px"
          cancelText="Cancel"
          nextLoading={false}
          wrapperClass={defineClass}
        />
      ),
    2:
      (
        <ModalWidget
          nextText="Contact us"
          title=""
          description=""
          onClickNext={onClickContactUs}
          onClickCancel={goBackToFeed}
          closePopup={false}
          width="530px"
          cancelText="Cancel"
          nextLoading={false}
          wrapperClass={defineClass}
        />
      ),
    3:
      (
        <ModalWidget
          nextText="Contact us"
          title={title}
          description={description}
          onClickNext={onClickContactUs}
          onClickCancel={goBackToFeed}
          closePopup={false}
          width="530px"
          cancelText="Cancel"
          nextLoading={false}
          wrapperClass={defineClass}
          isChatbotPopup={isChatbotPopup}
        >
          {emailsList()}
        </ModalWidget>
      ),
    4:
      (
        <ModalWidget
          nextText="Request Download"
          title={title}
          description={makeBold(description, "'Request Download'")}
          onClickNext={requestDownloadPopup}
          onClickCancel={goBackToFeed}
          closePopup={false}
          width="600px"
          cancelText=""
          nextLoading={loading}
          wrapperClass={defineClass}
        // cancelText="Cancel"
        />
      ),
    5:
      (
        <ModalWidget
          nextText="Contact us"
          title={title}
          description={description}
          onClickNext={onClickContactUs}
          onClickCancel={goBackToFeed}
          closePopup={false}
          width="530px"
          cancelText="Cancel"
          nextLoading={false}
          wrapperClass={defineClass}
        >
          {emailsList()}
        </ModalWidget>
      ),
    // access required for generating content
    6:
      (
        <ModalWidget
          nextText="Contact us"
          title={title}
          description={description}
          onClickNext={onClickContactUs}
          onClickCancel={closeAccessRequiredPopup}
          closePopup={false}
          width="530px"
          cancelText="Cancel"
          nextLoading={false}
          wrapperClass={defineClass}
        />
      ),
    // show a schedule demo popup
    8:
      (
        <ModalWidget
          nextText="Schedule Demo"
          title={title}
          description={description}
          email={contactUs}
          onClickNext={scheduleDemo}
          onClickCancel={goBackToFeed}
          closePopup={false}
          width="530px"
          cancelText="Cancel"
          nextLoading={false}
          wrapperClass={defineClass}
        />
      ),
  };

  return (
    <Wrapper>
      {Popups[type]}
    </Wrapper>
  );
};

ProfilePopups.defaultProps = defaultProps;

export default ProfilePopups;
