import { primaryColor } from 'Utils/utilsStyle';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onClick?: () => void,
  fllColor?: string,
} & typeof defaultProps;

const defaultProps = {
  onClick: () => { },
  fllColor: '#1EC252',
};
const SVG = styled.svg`
  &:hover {
    path {
      fill: ${primaryColor};
    }
  }
`;

const Edit = function Edit({ onClick, fllColor }: Props) {
  return (
    <SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="edit-icon-svg" onClick={onClick}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.76334 17.0144L1.52014 17.1392H1.47854C1.08334 17.1392 0.708944 16.9728 0.438544 16.7024C0.147344 16.432 -0.0190558 16.0368 0.00174418 15.6208L0.126544 11.3776C0.147344 11.0032 0.292944 10.6496 0.563344 10.3792L10.4849 0.4368C11.0465 -0.1456 11.9825 -0.1456 12.5649 0.4368L16.6833 4.576C16.9745 4.8464 17.1201 5.2208 17.1201 5.616C17.1201 6.0112 16.9537 6.3856 16.6833 6.656L6.76174 16.5776C6.49134 16.848 6.13774 16.9936 5.76334 17.0144ZM15.3729 5.5952L11.5249 1.7472L1.79054 11.5024L1.72814 13.4368L3.70414 15.4128L5.63854 15.3504L15.3729 5.5952Z" fill={fllColor} />
      <path d="M4.76498 14.0192C4.93138 14.1856 5.13938 14.2688 5.34738 14.2688C5.55538 14.2688 5.76338 14.1856 5.92978 14.0192L13.501 6.44802C13.8338 6.11522 13.8338 5.59522 13.501 5.26242C13.1682 4.92962 12.6482 4.92962 12.3154 5.26242L4.76498 12.8544C4.43218 13.1664 4.43218 13.7072 4.76498 14.0192Z" fill={fllColor} />
      <path d="M3.70407 12.6256C3.91207 12.6256 4.12007 12.5424 4.28647 12.376L11.8577 4.80483C12.1905 4.47203 12.1905 3.95203 11.8577 3.61923C11.5249 3.28643 11.0049 3.28643 10.6721 3.61923L3.12167 11.2112C2.78887 11.544 2.78887 12.064 3.12167 12.3968C3.28807 12.5424 3.49607 12.6256 3.70407 12.6256Z" fill={fllColor} />
    </SVG>
  );
};
Edit.defaultProps = defaultProps;
export default Edit;
