import PeopleWidgetWithOutHolo from 'Components/common/components/PeopleWidgetWithOutHolo';
import PersonSkeleton from 'Components/common/skeleton/PersonSkeleton';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import { TagItem } from 'Types/ChatBotV2Types';
import { Executive } from 'Types/PersonTypes';
import { backgroundColorParsing } from 'Utils/UtilityFunctions';
import {
  extendedSearchLoader,
  greyColor,
  maxMobile,
  maxTablet,
  maxWidth,
  midMobile,
  midWidthApp,
  minTablet,
  primaryColor,
  primaryFont,
  primaryHeadingColor, retina, scrollbar, secondaryFont, wLight, wMBold, wMedium,
} from 'Utils/utilsStyle';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const PeopleWrapper = styled.div`
  &.hide{
    display: none;
  }
  margin-top: 5px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  ${scrollbar};
  ::-webkit-scrollbar { width: 6px; } 
  ::-webkit-scrollbar-track { background-color: transparent; }
  @media all and (max-width: ${maxTablet}) {
    ::-webkit-scrollbar { width: 3px; } 
  } 
  .grid-for-result {
    &.gridForV3 {
      .grid-col {
        flex: 0 0 25%;
        max-width: 25%;
        @media all and (max-width: ${maxTablet}) {
          flex: 0 0 33.33%;
          max-width: 33.33%;
          padding-left: 2px !important;
          padding-right: 2px !important;
        }
        @media all and (max-width: ${maxMobile}) {
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
      }
    }
    margin: 0px !important; 
    width: 100%;
    .grid-col {
      @media all and (max-width: 1750px) {
        flex: 0 0 14.28%;
        max-width: 14.28%;
      }
      @media all and (max-width: 1600px) {
        flex: 0 0 16.66%;
        max-width: 16.66%;
      }
      @media all and (max-width: 1400px) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      @media all and (max-width: ${midWidthApp}) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media all and (max-width: ${maxTablet}) {
        flex: 0 0 16.66%;
        max-width: 16.66%;
      }
      @media all and (max-width: ${minTablet}) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      @media all and (max-width: ${maxMobile}) {
        padding-left: 4px !important;
        padding-right: 2px !important;
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media all and (max-width: ${midMobile}) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      .extended-search-loader-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border: 1px solid ${primaryColor};
        border-radius: 13px;
        padding: 15px 10px;
        @media all and (max-width: ${maxWidth}),${retina}{
          padding: 20px 10px;
        }
        @media all and (min-height: ${maxTablet}) {
          padding: 15px 10px;
          height: calc(100% - 10px);
          display: flex;
          justify-content: center;
        }
        @media all and (max-width: ${maxTablet}) {
          padding: 5px 10px;
          height: calc(100% - 10px);
          min-height: 162px;
          display: flex;
          justify-content: center;
        }
        .loader-style {
          width: 70px;
          height: 70px;
          @media all and (max-width: ${maxWidth}),${retina}{
            width: 60px;
            height: 60px;
            margin-bottom: 5px;
          }
          @media all and (min-height: ${maxTablet}) {
            width: 70px;
            height: 70px;
          }
          @media all and (max-width: ${maxTablet}) {
            width: 50px;
            height: 50px;
          }
        }
        .loader-text {
          font-family: ${secondaryFont};
          font-weight: ${wMedium};
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          margin-bottom: 0;
          @media all and (max-width: ${maxWidth}),${retina}{
            font-size: 11px;
            line-height: 13px;
          }
          @media all and (min-height: ${maxTablet}) {
            font-size: 12px;
            line-height: 14px;
          }
          @media all and (max-width: ${maxTablet}) {
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
      .person-skeleton-loader {
        margin: 0px !important;
        margin-top: 10px !important;
        .badge-box {
          width: 97px;
        }
        .title-box {
          margin-top: 10px;
        }
        .title-box-short {
          display: none;
        }
        .btn {
          display: none;
        }
      }
      .person-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        padding: 6px 11px;
        border: 2px solid #fff;
        cursor: pointer;
        min-height: 162px;
        &.activeCard {
          border-radius: 12px;
          border: 2px solid ${primaryColor};
          background: #DEF6E6;
        }
        .person-without-holo {
          padding: 2px !important;
          margin-bottom: 14px;
          .person-image {
            border: 2px solid #fff;
            line-height: 17px;
          }
          .name-wrapper {
            min-width: 70px;
            max-height: 15.5px;
            bottom: -16px;
            h4 {
              font-size: 8px;
              line-height: normal;
              padding-left: 0px !important;
            }
            .anticon-arrow-right {
              display: none;
            }
          }
        }
        .person-name {
          color: ${primaryHeadingColor};
          font-family: ${secondaryFont};
          font-size: 14px;
          font-weight: ${wMBold};
          line-height: 16px;
          letter-spacing: -0.248px;
          margin-bottom: 3px;
          @media all and (max-width: ${maxTablet}) {
            font-size: 12px;
          }
        }
        .person-designation {
          &.pdesignation {
            min-height: 24px;
          }
          color: ${greyColor};
          font-family: ${primaryFont};
          font-size: 10px;
          font-weight: ${wLight};
          line-height: 12px;
          letter-spacing: -0.17px;
          @media all and (max-width: ${maxTablet}) {
            font-size: 8px;
            line-height: 12px;
          }
        }
      }
    }
  }
`;

type Props = {
  people: Executive[]
  fetchMore: (type: string) => void
  handleClick: (item: TagItem) => void
  active: number
  pagination: boolean
  extendedLoading: boolean
};

const PeopleResultTagging = function PeopleResultTagging(props: Props) {
  const {
    people, fetchMore, handleClick, active, pagination, extendedLoading,
  } = props;
  const [activeCard, setActiveCard] = useState(-1);
  const disclessPersonality = useSelector(selectDisclessPersonality);

  const isGilroyV3 = disclessPersonality ? (!window.location.pathname.includes('feed') && !window.location.pathname.includes('person'))
    : !window.location.pathname.includes('feed');
  const handleMouseEnter = (index: number) => {
    setActiveCard(index);
  };

  const handleMouseLeave = () => {
    setActiveCard(-1);
  };

  return (
    <PeopleWrapper id="gilroySearchPeoplescrollWrapper" className={classNames('', { hide: active !== 1 })}>
      <InfiniteScroll
        hasMore
        dataLength={people.length}
        next={() => fetchMore('person')}
        className=""
        loader={false}
        scrollableTarget="gilroySearchPeoplescrollWrapper"
      >
        <Row gutter={[16, 16]} className={classNames('grid-for-result', { gridForV3: isGilroyV3 })}>
          {people.map((person, index) => (
            <Col span={3} className="grid-col">
              <div
                className={classNames('person-card', { activeCard: index === activeCard })}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick({
                  type: 'person', id: person.personId, name: person.personName, image: person.personImage, color: person.archeType.color, ...(person.personType === 'LIVESEARCH' ? { personType: person.personType } : {}),
                })}
                onKeyDown={() => handleClick({
                  type: 'person', id: person.personId, name: person.personName, image: person.personImage, color: person.archeType.color, ...(person.personType === 'LIVESEARCH' ? { personType: person.personType } : {}),
                })}
                role="button"
                tabIndex={0}
              >
                <PeopleWidgetWithOutHolo
                  diameter="77px"
                  diameterSm="77px"
                  diameterXsm="77px"
                  key={0}
                  persona={person.archeType.color}
                  logo={person.personImage}
                  personName={person.personName}
                  wrapperColor={backgroundColorParsing(person.personalityTypes)}
                  hidePersonality={false}
                  showCheckBox={false}
                  checked={false}
                  personId={person.personId}
                  personality={person.archeType.name}
                  imageClassName="person-image"
                  disableNavigation
                />
                <h6 className="person-name text-truncate-one-line">{person.personName}</h6>
                <span className="person-designation pdesignation text-truncate-two-line">{person.personDesignation}</span>
                <span className="person-designation text-truncate-one-line">{person.companyName}</span>
              </div>
            </Col>
          ))}
          {pagination ? (
            <Col span={3} className="grid-col">
              <PersonSkeleton diameterSm="73px" diameter="73px" />
            </Col>
          ) : null}
          {extendedLoading ? (
            <Col span={3} className="grid-col">
              <div className="extended-search-loader-div">
                <img className="loader-style" src={extendedSearchLoader} alt="extended-loader" />
                <p className="loader-text">
                  {
                    'Uncovering more People using xiQ\'s extended Search.'
                  }
                </p>
              </div>
            </Col>
          ) : null}
        </Row>
      </InfiniteScroll>
    </PeopleWrapper>
  );
};

export default PeopleResultTagging;
