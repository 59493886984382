import { createGlobalStyle } from 'styled-components';
import {
  maxMobile, maxTablet, maxWidth,
  maxWidth1300, midWidthApp, minExtraLargeScreen, minWidthApp,
  retina, textTruncateSnippet,
} from 'Utils/utilsStyle';

const GoogleTranslatorStyle = createGlobalStyle`
  body {
    top: 0px !important;
    > {
      .skiptranslateOne {
        display: none !important;
      }
    }
  }
  #google_translate_element {
    .google_translate_element, .goog-te-gadget {
      color: transparent !important;
      line-height: 0px;
      span {
        display: none;
      }
    }
  }
  .goog-te-combo{
    cursor: pointer;
    padding: 5px 10px !important;
    margin: 12px 0px 0px 0px !important;
    width: 100% !important;
    border-radius: 3px !important;
    color: #000 !important;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      width: 170px !important;
    }
    option{
      background-color: #fff;
      width: 400px;
    }
    &:focus-visible{
      outline: none;
    }
  }
  .goog-logo-link{
   display: none;
  }
  /* For English Language */
  html[lang="en"]{
    /* global style */
  }
  /* For Chines Language */
  html[lang="zh-CN"], html[lang="zh-TW"], html[lang="zh"]{
    body {
      .sales-sigma{
        width: 37px;
      }
      .infinite-scroll-group-browse{
        &.shareFooter {
          @media all and (max-width: ${maxWidth}), ${retina} {
            height: calc(100vh - 325px) !important;
          }
        }
      }
      .certification-completion-popup {
        .ant-modal-content{
          .ant-modal-body{
            .certification-earned-points {
              &.showPoints {
                margin-top: 6px;
              }
              &.show-text {
                margin-bottom: 6px;
              }
            }
          }
        }
      }
      .ceo-tile {
        .label-name {
          @media all and (max-width: ${maxWidth}), ${retina}{
            font-size: 13px;
          }
        }
      }
      .person-info-dv {
        .buttons-div {
          button {
            @media all and (max-width: ${maxTablet}) {
              line-height: 13px !important;
            }
          }
        }
      }
      .person-item {
        .person-item-widget {
          .name-wrapper {
            h4 {
              ${textTruncateSnippet}
              max-width: 120px;
            }
          }
        }
      }
      .state{
        ${textTruncateSnippet}
      }
      .chinese-layouticon{
        margin-left: 0px;
      }
      .small-button-chinese {
        min-width: 70px;
        white-space: nowrap;
      }
      .logout-dropdown-btn{
        .small-button-chinese{
          min-width: unset;
        }
      }
      .aside-wrapper {
        .nav-list {
          .item {
            .mt-c-6 {
              ${textTruncateSnippet}
              max-width: 45px;
            }
          }
        }
      }
      .people-group-drawer{
        .ant-drawer-content{
          .ant-drawer-body{
            h3{
              line-height: 50px;
            }
          }
        }
      }
      .downPrice, .upPrice  {
        .state {
         display: flex;
         margin: 20px auto 0px auto;
          @media all and (max-width: ${maxWidth}) , ${retina} {
            margin: 14px auto 0px auto;
          }
        }
      }
      .swot-head-row{
        .right-side-div-swot{
          @media screen and (max-width: ${maxMobile}){
            min-width: max-content;
            .button-text{
              max-width: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .gilroy-header-text{
        width: max-content;

        @media screen and (max-width: ${maxWidth1300}) and (min-width: ${maxTablet}) {
          font-size: 17px;
          margin-right: 4px;
        }
      }
      .image-container-gif{
        width: 78%;
        &.image-container-gif-v3 {
          width: 273px;
          @media all and (max-width: ${maxTablet}) {
            width: 240px;
          }
        }

        @media screen and (max-width: 1300px) and (min-width: 1200px){
          width: 72%;
        }
      }
      .upload-document-btn{
        right: 100%;
        left: 0;
        transform-origin: 0;
        transform: rotate(270deg) translateY(-50%);
        width: max-content;
        @media screen and (max-width: ${midWidthApp}) {
          left: 18px;
        }
      }
      .triggers-bullet-tooltip{
        .upload-document-btn{
          transform: unset;
        }
      }
      .chatbot-v3-container {
        .container-inner-area {
          .upload-document-btn{
            left: 0;
          }
        }
      }
      .chat-container{
        .header-v3-heading{
          white-space: nowrap;
        }
      }
      .content-popover-c{
        .tip-area{
          min-height: 82px;
        }
      }
      .splash-header{
        width: 100%;
      }
      .articleGridTilesWrapper{
        .gridTile{
          &.notImage{
            .text-area{
              .textAreaContentWrapper{
                .person-activity-text-detail{
                  .article_summary{
                    max-height: 210px;
                    @media all and (min-width: ${minExtraLargeScreen}) {
                      max-height: 263px;
                    }
                    @media all and (max-width: ${maxMobile}) {
                      max-height: 104px;
                    }
                  }
                }
              }
            }
          }
        }
      } 
    }
  }
  
  /* For Spanish Language*/
  html[lang="es"] {
   body { 
    .salesApp {
      .chat-container {
        .upload-document-btn {
          bottom: 66px;
          left: -106px;
        }
        .header-area {
          .heading {
            font-size: 22px;
          }
        }
      }
      .sub-container-document-hub{
        .table-item {
          .heading-div {
            .heading-text {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
      .company-card-bottom-area{
        .finincal-detail{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
   }
  }

  /* For German french and spanish Language*/
  html[lang="de"], html[lang="fr"], html[lang="es"]{
    body {
      .article-preview {
        .button-section {
          .article-viewer-btn {
            display: flex;
            align-items: center;
            .tabs-item {
              &:last-child {
                max-width: 100px;
                ${textTruncateSnippet}
              }
            }
          }
        }
      }
      .textual-area-chat-bot {
          width: 70%;
          .heading {
            &.paragraph {
              ${textTruncateSnippet}
              width: 100%;
              display: flex;
              flex-direction: inherit;
            }
            &.green {
              border-bottom: 0px !important;
              margin: 3px 0px 0px 0px !important;
              display: flex;
              flex-direction: initial;
            } 
          }
        }
      .chatbot-library-styled-wrapper {
        .heading-p {
          ${textTruncateSnippet}
          width: 100%;
        }
      }
      .spotlight-wrapper {
        .state{
          max-width: 150px;
         ${textTruncateSnippet}
        }
      }
      .downPrice, .upPrice  {
        .state {
         display: flex;
         margin: 20px auto 0px auto;
          @media all and (max-width: ${maxWidth}) , ${retina} {
            margin: 14px auto 0px auto;
          }
        }
      }
      .global-filter {
        .heading-panel {
          .head {
            ${textTruncateSnippet}
            max-width: 180px;
          }
        }
        .grid-checkbox-wrapper {
          .title-name {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 90px;
          }
        }
      }
      .drawer-alerts {
        .alerts-head {
          .manage-area {
            ${textTruncateSnippet}
            max-width: 100px;
          }
        }
      }
      .aside-wrapper {
        .nav-list {
          .item {
            .mt-c-6 {
              ${textTruncateSnippet}
              max-width: 45px;
            }
          }
        }
      }
      .group-badge {
          display: flex;
          flex-direction: column;
          align-items: center;
        p{
          ${textTruncateSnippet}
          max-width: 60px;
        }
      }
      .people-group-drawer {
        .groups-wrapper {
          .action-btn {
           ${textTruncateSnippet}
            max-width: 76px;
          }
        }
      }
      .person-item {
        .person-item-widget {
          .name-wrapper {
            h4 {
              ${textTruncateSnippet}
              max-width: 120px;
            }
          }
        }
      }
      .container-company-card {
        .founded-area {
          .item-label {
            ${textTruncateSnippet}
          }
        }
        .body-part {
          .state {
            ${textTruncateSnippet}
            width: 100%;
          }
        }
      }
      .sub-container-document-hub {
        .query-js {
          .right-side {
           display: block !important;
          } 
        }
      } 
      .states-average-score {
        h1 {
          ${textTruncateSnippet}
          width: 100%;
        }
      }
      .sign-out {
        .app-name {
          max-width: 130px;
          ${textTruncateSnippet}
        }
      }
      .toaster-content {
        .span-label {
          ${textTruncateSnippet}
          max-width: 40%;
        }
      }
      .download-doc {
        ul {
          li {
            ${textTruncateSnippet}
            width: 100%;
            display: list-item;
          }
        }
      }
      .company-card-bottom-area {
        .text-truncate-one-line{
          &.w-80 {
            width: 60%;
            min-width: 60%;
          }
        }
      }
      .ceo-tile {
        .person-item {
          .person-item-widget {
            margin: 20px 3px 0px 3px;
          }
        }
      }
      .tabSection {
        #tabs-details {
          h2 {
            font-size: 22px;
          }
        }
      }
      .highcharts-container  {
        .highcharts-legend-item {
          span[data-z-index="2"]{
            > {
              span {
                max-width: 160px;
                margin-top: 0px;
                line-height: 19px;
                ${textTruncateSnippet}
              }
            }
          }
        }
      }
      .body-profile {
        .other-side {
          .add-btn {
            margin: 0px 5px;
            display: inline-block;
            position: relative;
            top: -2px;
          }
          .share-article {
            margin: 0px 5px;
            position: relative;
            top: -1px;
          }
        }
      }
      .chat-container {
        .disclaimerMessage {
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box !important;
          word-wrap: break-word;
        }
        .upload-document-btn {
          bottom: 75px;
          left: -113.5px;
        }
        .header-area {
          .heading {
            font-size: 28px;
          }
        }
        .body-content {
          .ant-modal-content {
            .ant-modal-footer {
              button {
                min-width: 110px;
              }
            }
          }
        }
      }
      .logout-tip {
        .ant-popover-content {
          width: 90px;
        }
      }
      .smart-heading-title-tooltip {
        font-size: 13px;
      }
    }
  }

  /* For German language */
  html[lang="de"]{
    body{
      .salesApp{
        .nav-list{
          .item{
            span{
              width: 100%;
              display: flex;
              justify-content: center;
              text-align: center;
            }
          }
        }
        .swot-head-row{
          .right-side-div-swot{
            @media screen and (max-width: ${maxMobile}){
              min-width: max-content;
              .button-text{
                display: none;
              }
            }            
          }
        }
        .founded-area + div{
          @media screen and (min-width: ${maxMobile}) and (max-width: ${maxTablet}) {
            & > button,
            & > div{
              width: calc(50% - 6px);
            }
            & > div{
              margin-left: 12px;
              overflow: hidden;
              .ant-badge{
                width: 100%;
              }
              button{
                margin-left: 0px;
                display: block;
                width: 100%;
              }
            }
            & > button{
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .chat-container {
        &.chatContainer {
          .textual-area-chat-bot {
            width: 100%;
          }
        }
      }
      .slider-header{
        .slider-text.title{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 35px);
          display: block;
          svg{
            min-width: 21px;
          }
        }
      }
      .ant-drawer-content-wrapper{
        .ant-drawer-body{
          a.item{
            span{
              width: max-content;
              left: 120px;
            }
          }
        }
      }
    }
  }

  /* For Korean Language */
  html[lang="ko"]{
    body{
      .salesApp{
        .nav-list{
          .item{
            span{
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              display: flex;
              justify-content: center;
              text-align: center;
            }
          }
        }
        .gilroy-header-text{
          width: max-content;
        }
        .image-container-gif{
          width: 78%;
          &.image-container-gif-v3 {
            width: 273px;
            @media all and (max-width: ${maxTablet}) {
              width: 240px;
            }
          }
        }
        .buyer-group-members-div{
          span{
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
          }
        }
        .swot-head-row{
          .right-side-div-swot{
            @media screen and (max-width: ${maxMobile}){
              min-width: max-content;
              .button-text{
                max-width: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .person-info-dv {
          .buttons-div {
            button {
              @media all and (max-width: ${maxTablet}) {
                line-height: 13px !important;
              }
            }
            .drawer-btn-action {
              .ant-badge {
                .btn-notes-drawer {
                  @media all and (max-width: ${maxTablet}) {
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
        }
        .upload-document-btn{
          right: 100%;
          left: 0;
          transform-origin: 0;
          transform: rotate(270deg) translateY(-50%);
          width: max-content;
          @media screen and (max-width: ${midWidthApp}) {
            left: 18px;
          }
        }
        .triggers-bullet-tooltip{
          .upload-document-btn{
            transform: unset;
          }
        }
        .chatbot-v3-container .container-inner-area .upload-document-btn{
          left: 0;
        }
        .chat-container{
          .header-v3-heading{
            white-space: nowrap;
          }
        }
        .ceo-tile{
          &.nesting-tile{
            .label-name{
              max-width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              @media screen and (min-width: ${maxWidth}) {
                max-width: 70px;
              }
              @media screen and (max-width: ${minWidthApp}) {
                max-width: 30px;
              }
            }
          }
        }
      }
      .articleGridTilesWrapper{
        .gridTile{
          &.notImage{
            .text-area{
              .textAreaContentWrapper{
                .person-activity-text-detail{
                  .article_summary{
                    max-height: 210px;
                    @media all and (min-width: ${minExtraLargeScreen}) {
                      max-height: 263px;
                    }
                    @media all and (max-width: ${maxMobile}) {
                      max-height: 104px;
                    }
                  }
                }
              }
            }
          }
        }
      } 
      .infinite-scroll-group-browse{
        &.shareFooter {
          @media all and (max-width: ${maxWidth}), ${retina} {
            height: calc(100vh - 325px) !important;
          }
        }
      }
      .people-group-drawer{
        .ant-drawer-content{
          .ant-drawer-body{
            h3{
              line-height: 50px;
            }
          }
        }
      }
      .slider-header{
        .slider-text.title{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 35px);
          display: block;
          svg{
            min-width: 21px;
          }
        }
      }
      .ant-drawer-content-wrapper{
        .ant-drawer-body{
          a.item{
            span{
              width: max-content;
              left: 120px;
            }
          }
        }
      }
      .sales-sigma-leadership-drawer{
        h3{
          line-height: 50px;
          @media all and (max-width: ${maxWidth}) , ${retina} {
            line-height: 38px;
          }
        }
      }
    }
  }

  /* For French Language*/

  html[lang="fr"] {
    body {
      .chat-container {
        &.chatContainer {
          .upload-document-btn {
            bottom: 88px;
            left: -127px;
          }
        }
        .upload-document-btn {
          bottom: 75px;
        }
        .textual-area-chat-bot {
          width: 80%;
          .heading {
            white-space: nowrap;
          }
        }
      }
    }
  }
`;
export default GoogleTranslatorStyle;
