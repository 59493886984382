import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import {
  maxTablet,
  maxWidth,
  primaryHeadingColor, retina, sadGilroyFace, secondaryFont, wMBold,
} from 'Utils/utilsStyle';
import { TAG_LIMIT_MESSAGE } from 'Utils/Constants';
import classNames from 'classnames';
import CrossCircle from '../assets/svgIcons/CrossCircle';

const TaggingLimitModal = styled(Modal)`
  &.clearTaggingV3Modal {
    width: 420px !important;
    @media all and (max-width: ${maxTablet}) {
      width: 320px !important;
    }
      .ant-modal-content {
        .ant-modal-body {
          padding: 63px 50px !important;
          @media all and (max-width: ${maxTablet}) {
            padding: 42px 30px !important
          }
          .sad-gilroy-face {
            width: 75px;
            height: 60px;
            @media all and (max-width: ${maxTablet}) {
              width: 60px;
              height: 50px;
            }
          }
          .heading_logout_popup {
            font-size: 20px;
            line-height: 28px;
            @media all and (max-width: ${maxTablet}) {
              font-size: 16px;
              line-height: normal;
            }
          }
        }
        .ant-modal-footer {
          .ant-btn {
            font-size: 12px;
          }
        }
      }
  }
  @media all and (max-width: ${maxWidth}), ${retina} {
    width: 420px !important;
  }
  @media all and (max-width: ${maxTablet}) {
    width: 320px !important;
  }
  .ant-modal-content{
    border-radius: 18px;
    @media all and (max-width: ${maxTablet}){
      border-radius: 10px;
    }
    .ant-modal-close-x {
      @media all and (max-width: ${maxWidth}), ${retina} {
        width: 40px;
        height: 40px;
      }
      @media all and (max-width: ${maxTablet}){
        width: 50px;
        height: 55px;
        line-height: 65px;
      }
      svg {
        width: 33px;
        min-width: 33px;
        height: 33px;
        min-height: 33px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 25px;
          min-width: 25px;
          height: 25px;
          min-height: 25px;
          margin: 10px 10px 0px 0px;
        }
        @media all and (max-width: ${maxTablet}){
          width: 23px;
          min-width: 25px;
          height: 23px;
          min-height: 23px;
          margin: 0px;
        }
      }
    }
    .ant-modal-body{
      padding: 80px 65px !important;
      @media all and (max-width: ${maxWidth}), ${retina} {
        padding: 60px 40px !important;
      }
      @media all and (max-width: ${maxTablet}) {
        padding: 40px 20px !important;
      }
      text-align: center;
       .sad-gilroy-face {
        width: 100px;
        height: 82px;
        margin-bottom: 16px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 75px;
          height: 60px;
        }
        @media all and (max-width: ${maxTablet}){
          width: 62px;
          height: 50px;
        }
       }
      .heading_logout_popup{
        color: ${primaryHeadingColor};
        text-align: center;
        font-family: ${secondaryFont};
        font-size: 28px;
        font-weight: ${wMBold};
        line-height: 40px;
        letter-spacing: -0.8px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          font-size: 20px;
          line-height: 28px;
        }
        @media all and (max-width: ${maxTablet}){
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    .ant-modal-footer{
      display: none;
    }
  }
`;

type Props = {
  isVisible?: boolean;
  onClose: () => void
  onClick: () => void
} & typeof defaultProps;

const defaultProps = {
  isVisible: false,
};

const GilroyTaggingLimitPopup = function GilroyTaggingLimitPopup(props: Props) {
  const {
    isVisible, onClose, onClick,
  } = props;
  const isGilroyV3 = !window.location.pathname.includes('feed');

  return (
    <div role="button" onClick={onClick} onKeyDown={onClick} tabIndex={0}>
      <TaggingLimitModal
        // maskClosable={false}
        getContainer={() => document.querySelector('.container-inner-area') || document.body}
        visible={isVisible}
        onCancel={onClose}
        centered
        closeIcon={<CrossCircle onClick={onClose} />}
        width="582px"
        className={classNames('logout-modal-c', { clearTaggingV3Modal: isGilroyV3 })}
      >
        <img src={sadGilroyFace} alt="sad-gilroy" className="sad-gilroy-face" />
        <h4 className="heading_logout_popup">{TAG_LIMIT_MESSAGE}</h4>
      </TaggingLimitModal>
    </div>
  );
};
GilroyTaggingLimitPopup.defaultProps = defaultProps;
export default GilroyTaggingLimitPopup;
