import React from 'react';
import styled from 'styled-components';
import { primaryColor } from 'Utils/utilsStyle';

type Props = {
  color?: string,
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  color: '#fff',
  onClick: () => { },
};
const SVG = styled.svg`
&:hover {
  path {
    fill: ${primaryColor};
    stroke: ${primaryColor};
  }
}
`;
const RegenerateIcon = function RegenerateIcon(props: Props) {
  const { color, onClick } = props;
  return (

    <SVG width="14" height="14" viewBox="0 0 14 14" onClick={onClick} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5461 9.34584C11.9347 8.73402 11.3268 8.12579 10.7258 7.52441C10.8952 7.35549 11.0681 7.18297 11.2457 7.00577C11.5359 7.30026 11.8378 7.60644 12.1395 7.91262C12.1495 7.9074 12.1598 7.90219 12.1699 7.89697C12.2879 7.22327 12.2776 6.54974 12.1276 5.88107C11.8547 4.66445 11.2293 3.66406 10.256 2.88368C8.26791 1.28946 5.22248 1.34396 3.30416 3.27153C3.13199 3.09847 2.95982 2.92523 2.78711 2.75164C4.24668 1.18494 7.16042 0.290684 9.82913 1.71149C12.4239 3.0929 13.7674 6.23906 12.5461 9.34584Z" fill={color} stroke={color} strokeWidth="0.8" />
      <path d="M1.4559 4.65416C2.06722 5.26598 2.67512 5.87421 3.27618 6.47559C3.10671 6.64451 2.93382 6.81703 2.75625 6.99423C2.46607 6.69974 2.16419 6.39356 1.86249 6.08738C1.85241 6.0926 1.84216 6.09781 1.83208 6.10303C1.71406 6.77673 1.72432 7.45026 1.87436 8.11892C2.14728 9.33555 2.77262 10.3359 3.74591 11.1163C5.73404 12.7105 8.77947 12.656 10.6978 10.7285C10.87 10.9015 11.0421 11.0748 11.2148 11.2484C9.75528 12.8151 6.84153 13.7093 4.17283 12.2885C1.57806 10.9071 0.234527 7.76093 1.4559 4.65416Z" fill={color} stroke={color} strokeWidth="0.8" />
    </SVG>

  );
};
RegenerateIcon.defaultProps = defaultProps;
export default RegenerateIcon;
