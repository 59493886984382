import React from 'react';

const XiqGptEmailGilroySvg = function XiqGptEmailGilroySvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none" className="gillRoy">
      <ellipse cx="13.5209" cy="13.5898" rx="13.5209" ry="13.5898" transform="matrix(0.769639 -0.63848 0.620119 0.784507 0.689453 17.2656)" fill="white" stroke="#1EC252" strokeWidth="1.8" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4885 17.2179L27.3052 17.2179C28.141 17.2179 28.8248 17.9235 28.8248 18.786L28.8248 20.9812C28.8248 21.8436 28.141 22.5492 27.3052 22.5492L26.4885 22.5492L26.4885 23.2156C26.4885 24.7837 25.2539 26.0773 23.7153 26.0773L15.8897 26.0773C14.3702 26.0773 13.1166 24.8033 13.1166 23.2156L13.1166 22.5492L12.2998 22.5492C11.4641 22.5492 10.7803 21.8436 10.7803 20.9812L10.7803 18.786C10.7803 17.9235 11.4641 17.2179 12.2998 17.2179L13.1166 17.2179L13.1166 16.5515C13.1166 14.9835 14.3512 13.6899 15.8897 13.6899L19.1947 13.6899L19.1947 11.8082C19.1947 11.4554 19.4796 11.181 19.8025 11.181C20.1444 11.181 20.4103 11.475 20.4103 11.8082L20.4103 13.6899L23.7153 13.6899C25.2349 13.6899 26.4885 14.9639 26.4885 16.5515L26.4885 17.2179ZM11.9766 20.9632C11.9766 21.1592 12.1285 21.2964 12.2995 21.2964L13.1162 21.2964L13.1162 18.4543L12.2995 18.4543C12.1095 18.4543 11.9766 18.6111 11.9766 18.7875L11.9766 20.9632ZM25.2912 23.1963C25.2912 24.0979 24.5884 24.8231 23.7146 24.8231L15.889 24.8231C15.0153 24.8231 14.3125 24.0979 14.3125 23.1963L14.3125 16.5518C14.3125 15.6502 15.0153 14.925 15.889 14.925L23.7146 14.925C24.5884 14.925 25.2912 15.6502 25.2912 16.5518L25.2912 23.1963ZM27.305 21.316C27.495 21.316 27.6279 21.1592 27.6279 20.9828L27.6279 18.7875C27.6279 18.5915 27.476 18.4543 27.305 18.4543L26.4883 18.4543L26.4883 21.316L27.305 21.316Z" fill="#1EC252" />
      <ellipse cx="17.4096" cy="18.1591" rx="1.25362" ry="1.29362" fill="#1EC252" />
      <ellipse cx="22.1969" cy="18.1591" rx="1.25362" ry="1.29362" fill="#1EC252" />
      <path d="M22.3869 20.7076C22.083 20.5704 21.7221 20.7076 21.5891 21.0212C21.3422 21.5701 20.6204 21.9621 19.8037 21.9621C18.9869 21.9621 18.2652 21.5701 18.0182 21.0212C17.8853 20.7076 17.5244 20.5704 17.2205 20.7076C16.9166 20.8448 16.7836 21.2172 16.9166 21.5309C17.3534 22.5305 18.5121 23.1969 19.8037 23.1969C21.0953 23.1969 22.2539 22.5305 22.6908 21.5309C22.8238 21.2172 22.6908 20.8448 22.3869 20.7076Z" fill="#1EC252" />
    </svg>
  );
};

export default XiqGptEmailGilroySvg;
