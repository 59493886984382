/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Conversation, PromptCategoryV2, SetChat, SetSections, TagItem,
  ChatbotV2LimitPopup,
  PersonCardId,
  Section, DeleteChat, TagLists, HistoryItem,
} from 'Types/ChatBotV2Types';
import { PersonLinkedinSearch, SearchResultSection, SearchResultsRequest } from 'Types/GlobalSearchResultsTypes';
import { ChatBotV2State } from 'Types/GlobalTypes';
import {
  ChatFeedback,
  DeleteConversationRequest, EditConversationRequest,
  ChatbotRequest,
  GetConversationsRequest, GetPromptsRequest, PromptCategoriesRequest,
  EditChatV2Request, ChatHistoryV2Request, DeleteChatRequest, GetPersonProfileRequest,
  TagSetupRequest,
} from 'Types/RequestBodies';
import { getLocalStorageValue, removeHtmlTags } from 'Utils/UtilityFunctions';
import { DEFAULT_DOCUMENT_GILROY_IAMGE, DEFAULT_GILROY_IMAGE, DEFAULT_GROUP_GILROY_IMAGE } from 'Utils/Constants';
import { RecentTagSetupResponse } from 'Types/ResponseBodies';
import { SectionTypes } from './GlobalSearchResultsSlice';
import { Base64 } from 'js-base64';

export const initialState: ChatBotV2State = {
  conversationsLoading: false,
  converstationSections: [],
  deleteConversationLoading: false,
  deleteConversationLoaded: false,
  promptCategories: [],
  promptCategoriesLoading: false,
  prompts: [],
  promptsLoading: false,
  selectedPromptCategory: '',
  showExamplePrompts: false,
  editConversationLoading: false,
  showLibrary: true,
  chatHistory: [],
  chatLoading: false,
  deleteChat: {} as DeleteChat,
  stopGeneratingIds: [],
  pendingChatId: 0,
  chatId: 1,
  chatHistoryLoading: false,
  selectedConversationId: -1,
  editChatLoading: false,
  peopleSection: {
    title: 'Matching People',
    type: SectionTypes.Executives,
    count: 0,
    linkedInCount: 0,
    persons: [],
    liveSearchPersons: [],
    followedPersons: [],
    firstCount: 0,
  },
  companiesSection: {
    title: 'Matching Companies',
    type: SectionTypes.Companies,
    count: 0,
    linkedInCount: 0,
    companies: [],
    followedCompanies: [],
    firstCount: 0,
  },
  buyerGroupsSection: {
    title: 'Buyer Groups',
    type: SectionTypes.BuyerGroups,
    groups: [],
    count: 0,
    firstCount: 0,
  },
  documentSection: {
    title: 'Matching Documents',
    type: SectionTypes.Documents,
    documents: [],
    count: 0,
    firstCount: 0,
  },
  searchResultspage: 0,
  isGlobalSearchLoading: false,
  isGlobalSearchLoaded: false,
  isPersonExtendedLoaded: false,
  isPersonExtendedLoading: false,
  personPage: 1,
  companyPage: 1,
  buyerPage: 1,
  documentPage: 1,
  query: '',
  taggedItem: null,
  showSearchPopup: false,
  isRegenerate: false,
  editInProgressId: 0,
  limitPopup: {} as ChatbotV2LimitPopup,
  taggedItemsList: [],
  uniqueTagItemList: [],
  personCardLoading: false,
  idToReplace: null,
  scrollOnConvoUpdate: false,
  saveProfileName: '',
  saveUrl: '',
  generatingChannelId: 0,
  limitPopupVisible: false,
  tagSetupLoading: false,
  isNewChat: false,
  searchQuery: '',
  promptInProgress: '',
  placeholderToReplace: '',
  certificationPrompt: '',
  recentTags: [],
  historyPanelOpened: false,
  drawerPreviewOpen: false,
  history: [],
};

const ChatBotSliceV2 = createSlice({
  name: 'chatBotV2',
  initialState,
  reducers: {
    setShowLibrary(state, action: PayloadAction<boolean>) {
      state.showLibrary = action.payload;
    },
    getConversations(state, action: PayloadAction<GetConversationsRequest>) {
      state.conversationsLoading = true;
    },
    setConversations(state, action: PayloadAction<SetSections>) {
      const newSections = action.payload.sections;
      const lastIndex = state.converstationSections.length - 1;
      if (action.payload.offset !== 0 && state.converstationSections[lastIndex]?.sectionName
        === newSections[0]?.sectionName) {
        const newData = newSections[0]?.sectionData;
        const updatedData = state.converstationSections[lastIndex].sectionData.concat(newData);
        const sectionsWithoutFirstIndex = newSections.slice(1);
        state.converstationSections[lastIndex].sectionData = updatedData;
        state.converstationSections = [...state.converstationSections,
          ...sectionsWithoutFirstIndex];
      } else {
        state.converstationSections = [...state.converstationSections, ...newSections];
      }
      state.conversationsLoading = false;
    },
    deleteConversation(state, action: PayloadAction<DeleteConversationRequest>) {
      state.deleteConversationLoading = true;
      state.deleteConversationLoaded = false;
    },
    setDeleteConversation(state, action: PayloadAction<number>) {
      const updatedSections = state.converstationSections.map((section) => ({
        ...section,
        sectionData: section.sectionData.filter(
          (conversation: Conversation) => conversation.channelId !== action.payload,
        ),
      }))
        .filter((section) => section.sectionData.length > 0);
      state.converstationSections = updatedSections;
      state.deleteConversationLoading = false;
      state.deleteConversationLoaded = true;
    },
    setDeleteConversationLoaded(state, action: PayloadAction<boolean>) {
      state.deleteConversationLoaded = action.payload;
    },
    getPromptCategories(state, action: PayloadAction<PromptCategoriesRequest>) {
      state.promptCategoriesLoading = true;
    },
    setPromptCategories(state, action: PayloadAction<PromptCategoryV2[]>) {
      state.promptCategories = action.payload;
      state.promptCategoriesLoading = false;
    },
    getPrompts(state, action: PayloadAction<GetPromptsRequest>) {
      state.promptsLoading = true;
    },
    setPrompts(state, action: PayloadAction<string[]>) {
      state.prompts = action.payload;
      state.promptsLoading = false;
    },
    setSelectedPromptCategory(state, action: PayloadAction<string>) {
      state.selectedPromptCategory = action.payload;
    },
    editConversation(state, action: PayloadAction<EditConversationRequest>) {
      state.editConversationLoading = true;
    },
    setEditConversation(state, action: PayloadAction<EditConversationRequest>) {
      const { text, channelId } = action.payload;
      const updatedSections = state.converstationSections.map((section) => ({
        ...section,
        sectionData: section.sectionData.map((item) => {
          if (item.channelId === channelId) {
            return {
              ...item,
              channelHeading: text,
            };
          }
          return item;
        }),
      }));
      state.converstationSections = updatedSections;
      state.editConversationLoading = false;
    },
    setShowExamplePrompts(state, action: PayloadAction<boolean>) {
      state.showExamplePrompts = action.payload;
    },
    chatbot(state, action: PayloadAction<ChatbotRequest>) {
      state.chatLoading = true;
      state.showLibrary = false;
      state.pendingChatId = state.chatId;
      state.chatId += 1;
      state.promptInProgress =  Base64.decode(action.payload?.prompt);
    },
    setChat(state, action: PayloadAction<SetChat>) {
      const chatArray = action.payload.chat;
      const id = action.payload?.id as number;
      const channelId = action.payload?.channelId;
      const channelHeading = action.payload?.channelHeading;
      const isNewChat = action.payload?.isNewChat;
      const source = action.payload?.source;
      let sourceImg = '';
      switch (source) {
        case 'group':
          sourceImg = DEFAULT_GROUP_GILROY_IMAGE;
          break;
        case 'document':
          sourceImg = DEFAULT_DOCUMENT_GILROY_IAMGE;
          break;
        default:
          sourceImg = DEFAULT_GILROY_IMAGE;
      }
      if (state.stopGeneratingIds.includes(id)) {
        state.deleteChat.chatId = chatArray[0].id;
        state.deleteChat.convoId = channelId || 0;
      } else {
        if (chatArray.length === 0) {
          state.chatHistoryLoading = false;
        }
        chatArray.forEach((chat) => {
          const chatObject = chat;
          chatObject.chat = chatObject.chat.replace(/\n/g, '<br />');
          const senderName = getLocalStorageValue('fullname');
          chatObject.chat = chatObject.chat.replace(/\[Your Name\]/gi, senderName || '');
          if (id) {
            if (state.generatingChannelId === state.selectedConversationId) {
              state.chatHistory = [...state.chatHistory, chatObject];
            } else {
              state.chatLoading = false;
            }
            if (isNewChat) {
              const channel: Conversation = {
                channelId: channelId || 0,
                channelHeading: removeHtmlTags(channelHeading || ''),
                isFavorite: false,
                avatar: {
                  image: action.payload.image?.length
                    ? action.payload.image : sourceImg,
                  color: action.payload.color ?? '',
                },
              };
              if (state.converstationSections[0]?.sectionName === 'Today') {
                state.converstationSections[0].sectionData = [channel,
                  ...state.converstationSections[0].sectionData];
              } else {
                const section = {
                  sectionName: 'Today',
                  sectionData: [channel],
                };
                state.converstationSections = [section, ...state.converstationSections];
              }
              if (state.generatingChannelId === state.selectedConversationId) {
                state.selectedConversationId = channelId || 0;
                state.generatingChannelId = channelId || 0;
              }
            }
            state.chatLoading = false;
          } else {
            state.chatHistory = [chatObject, ...state.chatHistory];
            state.chatHistoryLoading = false;
          }
        });
      }
    },
    setChatbotPopup(state, action: PayloadAction<ChatbotV2LimitPopup>) {
      state.limitPopup = action.payload;
      state.chatLoading = false;
    },
    setChatLoading(state, action: PayloadAction<boolean>) {
      state.chatLoading = action.payload;
    },
    getChatHistory(state, action: PayloadAction<ChatHistoryV2Request>) {
      state.chatHistoryLoading = true;
    },
    setSelectedConversationId(state, action: PayloadAction<number>) {
      state.selectedConversationId = action.payload;
    },
    sendFeedback(state, action: PayloadAction<ChatFeedback>) {

    },
    setFeedbackSubmitted(state, action: PayloadAction<string>) {
      const responseId = action.payload;
      state.chatHistory = state.chatHistory.map((chat) => {
        if (chat.responseId === responseId) {
          return { ...chat, feedbackSubmitted: true };
        }
        return chat;
      });
    },
    editChatResponse(state, action: PayloadAction<EditChatV2Request>) {
      state.editChatLoading = true;
    },
    updateChatResponse(state, action: PayloadAction<EditChatV2Request>) {
      const { responseId, chat } = action.payload;
      const chatIndex = state.chatHistory.findIndex(
        (chatObject) => chatObject.responseId === responseId,
      );
      const data = {
        chat,
        edited: 1,
      };
      if (chatIndex !== -1) {
        const updatedChats = [...state.chatHistory];
        updatedChats[chatIndex] = { ...updatedChats[chatIndex], ...data };
        state.chatHistory = updatedChats;
      }
      state.editChatLoading = false;
    },
    setEditChatLoading(state, action: PayloadAction<boolean>) {
      state.editChatLoading = action.payload;
    },
    setStopGenerating(state, action: PayloadAction<number>) {
      state.stopGeneratingIds = [...state.stopGeneratingIds, action.payload];
      state.chatLoading = false;
    },
    deleteChat(state, action: PayloadAction<DeleteChatRequest>) {
    },
    setDeleteChat(state, action: PayloadAction<string>) {
      state.chatHistory = state.chatHistory.filter(
        (chat) => chat.id.toString() !== action.payload,
      );
    },
    setIsRegenerate(state, action: PayloadAction<boolean>) {
      state.isRegenerate = action.payload;
    },
    setEditInProgressId(state, action: PayloadAction<number>) {
      state.editInProgressId = action.payload;
    },
    setConvoToLatest(state, action: PayloadAction<number>) {
      const channelIdToMove = action.payload;
      if (!(state.converstationSections[0]?.sectionName === 'Today' && channelIdToMove === state.converstationSections[0]?.sectionData[0].channelId)) {
        let conversationToMove: Conversation | undefined;
        let sectionIndex: number | undefined;

        state.converstationSections.forEach((section, index) => {
          const conversation = section.sectionData.find(
            (conv) => conv.channelId === channelIdToMove,
          );

          if (conversation) {
            conversationToMove = conversation;
            sectionIndex = index;
          }
        });
        if (conversationToMove) {
          if (state.converstationSections[0].sectionName === 'Today') {
            if (sectionIndex !== undefined) {
              state.converstationSections[sectionIndex].sectionData = state.converstationSections[
                sectionIndex
              ].sectionData.filter((conv) => conv.channelId !== channelIdToMove);
            }
            state.converstationSections[0].sectionData.unshift(conversationToMove);
          } else {
            if (sectionIndex !== undefined) {
              state.converstationSections[sectionIndex].sectionData = state.converstationSections[
                sectionIndex
              ].sectionData.filter((conv) => conv.channelId !== channelIdToMove);
            }
            const newSection: Section = {
              sectionName: 'Today',
              sectionData: [conversationToMove],
            };
            state.converstationSections.unshift(newSection);
          }
        }
        state.scrollOnConvoUpdate = true;
      }
    },
    setScrollOnConvoUpdate(state, action: PayloadAction<boolean>) {
      state.scrollOnConvoUpdate = action.payload;
    },
    setSaveProfileName(state, action: PayloadAction<string>) {
      state.saveProfileName = action.payload;
    },
    setSaveUrl(state, action: PayloadAction<string>) {
      state.saveUrl = action.payload;
    },
    setGeneratingChannelId(state, action: PayloadAction<number>) {
      state.generatingChannelId = action.payload;
    },
    resetChatHistory(state) {
      state.chatHistory = [];
    },
    personLinkedinSearch(state, action: PayloadAction<PersonLinkedinSearch>) {
      const { payload } = action;
      state.isPersonExtendedLoaded = true;
      state.isPersonExtendedLoading = false;
      state.peopleSection.liveSearchPersons = [
        ...(state.peopleSection.liveSearchPersons ?? []),
        ...payload.results,
      ];
      state.peopleSection.linkedInCount = payload.count ?? 0;
      if (
        state.peopleSection.linkedInCount > 0
        && state.searchResultspage === 1
      ) {
        state.peopleSection.count += state.peopleSection.linkedInCount;
        state.peopleSection.firstCount = state.peopleSection.persons
          ? state.peopleSection.persons.length : 0;
      }
    },
    globalSearchResultsLoaded(state, action: PayloadAction<SearchResultSection[]>) {
      state.isGlobalSearchLoading = false;
      state.isGlobalSearchLoaded = true;
      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.BuyerGroups,
        )
        .forEach((section) => {
          state.buyerGroupsSection.title = section.title;
          state.buyerGroupsSection.groups = [...state?.buyerGroupsSection?.groups ?? [],
            ...section.groups ?? []];
          if (section.count > 0 && state.searchResultspage === 1) {
            state.buyerGroupsSection.count += section.count;
            state.buyerGroupsSection.firstCount = state.buyerGroupsSection.groups
              ? state.buyerGroupsSection.groups.length : 0;
          }
        });
      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.Companies,
        )
        .forEach((section) => {
          state.companiesSection.title = section.title;
          state.companiesSection.companies = [...state?.companiesSection?.companies ?? [],
            ...section.companies ?? []];
          if (section.count > 0 && state.searchResultspage === 1) {
            state.companiesSection.count += section.count;
            state.companiesSection.firstCount = state.companiesSection.companies
              ? state.companiesSection.companies.length : 0;
          }
        });
      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.Executives,
        )
        .forEach((section) => {
          state.peopleSection.title = section.title;
          state.peopleSection.persons = [...(state.peopleSection.persons ?? []),
            ...(section.persons ?? [])];
          if (section.count > 0 && state.searchResultspage === 1) {
            state.peopleSection.count += section.count;
            state.peopleSection.firstCount = state.peopleSection.persons
              ? state.peopleSection.persons.length : 0;
          }
        });
      action.payload
        ?.filter(
          (section: SearchResultSection) => section.type === SectionTypes.Documents,
        )
        .forEach((section) => {
          state.documentSection.title = section.title;
          state.documentSection.documents = [...(state.documentSection.documents ?? []),
            ...(section.documents ?? [])];
          if (section.count > 0 && state.searchResultspage === 1) {
            state.documentSection.count += section.count;
            state.documentSection.firstCount = state.documentSection.documents
              ? state.documentSection.documents.length : 0;
          }
        });
    },
    globalSearchResultsError(state) {
      state.isGlobalSearchLoading = false;
      state.isGlobalSearchLoaded = true;
      state.isPersonExtendedLoaded = true;
      state.isPersonExtendedLoading = false;
    },
    globalSearchResults(state, action: PayloadAction<SearchResultsRequest>) {
      const { page, query } = action.payload;
      state.searchQuery = query.trim();
      const temp = query.trim().split(':');
      if (temp[0].includes('group') || temp[0].includes('person') || temp[0].includes('company') || temp[0].includes('document')) {
        state.query = temp[1]?.trim();
      } else {
        state.query = query.trim();
      }
      state.buyerPage = page && query.includes('group') ? page : state.buyerPage;
      state.personPage = page && query.includes('person') ? page : state.personPage;
      state.companyPage = page && query.includes('company') ? page : state.companyPage;
      state.documentPage = page && query.includes('document') ? page : state.documentPage;
      state.searchResultspage = page || 1;
      if (!page || page === 1) {
        state.isGlobalSearchLoaded = false;
        state.isPersonExtendedLoaded = false;
      }
      state.isGlobalSearchLoading = true;
      state.isPersonExtendedLoading = !query.includes('group') && !query.includes('company') && !query.includes('document');
      state.isPersonExtendedLoaded = query.includes('group') || query.includes('company') || query.includes('document') ? true : state.isPersonExtendedLoaded;
      state.peopleSection.persons = page
        ? state.peopleSection.persons
        : [];
      state.peopleSection.liveSearchPersons = page
        ? state.peopleSection.liveSearchPersons
        : [];
      state.peopleSection.count = page ? state.peopleSection.count : 0;
      state.companiesSection.companies = page
        ? state.companiesSection.companies
        : [];
      state.companiesSection.count = page
        ? state.companiesSection.count
        : 0;
      state.buyerGroupsSection.groups = page
        ? state.buyerGroupsSection.groups
        : [];
      state.buyerGroupsSection.count = page
        ? state.buyerGroupsSection.count
        : 0;
      state.companiesSection.count = page
        ? state.companiesSection.count
        : 0;
      state.documentSection.documents = page
        ? state.documentSection.documents
        : [];
      state.documentSection.count = page
        ? state.documentSection.count
        : 0;
    },
    setQuery(state, action:PayloadAction<string>) {
      state.query = action.payload;
    },
    setPeoplePage(state, action:PayloadAction<number>) {
      state.personPage = action.payload;
    },
    setCompaniesPage(state, action:PayloadAction<number>) {
      state.companyPage = action.payload;
    },
    setBuyerPage(state, action:PayloadAction<number>) {
      state.buyerPage = action.payload;
    },
    setDocumentsPage(state, action:PayloadAction<number>) {
      state.documentPage = action.payload;
    },
    setTaggedItem(state, action: PayloadAction<TagItem | null>) {
      state.taggedItem = action.payload;
      if (action.payload) {
        state.taggedItemsList.push(action.payload);
      }
    },
    setUniqueTaggedItem(state, action: PayloadAction<TagItem>) {
      if (action.payload) {
        const isIdExists = state.uniqueTagItemList?.some((item) => item.id === action.payload.id);
        if (!isIdExists) {
          state.uniqueTagItemList.push(action.payload);
        }
      }
    },
    removeUniqueTaggedItem(state, action: PayloadAction<number>) {
      if (action.payload) {
        state.uniqueTagItemList = state
          .uniqueTagItemList?.filter((item) => item.id !== action.payload);
      }
    },
    changeOrderRecentTags(state, action: PayloadAction<TagItem[]>) {
      // Remove duplicates from action.payload based on 'id'
      const uniquePayload = action.payload.reduce((acc: TagItem[], current: TagItem) => {
        const existingIdSet = new Set(acc.map((obj) => (obj as { id: number }).id));
        if (!existingIdSet.has(current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);

      const payloadLength = uniquePayload.length;
      const idsToRemove = uniquePayload.map((obj) => (obj as { id: number }).id);

      // Remove items with matching IDs from state.recentTags
      state.recentTags = state.recentTags.filter(
        (obj) => !idsToRemove.includes((obj as { id: number }).id),
      );

      const excessItems = state.recentTags.length + payloadLength - 10;

      // Trim excess items if needed
      if (excessItems > 0) {
        state.recentTags.splice(-excessItems);
      }

      // Add unique payload items to the beginning of state.recentTags
      state.recentTags.unshift(...uniquePayload.reverse());
    },

    pushTagItem(state, action: PayloadAction<TagItem>) {
      state.taggedItemsList.push(action.payload);
    },
    resetTagItemsList(state) {
      state.taggedItemsList = [];
      state.uniqueTagItemList = [];
    },
    tagLiveSearchPerson(state, action: PayloadAction<GetPersonProfileRequest>) {
      state.personCardLoading = true;
    },
    personSetup(state, action:PayloadAction<TagSetupRequest>) {
      state.tagSetupLoading = true;
    },
    companySetup(state, action:PayloadAction<TagSetupRequest>) {
      state.tagSetupLoading = true;
    },
    groupSetup(state, action:PayloadAction<TagSetupRequest>) {
      state.tagSetupLoading = true;
    },
    removeTagItem(state, action:PayloadAction<number>) {
      const id = action.payload;
      const index = state.taggedItemsList.findIndex((item) => item.id === id);
      if (index !== -1) {
        state.taggedItemsList.splice(index, 1);
      }
    },
    recentTags(state, action:PayloadAction<RecentTagSetupResponse>) {
      const { recentTags } = action.payload.response;
      state.recentTags = recentTags;
    },
    setLivePersonTag(state, action:PayloadAction<PersonCardId>) {
      state.personCardLoading = false;
      const { livesearchId, executiveId, color } = action.payload;
      const index = state.taggedItemsList.findIndex((item) => item.id === livesearchId);
      if (index !== -1 && executiveId) {
        state.taggedItemsList[index].id = executiveId;
        state.taggedItemsList[index].personType = 'EXECUTIVE';
        state.taggedItemsList[index].color = color;
        state.idToReplace = action.payload;
      }
      if (state.placeholderToReplace.includes(livesearchId.toString())) {
        state.placeholderToReplace = state.placeholderToReplace.replace(/ data-color="[^"]*"/g, '').replace(/ data-color=""/g, '').replace(`id="${livesearchId}"`, `id="${executiveId}" data-color="${color}"`);
      }
    },
    setPersonCardLoading(state, action:PayloadAction<boolean>) {
      state.personCardLoading = action.payload;
    },
    setTagSetupLoading(state, action:PayloadAction<boolean>) {
      state.tagSetupLoading = action.payload;
    },
    resetIdToReplacae(state) {
      state.idToReplace = null;
    },
    setSearchPopup(state, action: PayloadAction<boolean>) {
      state.searchQuery = '';
      state.showSearchPopup = action.payload;
      if (!action.payload) {
        state.searchResultspage = 0;
        state.isGlobalSearchLoading = false;
        state.isGlobalSearchLoaded = false;
        state.isPersonExtendedLoaded = false;
        state.isPersonExtendedLoading = false;
        state.personPage = 1;
        state.companyPage = 1;
        state.buyerPage = 1;
        state.documentPage = 1;
        state.query = '';
        state.buyerGroupsSection = initialState.buyerGroupsSection;
        state.peopleSection = initialState.peopleSection;
        state.companiesSection = initialState.companiesSection;
        state.documentSection = initialState.documentSection;
      }
    },
    resetChatbotPopup(state) {
      state.limitPopup.callToAction = '';
      state.limitPopup.contactUsEmail = '';
      state.limitPopup.popupText = '';
      state.limitPopup.showPlanPopup = 0;
    },
    resetTagging(state) {
      state.history = [];
      state.uniqueTagItemList = [];
      state.taggedItemsList = [];
      state.taggedItem = null;
      state.idToReplace = null;
      state.personCardLoading = false;
      state.peopleSection = {
        title: 'Matching People',
        type: SectionTypes.Executives,
        count: 0,
        linkedInCount: 0,
        persons: [],
        followedPersons: [],
        liveSearchPersons: [],
        firstCount: 0,
      };
      state.companiesSection = {
        title: 'Matching Companies',
        type: SectionTypes.Companies,
        count: 0,
        linkedInCount: 0,
        companies: [],
        followedCompanies: [],
        firstCount: 0,
      };
      state.buyerGroupsSection = {
        title: 'Buyer Groups',
        type: SectionTypes.BuyerGroups,
        groups: [],
        count: 0,
        firstCount: 0,
      };
      state.documentSection = {
        title: 'Matching Documents',
        type: SectionTypes.Documents,
        documents: [],
        count: 0,
        firstCount: 0,
      };
      state.searchResultspage = 0;
      state.isGlobalSearchLoading = false;
      state.isGlobalSearchLoaded = false;
      state.isPersonExtendedLoaded = false;
      state.isPersonExtendedLoading = false;
      state.personPage = 1;
      state.companyPage = 1;
      state.buyerPage = 1;
      state.documentPage = 1;
      state.query = '';
      state.limitPopupVisible = false;
    },
    setLimitPopupVisible(state, action: PayloadAction<boolean>) {
      state.limitPopupVisible = action.payload;
    },
    setIsNewChat(state, action: PayloadAction<boolean>) {
      state.isNewChat = action.payload;
    },
    setPromptInProgress(state, action: PayloadAction<string>) {
      state.promptInProgress = action.payload;
    },
    setPlaceholderToReplace(state, action: PayloadAction<string>) {
      state.placeholderToReplace = action.payload;
    },
    setCertificationPrompt(state, action: PayloadAction<string>) {
      state.certificationPrompt = action.payload;
    },
    setHistoryPanelOpened(state, action: PayloadAction<boolean>) {
      state.historyPanelOpened = action.payload;
    },
    getRecentTags(state) {

    },
    setDrawerPreviewOpen(state, action: PayloadAction<boolean>) {
      state.drawerPreviewOpen = action.payload;
    },
    setTagsList(state, action: PayloadAction<TagLists>) {
      const { allTags, uniqueTags } = action.payload;
      state.taggedItemsList = allTags;
      state.uniqueTagItemList = uniqueTags;
    },
    setHistory(state, action:PayloadAction<HistoryItem[]>) {
      state.history = action.payload;
    },
    reset: (state) => ({
      ...initialState,
      promptCategories: state.promptCategories,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = ChatBotSliceV2;
