import React from 'react';

type Props = {
  color?: string;
  onClick?: () => void;
} & typeof defaultProps;

const defaultProps = {
  color: '#001760',
  onClick: () => { },
};

const ExpandIconGilroy = function ExpandIconGilroy({ color, onClick }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none" className="expand-icon-gilroy-svg" onClick={onClick}>
      <path d="M13.9643 0H2.03571C0.911826 0.00084816 0.000846857 0.911826 0 2.03571V12.3214C0.00084816 13.4453 0.911826 14.3563 2.03571 14.3571H13.9643C15.0882 14.3563 15.9992 13.4453 16 12.3214V2.03571C15.9992 0.911826 15.0882 0.000846857 13.9643 0ZM2.03571 13C1.6608 12.9992 1.35799 12.6963 1.35714 12.3214V2.03571C1.35799 1.6608 1.6608 1.35799 2.03571 1.35714H6.78571V13H2.03571ZM14.6429 12.3214C14.642 12.6963 14.3392 12.9992 13.9643 13H8.14286V1.35714H13.9643C14.3392 1.35799 14.642 1.6608 14.6429 2.03571V12.3214Z" fill={color} />
      <circle cx="4" cy="4" r="1" fill={color} />
      <circle cx="4" cy="7" r="1" fill={color} />
    </svg>
  );
};

ExpandIconGilroy.defaultProps = defaultProps;
export default ExpandIconGilroy;
