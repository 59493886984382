/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SettingState } from 'Types/GlobalTypes';
import { SettingsRequest } from 'Types/RequestBodies';

export const initialState: SettingState = {
  updateLoading: false,
  settingUpdated: false,
  subscription: '',
};

const sriSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    updateSetting(state, action: PayloadAction<SettingsRequest>) {
      const { subscription } = action.payload;
      state.updateLoading = true;
      state.settingUpdated = false;
      state.subscription = subscription;
    },
    updateSettingSuccess(state) {
      state.updateLoading = false;
      state.settingUpdated = true;
    },
    updateSettingfail(state) {
      state.updateLoading = false;
      state.settingUpdated = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = sriSlice;
