/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  lazy, Suspense, useEffect, useState,
} from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  EmailShareButton,
  TwitterShareButton,
  // InstapaperIcon,
} from 'react-share';
import styled from 'styled-components';
import {
  getCurrentTime, isGenpact, teamsShareLauncherFunction, valueToObject,
} from 'Utils/UtilityFunctions';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { actions as flurryActions } from 'Slice/HelperSlice';

import { actions as certificationActions } from 'Slice/CertificationSlice';
import {
  selectAiImgLink, selectData, selectImgLink,
  selectIsCertificationCompleted, selectOpenGilroyDrawer,
} from 'Selector/CertificationSelector';
import { CERTIFICATE_MAIL_TEXT } from 'Utils/Constants';
import CustomLoaderPopup from 'Components/common/modal/CustomLoaderPopup';
import { actions as articleHelper } from 'Slice/ArticlesSlice';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import EverNote from './EverNote';

const SocialTwitterIcon = lazy(() => import('Components/common/assets/svgIcons/SocialTwitterIcon'));
const SocialMailIcon = lazy(() => import('Components/common/assets/svgIcons/SocialMailIcon'));
const SocialFaceBookIcon = lazy(() => import('Components/common/assets/svgIcons/SocialFaceBookIcon'));
const SocialLinkedinIcon = lazy(() => import('Components/common/assets/svgIcons/SocialLinkedinIcon'));

export const SocialIcon = styled.span`
margin-right: 5px;
cursor: pointer;
`;

type Props = {
  articleid?: number | string
  title: string
  img?: string
  domain?: string
  entity: string
  alertId?: number
  url?: string
  isTwitter?: boolean
  tweetBy?: string
  certificationPage?: boolean
  spotlight?: boolean
  chatGptShare?: boolean
  summary?: string[]
  isSimilarArticle?: boolean
  summaryLoading?: boolean
  summaryLoaded?: boolean
};

const defaultProps = {
  articleid: '',
  img: '',
  domain: '',
  alertId: 0,
  url: '',
  certificationPage: false,
  spotlight: false,
  isTwitter: false,
  tweetBy: '',
  chatGptShare: false,
  summary: [],
  isSimilarArticle: false,
  summaryLoading: false,
  summaryLoaded: false,
};

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.shareIconsWrapper {
    gap: 12px;
    overflow: hidden;
    .evernote-share-button,
    .social-icons-slide-mail,
    .social-icons-slide-linkedin,
    .social-icons-slide-twitter,
    .social-icons-slide-facebook,
    .teams-share-button{
      display: flex;
      height: 24px !important;
      width: 24px;
      /* to overwrite styles */
      min-width: unset !important;
      margin: 0 !important;
      border-radius: unset !important;
      text-align: unset !important;
    }
    .social-icons-slide-mail,
    .social-icons-slide-linkedin,
    .social-icons-slide-twitter,
    .social-icons-slide-facebook {   
      & > span{
        height: 100%;
        width: 100%;
        display: flex;
        margin: 0 !important;
        & > svg{
          height: 100%;
          width: 100%;
          margin: 0;
        }
      }
    }
    .evernote-share-button {
      & > span{
        height: 100%;
        width: 100%;
        display: flex;
        margin: 0 !important;
        & > a{
          height: 100%;
          width: 100%;
          display: flex;
          margin: 0;
          & > svg{
            height: 100%;
            width: 100%;
            margin: 0;
          }
        }
      }
    }
    .teams-share-button {
      &::before{
        height: 100%;
        width: 100%;
        top: 0;
      }
      & > a{
        height: 100%;
        width: 100%;
        display: flex;
        margin: 0;
        & > img{
          height: 100%;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
`;

const SocialShareIcons = function SocialShareIcons(props: Props) {
  const {
    articleid, title, img, domain, entity, alertId, url,
    certificationPage, spotlight, isTwitter, tweetBy,
    chatGptShare, summary, isSimilarArticle, summaryLoading,
    summaryLoaded,
  } = props;
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const isGilroyDrawer = useSelector(selectOpenGilroyDrawer);
  const salesxImgLink = useSelector(selectImgLink);
  const aiImgLink = useSelector(selectAiImgLink);
  const imgLink = isGilroyDrawer ? aiImgLink : salesxImgLink;
  const host = window.location.href;
  const subject = isTwitter ? `Tweet by ${tweetBy as string}` : (chatGptShare ? '' : title);
  const articleTitle = isTwitter ? '' : title;
  const URL = url as string;
  const waterMark = '-Shared via xiQ';
  const dispatch = useDispatch();
  const completed = useSelector(selectIsCertificationCompleted);
  useEffect(() => {
    teamsShareLauncherFunction();
    dispatch(articleHelper.resetArticleItemSummary());
  }, []);

  const socialShareFlurry = (eventname: string) => {
    const startTime = getCurrentTime();
    const data = {
      share_text: title,
      share_link: url,
      article_id: articleid,
      alert_id: alertId,
      host,
    };
    dispatch(flurryActions.callFlurryEvent(valueToObject(`${eventname}`, startTime, data)));
  };

  const certificationShareFlurry = (eventname: string) => {
    const startTime = getCurrentTime();
    dispatch(flurryActions.callFlurryEvent(valueToObject(`${eventname}`, startTime)));
  };

  const data = useSelector(selectData);

  const certificationActivity = () => {
    if (spotlight || !completed) {
      dispatch(certificationActions.certificationAction({
        type: 'spotlight_article_share',
        source: 'spotlight',
        data,
      }));
    }
  };

  const shareSummary = () => {
    const articleSummary = summary?.join('\n\n');
    const body = `Hi,\n\nI have found this news article to be interesting and useful.\n\nHere is the summary of this article:\n\n${articleSummary || ''}\n\n`;
    const link = URL;
    const mailtoString = `mailto:?subject=${encodeURIComponent(`${subject} ${waterMark}`)}&body=${encodeURIComponent(body + link)}`;
    setShowLoadingPopup(false);
    dispatch(articleHelper.setArticleSummaryLoaded(false));
    window.location.href = mailtoString;
  };

  useEffect(() => {
    if (!summaryLoading && summary?.length && summaryLoaded && showLoadingPopup) {
      shareSummary();
    }
  }, [summaryLoading, summaryLoaded]);

  const handleShareEmail = () => {
    if (!summaryLoading) {
      if (!summary?.length) {
        setShowLoadingPopup(true);
        dispatch(articleHelper.getArticleSummary({
          articleId: parseInt(articleid as string, 10),
          isSimilarArticle: isSimilarArticle as boolean,
          isArticleItem: true,
        }));
      } else {
        shareSummary();
      }
    }
  };

  console.log(articleid, title, img, domain, entity);
  return (
    // <SocialWrapper className="share-icons-wrapper">
    <SocialWrapper className="shareIconsWrapper">
      {articleid ? (
        <span
          className="social-icons-slide-mail"
          onClick={handleShareEmail}
          onKeyPress={handleShareEmail}
          tabIndex={0}
          role="button"
        >
          <SocialIcon onClick={(e) => {
            window.onbeforeunload = () => {
              e.preventDefault();
            };
            if (!certificationPage) {
              socialShareFlurry('article_share_email');
            } else {
              certificationShareFlurry('certification_email_share');
            }
            certificationActivity();
          }}
          >
            <Suspense
              fallback={<div />}
            >
              {summaryLoading ? <SkeletonCustom width="14px" height="14px" margin="0px 5px 0px" marginSm="0px 5px 0px" /> : <SocialMailIcon />}
            </Suspense>
          </SocialIcon>
        </span>
      )
        : (
          <EmailShareButton
            className="social-icons-slide-mail"
            url={certificationPage ? imgLink : URL}
            body={certificationPage ? CERTIFICATE_MAIL_TEXT : articleTitle}
            subject={
              isGenpact()
                ? subject
                : `${subject} ${waterMark}`
            }
            separator={'\r\n\r\n'}
          >
            <SocialIcon onClick={(e) => {
              window.onbeforeunload = () => {
                e.preventDefault();
              };
              if (!certificationPage) {
                socialShareFlurry('article_share_email');
              } else {
                certificationShareFlurry('certification_email_share');
              }
              certificationActivity();
            }}
            >
              <Suspense
                fallback={<div />}
              >
                <SocialMailIcon />
              </Suspense>
            </SocialIcon>
          </EmailShareButton>
        )}
      {summaryLoading && showLoadingPopup ? <CustomLoaderPopup /> : null}
      {!certificationPage ? (
        <FacebookShareButton
          className="social-icons-slide-facebook"
          url={certificationPage ? imgLink : URL}
          quote={isGenpact() ? articleTitle : `${articleTitle} ${waterMark}`}
          hashtag={waterMark}
        >
          <SocialIcon onClick={() => {
            if (!certificationPage) {
              socialShareFlurry('article_share_facebook');
            } else {
              certificationShareFlurry('certification_facebook_share');
            }
            certificationActivity();
          }}
          >
            <Suspense
              fallback={<div />}
            >
              <SocialFaceBookIcon />
            </Suspense>
          </SocialIcon>
        </FacebookShareButton>
      ) : null}

      <LinkedinShareButton
        className="social-icons-slide-linkedin"
        url={certificationPage ? imgLink : URL}
        title={
          isGenpact()
            ? articleTitle
            : `${articleTitle} ${waterMark} `
        }
      >
        <SocialIcon onClick={() => {
          if (!certificationPage) {
            socialShareFlurry('article_share_linkedin');
          } else {
            certificationShareFlurry('certification_linkedin_share');
          }
          certificationActivity();
        }}
        >
          <Suspense
            fallback={<div />}
          >
            <SocialLinkedinIcon />
          </Suspense>
        </SocialIcon>
      </LinkedinShareButton>

      <TwitterShareButton
        className="social-icons-slide-twitter"
        url={certificationPage ? imgLink : URL}
        title={
          isGenpact()
            ? articleTitle && articleTitle.substring(0, 230)
            : articleTitle && `${articleTitle.substring(0, 230)} ${waterMark} `
        }
      >
        <SocialIcon onClick={() => {
          if (!certificationPage) {
            socialShareFlurry('article_share_twitter');
          } else {
            certificationShareFlurry('certification_twitter_share');
          }
          certificationActivity();
        }}
        >
          <Suspense
            fallback={<div />}
          >
            <SocialTwitterIcon />
          </Suspense>
        </SocialIcon>
      </TwitterShareButton>
      {!certificationPage ? (
        <>
          {isTwitter ? null : (
            <span
              className="evernote-share-button"
              role="button"
              tabIndex={0}
              onClick={() => {
                if (!certificationPage) {
                  socialShareFlurry('article_share_evernote');
                } else {
                  certificationShareFlurry('certification_evernote_share');
                }
                certificationActivity();
              }}
              onKeyPress={certificationActivity}
            >
              <EverNote
                url={certificationPage ? imgLink : URL}
                title={isGenpact() ? title : `${title} ${waterMark}`}
              />
            </span>
          )}

          <div
            className="teams-share-button"
            data-preview
            data-href={certificationPage ? imgLink : URL}
            data-msg-text={chatGptShare ? title : 'Shared via xiQ'}
            data-icon-px-size="22"
            onClick={() => {
              if (!certificationPage) {
                socialShareFlurry('article_share_msteams');
              } else {
                certificationShareFlurry('certification_teams_share');
              }
              certificationActivity();
            }}
            onKeyPress={() => {
              if (!certificationPage) socialShareFlurry('article_share_msteams');
              certificationActivity();
            }}
            role="button"
            tabIndex={0}
            aria-label="Call"
          />
        </>
      ) : null}

    </SocialWrapper>
  );
};

SocialShareIcons.defaultProps = defaultProps;
export default SocialShareIcons;
