import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import {
  maxTablet,
  maxWidth,
  primaryFont, retina, wMedium,
} from 'Utils/utilsStyle';
import { useDispatch } from 'react-redux';
import { actions } from 'Slice/ChatbotV2Slice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import Cross from '../assets/svgIcons/Cross';
import Tick from '../assets/svgIcons/Tick';

const StyledWrapper = styled.span`
  position: relative;
  width: 100%;
  .editable-input {
    width: 100%;
    background-color: #fff;
    box-shadow: none;
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 0px 60px 0px 10px;
    font-family: ${primaryFont};
    font-weight: ${wMedium};
    font-size: 12px;
    line-height: 20px;
    color: #616161;
    min-height: 29px;
  }
  .icon-container {
    position: absolute;
    display: flex;
    align-items: center;
    top: 7px;
    right: 10px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      top: 7px;
    }
    @media all and (max-width: ${maxTablet}) {
      top: 6px;
    }
    .cross {
      cursor: pointer;
      height: 16px;
      width: 9px;
      margin-right: 10px;
      svg {
        height: inherit;
        width: inherit;
      }
    }
    .ant-divider {
      border-color: #a0a0a0;
      margin: 0 10px;
    }
    .tick {
      cursor: pointer;
      height: 15px;
      width: 13px;
      @media all and (max-width: ${maxTablet}) {
        margin-bottom: 2px;
      }
      svg {
        height: inherit;
        width: inherit;
      }
      &.disabled{
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
`;
type Props = {
  disableTick: boolean,
  id: number,
  defaultText: string,
  setIsEditing: (a: boolean) => void,
};

const EditableInputGlobal = function EditableInputGlobal(props: Props) {
  const {
    setIsEditing, id, defaultText, disableTick,
  } = props;
  const [editedText, setEditedText] = useState(defaultText);
  const dispatch = useDispatch();
  const startTime = getCurrentTime();
  const host = window.location.href;

  const handleEdit = () => {
    if (editedText.trim().length) {
      dispatch(actions.editConversation({
        channelId: id,
        text: editedText,
      }));
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_editSubject_saved_conversationHistory', startTime, {
          channelId: id,
          text: editedText,
          host,
        }),
      ));
    }
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_editSubject_cancel_conversationHistory', startTime, {
        host,
      }),
    ));
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleEdit();
    } else if (e.key === 'Escape') {
      handleEditCancel();
    }
  };

  return (
    <StyledWrapper className={classNames('editable-input-global')}>
      <input
        name="title"
        value={editedText}
        onChange={(e) => setEditedText(e.target.value)}
        onKeyDown={onKeyDown}
        className="editable-input"
      />
      <div className="icon-container">
        <span
          className="cross"
          onClick={handleEditCancel}
          onKeyPress={handleEditCancel}
          role="button"
          aria-label="close"
          tabIndex={0}
        >
          <Cross />
        </span>
        <span
          className={classNames('tick', { disabled: disableTick || !editedText?.trim()?.length || editedText?.trim() === defaultText })}
          onClick={handleEdit}
          onKeyPress={handleEdit}
          role="button"
          aria-label="close"
          tabIndex={0}
        >
          <Tick />
        </span>
      </div>
    </StyledWrapper>
  );
};

export default EditableInputGlobal;
