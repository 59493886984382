import BuyerGroupCard from 'Components/common/components/BuyerGroupCard';
import BuyerGroupSkeleton from 'Components/common/skeleton/BuyerGroupSkeleton';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import { TagItem } from 'Types/ChatBotV2Types';
import { StaffBuyerGroupData } from 'Types/GroupsTypes';
import { DEFAULT_GROUP_GILROY_IMAGE } from 'Utils/Constants';
import {
  maxMobile,
  maxTablet,
  midMobile,
  primaryColor,
  primaryHeadingColor, scrollbar, secondaryFont, smMobile, wMBold,
} from 'Utils/utilsStyle';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const BRMWrapper = styled.div`
  &.hide{
    display: none;
  }
  margin-top: 5px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  ${scrollbar};
  ::-webkit-scrollbar { width: 6px; } 
  ::-webkit-scrollbar-track { background-color: transparent; } 
   @media all and (max-width: ${maxTablet}) {
    ::-webkit-scrollbar { width: 3px; } 
  } 
  .grid-for-result {
    &.gridForV3 {
      .grid-col {
        flex: 0 0 50%;
        max-width: 50%;
        @media all and (max-width: ${maxMobile}) {
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 35px !important;
          padding-right: 35px !important;
        }
      }
    }
    margin: 0px !important;
    width: 100%;
    .grid-col {
     @media all and (max-width: 1750px) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      @media all and (max-width: 1600px) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media all and (max-width: 1280px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      @media all and (max-width: ${maxTablet}) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media all and (max-width: ${maxMobile}) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      @media all and (max-width: ${midMobile}) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      padding-left: 2px !important;
      padding-right: 0px !important;
      .buyer-group-skeleton {
        width: 157px;
        margin-top: 6px;
        .buyer-skeleton-wrapper {
          .companies-logo-div {
            margin: 0 10px;
          }
          .title-box {
            display: none;
          }
          .title-box-short {
            display: none;
          }
          .round-logos {
            margin: 20px auto 22px;
          }
        }
      }
      .BRM-card {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 4px 6px;
        border: 2px solid #fff;
        cursor: pointer;
        &.activeCard {
          border-radius: 12px;
          border: 2px solid ${primaryColor};
          background: #DEF6E6;
        }
        .buyers-card-item {
          padding: 3px;
          .card-inner-wrapper {
            .logo-image {
              margin: 24px 0px 14px;
              @media all and (max-width: ${maxTablet}) {
                margin: 16px 0px 8px;
              }
              .frame-logo {
                width: 45px;
                height: 28px;
                &:last-child{ 
                  @media all and (max-width: ${smMobile}){
                    display: none;
                  }
                }
              }
            }
            .user-images {
              margin-top: 0px;
              min-height: 32px;
              @media all and (max-width: ${maxTablet}) {
                margin-bottom: 25px;
              }
              .person-without-holo {
                width: 32px;
                height: 32px;
                min-width: 32px;
              }
            }
            .buyer-name {
              display: none;
            }
            .data-footer-main {
              display: none;
            }
          }
        }
        .buyer-group-name {
          width: 100%;
          color: ${primaryHeadingColor};
          font-family: ${secondaryFont};
          font-size: 14px;
          line-height: 25px;
          font-weight: ${wMBold};
          letter-spacing: -0.454px;
          margin-bottom: 0px;
          @media all and (max-width: ${maxTablet}) {
            margin-top: 6px;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

type Props = {
  buyers: StaffBuyerGroupData[],
  fetchMore: (type: string) => void
  handleClick: (item: TagItem) => void
  active: number
  loading: boolean
};

const BRMResultTagging = function BRMResultTagging(props: Props) {
  const {
    buyers, fetchMore, handleClick, active, loading,
  } = props;
  const [activeCard, setActiveCard] = useState(-1);
  const disclessPersonality = useSelector(selectDisclessPersonality);

  const isGilroyV3 = disclessPersonality ? (!window.location.pathname.includes('feed') && !window.location.pathname.includes('person'))
    : !window.location.pathname.includes('feed');
  const handleMouseEnter = (index: number) => {
    setActiveCard(index);
  };

  const handleMouseLeave = () => {
    setActiveCard(-1);
  };

  return (
    <BRMWrapper id="gilroySearchGroupscrollWrapper" className={classNames('', { hide: active !== 3 })}>
      <InfiniteScroll
        hasMore
        dataLength={buyers.length}
        next={() => fetchMore('group')}
        className=""
        loader={false}
        scrollableTarget="gilroySearchGroupscrollWrapper"
      >
        <Row gutter={[16, 16]} className={classNames('grid-for-result', { gridForV3: isGilroyV3 })}>
          {buyers.map((group, index) => (
            <Col span={4} className="grid-col">
              <div
                className={classNames('BRM-card', { activeCard: index === activeCard })}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick({
                  type: 'group', id: group.groupId, name: group.groupName, image: DEFAULT_GROUP_GILROY_IMAGE, color: '',
                })}
                onKeyDown={() => handleClick({
                  type: 'group', id: group.groupId, name: group.groupName, image: DEFAULT_GROUP_GILROY_IMAGE, color: '',
                })}
                role="button"
                tabIndex={0}
              >
                <BuyerGroupCard
                  key={group.groupId}
                  groupId={group.groupId}
                  groupName={group.groupName}
                  groupPersona={group.groupPersonality}
                  persons={group.persons}
                  companies={group.companyLogos}
                  members={group.personsCount}
                  isShared={group.groupShared}
                  sharedBy={group.sharedByFirstName}
                  disableNavigation
                />
                <h4 className="buyer-group-name text-truncate-one-line">{group.groupName}</h4>
              </div>
            </Col>
          ))}
          {loading ? (
            <Col span={4} className="grid-col">
              <BuyerGroupSkeleton border />
            </Col>
          ) : null}
        </Row>
      </InfiniteScroll>
    </BRMWrapper>
  );
};

export default BRMResultTagging;
