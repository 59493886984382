import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTaggedItem, selectUniqueTagItemList } from 'Selector/ChatBotV2Selector';
import { actions } from 'Slice/ChatbotV2Slice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import styled from 'styled-components';
import { TagItem } from 'Types/ChatBotV2Types';
import { TAG_LIMIT, translateSkipAreas } from 'Utils/Constants';
import {
  maxTablet,
  maxWidth,
  maxWidth1300,
  nutenixColor,
  primaryFont, retina, retinaOne, wMedium,
} from 'Utils/utilsStyle';
import SliderBackwardIcon from 'Components/common/assets/svgIcons/SliderBackwardIcon';
import SliderForwardIcon from 'Components/common/assets/svgIcons/SliderForwardIcon';
import { valueToObject, getCurrentTime } from 'Utils/UtilityFunctions';
import TagPill from './mainComponents/TagPill';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';

const StyledWrapper = styled.div`
  padding-bottom: 0px;
  position: absolute;
  top: -38px;
  height: 37px;
  left: -1px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 19.533px -2.298px rgba(0, 0, 0, 0.19);  
  padding: 6px 0px 6px 11px;
  width: calc(100%);
  display: flex;
  align-items: center;
  .arrow-div {
    width: 38px;
    height: inherit;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 0px 3.53px -1.3px rgba(0, 0, 0, 0.06);
    &.prev-div {
      &.arrowDisabled {
        box-shadow: none;
        pointer-events: none;
        cursor: default;
        svg {
          path {
            fill: #C6CDD0;
          }
        }
      }
    }
    &.next-div {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: -4px 0px 3.53px -1.3px rgba(0, 0, 0, 0.06);
      &.arrowDisabledNext {
        box-shadow: none;
        pointer-events: none;
        cursor: default;
        svg {
          path {
            fill: #C6CDD0;
          }
        }
      }
    }
    svg {
      height: 12px;
      width: 12px;
      path {
      fill: #667982;
      }
    }
  }
  .tags-wrapper-slider {
    width: 103%;
    display: flex;
    margin-right: 5px;
    @media all and (max-width: ${maxWidth1300}) {
      padding-bottom: 1px;
    }
  }
  @media all and (max-width: ${maxWidth}), ${retina} {
    top: -37.1px;
    height: 36px;
  }
  @media all and (min-height: ${maxTablet}) {
    top: -39px;
  }
  .recent-tag-slider {
    width: calc(100% - 150px);
    .slick-arrow {
      background-color: #fff;
      position: absolute;
      top: 13px;
      left: -38px;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 36px;
      box-shadow: 4px 0px 3.53px -1.3px rgba(0, 0, 0, 0.06);
      @media all and (max-width: ${maxWidth}), ${retina} {
        height: 35px;
      }
      @media all and (${retinaOne}) {
        top: 12px;
      }
      svg {
         path {
          fill: #667982;
        }
      }
      ::before {
        content: '';
      }
      &.slick-next {
        right: -44px;
        left: unset;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        box-shadow: -4px 0px 3.53px -1.3px rgba(0, 0, 0, 0.06);
      }
      &.slick-disabled {
        box-shadow: none;
        svg {
          path {
            fill: #C6CDD0;
          }
        }
      }
      .slider-backward-icon {
        height: 12px;
        width: 12px;
      }
      .slider-forward-icon {
        height: 12px;
        width: 12px;
      }
    }
    .slick-list {
      width: calc(100% + 5px);
      .slick-track {
        margin-left: 0px;
      }
    }
  }
`;

const Heading = styled.span`
  &.withArrows {
  margin-right: 40px;
  }
  margin-right: 12px;
  color: #5C5E5F;
  font-family: ${primaryFont};
  font-size: 12px;
  font-style: normal;
  font-weight: ${wMedium};
  line-height: 15px;
  letter-spacing: -0.272px;
  white-space: nowrap;
`;

type Props = {
  recentTags: TagItem[]
  setRecentTagState: React.Dispatch<React.SetStateAction<boolean>>;
  setPopUpClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const RecentTags = function RecentTags(props: Props) {
  const { recentTags, setRecentTagState, setPopUpClose } = props;
  const dispatch = useDispatch();
  const host = window.location.href;
  const uniqueTagList = useSelector(selectUniqueTagItemList);
  const taggedItem = useSelector(selectTaggedItem);
  const tagsWrapperRef = useRef<HTMLDivElement>(null);
  const [prevArrow, setPrevArrow] = useState(false);
  const [nextArrow, setNextArrow] = useState(false);
  const [scrollMove, setScrollMove] = useState(0);
  const startTime = getCurrentTime();
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const isTextareaFocused = () => {
    const textarea = document.getElementById('custom-text-editable');
    return document.activeElement === textarea;
  };
  const isGlobalInputFocused = () => {
    const textarea = document.getElementById('global-search-input');
    return document.activeElement === textarea;
  };

  useEffect(() => {
    const tagsWrapper = tagsWrapperRef.current;
    if (tagsWrapper) {
      const isNoSpaceLeft = tagsWrapper.scrollLeft
        >= tagsWrapper.scrollWidth - tagsWrapper.clientWidth;
      const isAtStart = tagsWrapper.scrollLeft === 0;
      const isAtEnd = tagsWrapper.scrollLeft === tagsWrapper.scrollWidth - tagsWrapper.clientWidth;

      setPrevArrow(isAtStart);
      setNextArrow(isNoSpaceLeft);

      const prevDivNode = document.getElementById('prev-div');
      const nextDivNode = document.getElementById('next-div');

      if (prevDivNode) {
        if (isAtStart) {
          prevDivNode.classList.add('arrowDisabled');
        } else {
          prevDivNode.classList.remove('arrowDisabled');
        }
      }

      if (nextDivNode) {
        if (isNoSpaceLeft || isAtEnd) {
          nextDivNode.classList.add('arrowDisabledNext');
        } else {
          nextDivNode.classList.remove('arrowDisabledNext');
        }
      }
    }
  }, [tagsWrapperRef, prevArrow, nextArrow, scrollMove]);

  const removeDuplicateRecentTags = () => {
    const uniqueIds = new Set();
    return recentTags.filter((tag) => {
      if (uniqueIds.has(tag.id)) {
        return false;
      }
      uniqueIds.add(tag.id);
      return true;
    });
  };

  const addTagsToTextarea = (tag: TagItem) => {
    const isIdExists = uniqueTagList?.some((item) => item.id === tag.id);
    if (uniqueTagList.length >= TAG_LIMIT && !isIdExists) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_tagLimitReached', startTime, {
          host,
        }),
      ));
      dispatch(actions.setLimitPopupVisible(true));
      setPopUpClose(true);
    } else if ((isTextareaFocused() || isGlobalInputFocused()) && !taggedItem) {
      dispatch(actions.setTaggedItem(tag));
      dispatch(actions.setUniqueTaggedItem(tag));
      setRecentTagState(true);
    }
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_recent_tags_selected', 0, {
        host,
      }),
    ));
  };

  const getColor = (tag: TagItem): string => {
    if (tag.type === 'person') {
      return tag.color || '';
    } if (tag.type === 'group') {
      return 'transparent';
    }
    return '#979797';
  };

  const handlePrevSlide = () => {
    const tagsWrapper = tagsWrapperRef.current;
    if (tagsWrapper) {
      tagsWrapper.scrollLeft -= 320;
      setScrollMove(scrollMove - 1);
    }
  };

  const handleNextSlide = () => {
    const tagsWrapper = tagsWrapperRef.current;
    if (tagsWrapper) {
      tagsWrapper.scrollLeft += 320;
      setScrollMove(scrollMove + 1);
    }
  };

  return (
    <StyledWrapper className="recent-tags">
      <Heading>Recent Tags:</Heading>
      <span className="arrow-div prev-div" id="prev-div">
        <SliderBackwardIcon onClick={handlePrevSlide} />
      </span>
      <div
        className={`tags-wrapper-slider wrap-slider overFlowOnMaxWidth ${translateSkipAreas}`}
        ref={tagsWrapperRef}
        id="recent-tag-slider-div"
        onScroll={() => setScrollMove(scrollMove + 1)}
      >
        {removeDuplicateRecentTags().map((tag) => {
          const color = disclessPersonality ? nutenixColor : getColor(tag);
          return (
            <TagPill
              key={tag.name}
              imageSrc={tag.image || ''}
              personality={color || ''}
              name={tag.name || ''}
              onClick={() => addTagsToTextarea(tag)}
              tagType={tag.type}
            />
          );
        })}
      </div>
      <span className="arrow-div next-div" id="next-div">
        <SliderForwardIcon onClick={handleNextSlide} />
      </span>
    </StyledWrapper>

  );
};

export default RecentTags;
