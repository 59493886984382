import React from 'react';

type Props = {
  color?: string,
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  color: '#A0A0A0',
  onClick: () => { },
};

const SideMenuIcon = function SideMenuIcon(props: Props) {
  const { color, onClick } = props;
  return (
    <svg width="5" height="23" viewBox="0 0 5 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="side-menu-icon" onClick={onClick}>
      <circle cx="2.5" cy="2.5" r="2.5" fill={color} />
      <circle cx="2.5" cy="11.5" r="2.5" fill={color} />
      <circle cx="2.5" cy="20.5" r="2.5" fill={color} />
    </svg>
  );
};
SideMenuIcon.defaultProps = defaultProps;
export default SideMenuIcon;
