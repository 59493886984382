import classNames from 'classnames';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  // margin-bottom: 20px;
  .buyer-skeleton-wrapper{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 16px;
    width: 100%;
    padding-top: 28px;
    margin-bottom: 28px;
    &.border {
      border: 5px solid #e9e9e9; 
    }
    .company-logo{
      height: 26px;
      width:40px;
      border-radius:0;
    }
    .company-logo:nth-of-type(2){
      width:50px;
      margin:0 10px;
    }
    .company-logo:nth-of-type(3){
      width:40px;
    }
   .title-box {
     height: 25px;
     border-radius: 25px;
     width: 90%;
     margin:20px auto 10px auto;
   }
   .title-box-short{
     width: 80%;
     height: 25px;
     border-radius: 25px;
     margin:0 auto;
   }
  }
  .round-logos{
    margin:20px auto 30px auto;
    .user-logo{
      border-radius:50%;
      width: 30px;
      height: 30px;
      margin-left:-7px;
    }
  }
  .skeleton-box {
    height: 20px;
    border-radius: 25px;
    width: 50px;
  }
`;

type Props = {
  border?: boolean
} & typeof defaultProps;

const defaultProps = {
  border: false,
};

const BuyerGroupSkeleton = function BuyerGroupSkeleton(props: Props) {
  const { border } = props;
  return (
    <Wrapper className="buyer-group-skeleton">
      <div className={classNames('buyer-skeleton-wrapper', { border })}>
        <div className="d-flex justify-content-center companies-logo-div">
          <span className="skeleton-box company-logo" />
          <span className="skeleton-box company-logo" />
          <span className="skeleton-box company-logo" />
        </div>
        <span className="skeleton-box title-box" />
        <span className="skeleton-box title-box-short" />
        <div className="d-flex justify-content-center round-logos">
          <span className="skeleton-box user-logo" />
          <span className="skeleton-box user-logo" />
          <span className="skeleton-box user-logo" />
          <span className="skeleton-box user-logo" />
        </div>
      </div>
    </Wrapper>
  );
};
BuyerGroupSkeleton.defaultProps = defaultProps;
export default BuyerGroupSkeleton;
