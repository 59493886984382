import {
  SecondaryColor, primaryFont, wMBold, wMedium,
} from 'Utils/utilsStyle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import {
  MidWidthApp, getCurrentTime, valueToObject,
} from 'Utils/UtilityFunctions';
import { actions as flurryActions } from 'Slice/HelperSlice';
import classNames from 'classnames';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';

const StyledWrapper = styled.div`
display: flex;
align-items: center;
border-radius: 22px;
border: 1px solid ${SecondaryColor};
z-index: 1;
cursor: pointer;
&.hidden{
display: none
}
.new-tag {
  display: flex;
  border-radius: 3px;
  background: rgba(0, 23, 96, 0.10);
  margin-right: 5px;
  padding: 3px 5px;
  height: 18px;
}
.button-text {
  color: ${SecondaryColor};
  font-family: ${primaryFont};
  font-size: 14px;
  line-height: 13px;
  font-weight: ${wMedium};
  letter-spacing: -0.498px;
  margin-bottom: 0px;
  &.tag-text {
    font-size: 10px;
    line-height: 12px;
    font-weight: ${wMBold};
  }
}
`;

const GetCertifiedButton = function GetCertifiedButton() {
  const midApp = MidWidthApp();
  const dispatch = useDispatch();
  const startTime = getCurrentTime();
  const isFeed = !!window.location.pathname.includes('feed');
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const handleCertificationDrawer = () => {
    dispatch(certificationActions.setOpenGilroyDrawer(true));
    dispatch(certificationActions.setOpenDrawer(true));
    dispatch(flurryActions.callFlurryEvent(
      valueToObject(isFeed ? 'Certificate_AI_Gilroy_Feedpage ' : 'Certificate_AI_Gilroy_User_Profile', startTime, {}),
    ));
  };

  return (
    <StyledWrapper
      className={classNames(
        'get-certified-ai-button-wrapper',
        { hidden: disclessPersonality },
      )}
      onClick={() => handleCertificationDrawer()}
    >
      <span className="new-tag button-text tag-text">New</span>
      <span className="button-text">{midApp ? 'Get Gen AI Certified' : 'Get Generative AI for Sales Certified '}</span>
    </StyledWrapper>
  );
};

export default GetCertifiedButton;
