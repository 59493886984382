import { primaryColor } from 'Utils/utilsStyle';
import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  &:hover {
    path {
      fill: ${primaryColor};
       &:nth-child(3) {
        stroke: ${primaryColor};
      }
    }
    ellipse {
      fill: ${primaryColor};
      stroke: ${primaryColor};
    }
  }
`;

type Props = {
  onClickAction?: () => void;
};

const defaultProps = {
  onClickAction() { },
};

const ThumbsDownIcon = function ThumbsDownIcon(props: Props) {
  const { onClickAction } = props;
  return (
    <SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClickAction} id="thumbs-down-am">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.63186 0.535994H4.19516C4.90553 0.535994 5.48403 1.12204 5.48403 1.84284V9.67557C5.48403 10.3959 4.90553 10.9824 4.19516 10.9824H1.63186C0.921486 10.9824 0.343506 10.3959 0.343506 9.67557V1.84284C0.343506 1.12204 0.921486 0.535994 1.63186 0.535994ZM1.37427 9.67578C1.37427 9.82047 1.49017 9.93799 1.63235 9.93799H4.19566C4.33835 9.93799 4.45426 9.82047 4.45426 9.67578V1.84305C4.45426 1.69889 4.33835 1.58085 4.19566 1.58085H1.63235C1.49017 1.58085 1.37427 1.69889 1.37427 1.84305V9.67578Z" fill="#667982" />
      <path d="M4.19516 0.750279H1.63186V0.321708H4.19516V0.750279ZM5.26974 1.84284C5.26974 1.23763 4.78445 0.750279 4.19516 0.750279V0.321708C5.02662 0.321708 5.69832 1.00644 5.69832 1.84284H5.26974ZM5.26974 9.67557V1.84284H5.69832V9.67557H5.26974ZM4.19516 10.7681C4.7844 10.7681 5.26974 10.2803 5.26974 9.67557H5.69832C5.69832 10.5114 5.02667 11.1967 4.19516 11.1967V10.7681ZM1.63186 10.7681H4.19516V11.1967H1.63186V10.7681ZM0.557792 9.67557C0.557792 10.2804 1.04273 10.7681 1.63186 10.7681V11.1967C0.800239 11.1967 0.12922 10.5113 0.12922 9.67557H0.557792ZM0.557792 1.84284V9.67557H0.12922V1.84284H0.557792ZM1.63186 0.750279C1.04268 0.750279 0.557792 1.23752 0.557792 1.84284H0.12922C0.12922 1.00656 0.800295 0.321708 1.63186 0.321708V0.750279ZM1.63235 10.1523C1.36876 10.1523 1.15998 9.93572 1.15998 9.67578H1.58856C1.58856 9.70521 1.61159 9.7237 1.63235 9.7237V10.1523ZM4.19566 10.1523H1.63235V9.7237H4.19566V10.1523ZM4.66854 9.67578C4.66854 9.936 4.45949 10.1523 4.19566 10.1523V9.7237C4.21722 9.7237 4.23997 9.70493 4.23997 9.67578H4.66854ZM4.66854 1.84305V9.67578H4.23997V1.84305H4.66854ZM4.19566 1.36656C4.45976 1.36656 4.66854 1.58363 4.66854 1.84305H4.23997C4.23997 1.81415 4.21694 1.79513 4.19566 1.79513V1.36656ZM1.63235 1.36656H4.19566V1.79513H1.63235V1.36656ZM1.15998 1.84305C1.15998 1.58391 1.36849 1.36656 1.63235 1.36656V1.79513C1.61186 1.79513 1.58856 1.81387 1.58856 1.84305H1.15998ZM1.15998 9.67578V1.84305H1.58856V9.67578H1.15998Z" fill="#667982" />
      <path d="M13.6367 0.535959H7.39323C7.0754 0.535959 6.75756 0.612219 6.47269 0.756378L4.73772 1.63597C4.48324 1.76498 4.38021 2.0789 4.50745 2.33692C4.63469 2.59495 4.94377 2.69941 5.19876 2.5704L6.93373 1.69081C7.07591 1.61873 7.23457 1.5806 7.39323 1.5806H13.6367C13.8247 1.5806 13.9998 1.6835 14.0936 1.8496L14.6752 2.8817C15.402 4.17132 15.7863 5.63904 15.7863 7.12452V8.89206C15.7863 9.4687 15.3237 9.93774 14.755 9.93774H10.8776C10.5361 9.93774 10.2177 10.1091 10.0261 10.3958C9.83497 10.6826 9.79633 11.0461 9.92306 11.3673C10.3954 12.5645 10.635 13.8259 10.635 15.1166V15.7219C10.635 15.9883 10.4212 16.2056 10.158 16.2056H9.83755C9.62582 16.2056 9.43729 16.0614 9.37908 15.8551L9.17714 15.139C8.74443 13.605 8.02685 12.1957 7.04397 10.9495C6.53708 10.3065 5.78086 9.93774 4.9685 9.93774H3.42309C3.13874 9.93774 2.90796 10.1717 2.90796 10.4601C2.90796 10.7484 3.13874 10.9824 3.42309 10.9824H4.9685C5.4656 10.9824 5.92922 11.2086 6.23985 11.6024C7.1367 12.74 7.79195 14.0265 8.18654 15.4263L8.38847 16.1424C8.57238 16.7943 9.16839 17.2502 9.83755 17.2502H10.158C10.9894 17.2502 11.6652 16.565 11.6652 15.7219V15.1166C11.6652 13.6922 11.4005 12.3002 10.8797 10.9793L14.755 10.9824C15.8919 10.9824 16.8166 10.0448 16.8166 8.89206V7.12452C16.8166 5.45727 16.3854 3.81039 15.5694 2.36304L14.9879 1.33093C14.7112 0.840471 14.1935 0.535959 13.6367 0.535959Z" fill="#667982" stroke="#667982" strokeWidth="0.428572" />
      <ellipse cx="0.515134" cy="0.522321" rx="0.515134" ry="0.522321" transform="matrix(1 0 0 -1 2.39307 3.66992)" fill="#667982" stroke="#667982" strokeWidth="0.428572" />
    </SVG>

  );
};

ThumbsDownIcon.defaultProps = defaultProps;
export default ThumbsDownIcon;
