import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
`;

const ChatBotHistorySkeletonComponent = function ChatBotHistorySkeletonComponent() {
  return (
    <Wrapper>
      <SkeletonCustom width="97%" height="60px" diameter="4px !important" logo logoSize="50px" margin="0px 0px 10px 0px" marginSm="0px 0px 10px 0px" />
      <SkeletonCustom width="97%" height="120px" diameter="4px !important" logo logoSize="50px" />
    </Wrapper>
  );
};

export default ChatBotHistorySkeletonComponent;
