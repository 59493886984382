import React from 'react';

type Props = {
  color?: string;
  onClick?: () => void;
} & typeof defaultProps;

const defaultProps = {
  color: '#6A7981',
  onClick: () => { },
};

const ExpandArrowOutputAction = function ExpandArrowOutputAction({ color, onClick }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none" className="expand-arrow-output-action-svg" onClick={onClick}>
      <path d="M1 9L5 5L1 1" stroke={color} strokeWidth="1.14286" strokeLinejoin="round" />
    </svg>
  );
};

ExpandArrowOutputAction.defaultProps = defaultProps;
export default ExpandArrowOutputAction;
