import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  secondaryFont,
  wMBold,
  primaryHeadingColor, maxWidth, retina, maxMobile,
} from '../../../Utils/utilsStyle';

type Props = {
  children: PropTypes.ReactNodeLike
};
const HeadingWrapper = styled.span`
  font-family: ${secondaryFont};
  font-size: 28px;
  font-weight: ${wMBold};
  line-height: 47px;
  color: ${primaryHeadingColor};
  letter-spacing: -0.7px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 20px;
    line-height: 34px;
  }
  @media all and (max-width: ${maxMobile}){
    font-size: 16px;
    line-height: 20px;
    padding-left: 10px;
  }
`;

const SectionHeading = function SectionHeading(props: Props) {
  const { children } = props;
  return (
    <HeadingWrapper className="">{children}</HeadingWrapper>
  );
};

export default SectionHeading;
