import React from 'react';

const GilroyWhiteSvg = function GilroyWhiteSvg() {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="gilroy-white-svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M29.2901 13.7081L30.617 13.7035C31.9804 13.7079 33.0925 14.8208 33.0981 16.1906L33.0972 19.6831C33.1028 21.0529 31.9835 22.1749 30.6238 22.1869L29.2969 22.1915L29.3018 23.251C29.3007 25.7433 27.2891 27.8072 24.7804 27.8097L12.0224 27.8273C9.54016 27.8324 7.49902 25.8071 7.49283 23.282L7.48788 22.2225L6.16099 22.2271C4.79757 22.2226 3.68553 21.1098 3.67993 19.74L3.68085 16.2475C3.67525 14.8777 4.79448 13.7556 6.15421 13.7437L7.4811 13.7391L7.47615 12.6795C7.47733 10.1873 9.48893 8.12334 11.9976 8.12084L17.3846 8.11049L17.3775 5.11995C17.3809 4.5587 17.8426 4.11878 18.361 4.12317C18.9222 4.12656 19.3457 4.59201 19.3514 5.11674L19.3586 8.10728L24.7456 8.09694C27.2278 8.09175 29.2689 10.1171 29.2751 12.6422L29.2801 13.7017L29.2901 13.7081ZM5.6428 19.6875C5.64345 19.9978 5.88938 20.2099 6.16682 20.2166L7.49372 20.212L7.487 15.6865L6.1601 15.6911C5.84982 15.6918 5.6377 15.9377 5.64104 16.2215L5.6428 19.6875ZM27.347 23.2241C27.3509 24.6632 26.2026 25.8091 24.7798 25.8093L12.0219 25.8269C10.5991 25.8271 9.45326 24.6787 9.44934 23.2396L9.44003 12.67C9.43611 11.2308 10.5845 10.0849 12.0072 10.0847L24.7652 10.0672C26.1879 10.0669 27.3338 11.2153 27.3377 12.6545L27.347 23.2241ZM30.6243 20.2294C30.9345 20.2287 31.1467 19.9828 31.1433 19.699L31.1442 16.2065C31.1436 15.8963 30.8977 15.6841 30.6202 15.6774L29.2933 15.682L29.2974 20.234L30.6243 20.2294Z" fill="#1EC252" />
      <path d="M14.4922 17.3202C15.6225 17.31 16.5304 16.3801 16.5201 15.2433C16.5098 14.1065 15.5852 13.1932 14.4549 13.2034C13.3247 13.2137 12.4168 14.1435 12.4271 15.2803C12.4374 16.4172 13.362 17.3304 14.4922 17.3202Z" fill="#1EC252" />
      <path d="M22.2812 17.2498C23.4115 17.2396 24.3194 16.3097 24.3091 15.1729C24.2988 14.0361 23.3742 13.1228 22.2439 13.133C21.1137 13.1433 20.2058 14.0732 20.2161 15.21C20.2264 16.3468 21.151 17.2601 22.2812 17.2498Z" fill="#1EC252" />
      <path d="M22.6201 19.2314C22.1215 19.0193 21.5342 19.2426 21.3222 19.7412C20.9305 20.6187 19.7551 21.2468 18.4283 21.2623C17.1015 21.2778 15.9133 20.6649 15.5028 19.793C15.2823 19.2915 14.6978 19.0821 14.1963 19.3026C13.7049 19.5295 13.4893 20.124 13.7161 20.6154C14.4397 22.2005 16.3415 23.2432 18.4439 23.2197C20.5464 23.1963 22.4309 22.1264 23.1199 20.5231C23.332 20.0244 23.1087 19.4372 22.6163 19.215L22.6201 19.2314Z" fill="#1EC252" />
    </svg>
  );
};
export default GilroyWhiteSvg;
