import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  margin: 0px 10px 25px 10px;
  .person-skeleton-wrapper {
    display: flex;
    flex-direction: column;
   .person-box {
    width: 100%;
    height: 110px;
    border-radius: 13px;
    margin: 0px auto 25px auto;
    @media all and (max-width: 1440px){
      height: 110px;
    }
   }
   .title-box {
     height: 20px;
     border-radius: 25px;
     width: 80%;
     margin-bottom: 9px;
     @media all and (max-width: 1439px), (max-height: 729px), (-webkit-device-pixel-ratio: 1.25) {
      margin-bottom: 5px;
    }
   }
   .title-box-short{
     width: 90%;
     height: 20px;
     border-radius: 25px;
     margin-bottom: 12px;
   }
  }
  .skeleton-box {
    height: 20px;
    border-radius: 25px;
    width: 50px;
  }
  .btn {
    border-radius: 25px;
    width: 94px;
    height: 40px;
    margin: 0 auto;
  }
`;

const CompanySkeleton = function CompanySkeleton() {
  return (
    <Wrapper className="company-skeleton">
      <div className="person-skeleton-wrapper">
        <span className="skeleton-box person-box" />
        <span className="skeleton-box title-box" />
        <span className="skeleton-box title-box-short" />
        <span className="skeleton-box btn" />
      </div>
    </Wrapper>
  );
};

export default CompanySkeleton;
