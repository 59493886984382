import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  COMPANIES, COMPANIES_ROUTE, COMPANY, DOCUMENT, FEED,
  FEED_GILROY_ROUTE, FEED_SPOTLIGHT_ROUTE, GROUPDETAILS, INDUSTRIES, INDUSTRY,
  NOTABLESTAFF, PEOPLE, PERSON, SERVICELINE, SRI,
} from 'Utils/Constants';
import {
  isEnterpriseUser, isGenpact, isSalesforceRoute, IsTablet, valueToObject,
} from 'Utils/UtilityFunctions';
import {
  maxTablet,
  primaryColor, primaryFont, primaryHeadingColor, wMedium,
} from 'Utils/utilsStyle';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import {
  selectActivetype, selectIsHighlighted, selectIsScroll,
} from 'Selector/CertificationSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowChatbot, selectShowDocumentHub, selectShowGlobalGilroy,
} from 'Selector/GlobalSearchContentSelector';
import { actions as chatbotV2Actions } from 'Slice/ChatbotV2Slice';
import { actions as UiAction } from 'Slice/UISlice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { actions as docActions } from 'Slice/DocumentHubSlice';
import CompaniesSvg from '../assets/CompaniesSvg';
import FeedSvg from '../assets/FeedSvg';
import IndustriesSvg from '../assets/IndustriesSvg';
import PeopleSvg from '../assets/PeopleSvg';
import ServiceLinesSvg from '../assets/ServiceLinesSvg';
import SRISvg from '../assets/SRISvg';
import SRISvgAside from '../assets/SRISvgAside';
import DocumentSvg from '../assets/DocumentSvg';

const Nav = styled.span< { isGenpact: boolean }>`
  .item {
    width: 60px;
    height: 50px;
    display: flex;
    flex-flow: column;
    font-weight:${wMedium};
    font-family:${primaryFont};
    font-size: 10px;
    line-height: 10px;
    color: ${primaryHeadingColor};
    border-radius: 6px;
    padding: 5px 12px;
    margin: 25px 7px;
    @media all and (max-height:820px) {
      margin: ${(props) => (props.isGenpact ? '15px 7px' : '25px 7px')}; 
    }
    @media all and (max-width: ${maxTablet}) {
      position: relative;
    }
    transition: 0.5s ease all;
    text-align: center;
    cursor:pointer;
    position:relative;
    transition: 0.5s;
    &:nth-child(3), &:nth-child(4), &:nth-child(5) {
      span {
        @media all and (max-width: ${maxTablet}) {
          left: 105px;
        }
      }
    }
    &.active, &:hover {
      background: #ebf5ee;
      span {
        opacity:1; 
      }
      svg {
       path,g {
         fill: ${primaryColor};
       }
      }
      .sales-sigma-svg {
        path,g {
          fill: ${primaryColor};
          stroke: ${primaryColor};
        }
      }
      .document-svg {
         path,g {
          fill: ${primaryColor};
          stroke: ${primaryColor};
        }
      }
    }
    &.serviceLines {
      height: 72px;
      @media all and (max-width: ${maxTablet}) {
        height: 50px;
        span {
          white-space: pre;
          left: 115px;
        }
      }
      svg {
        height: 23px;
        margin-top: 5px;
        @media all and (max-width: ${maxTablet}) {
          margin-top: 10px;
        }
      }
      span {
        margin-top: 13px;
      }
    }
    span {
      position: absolute;
      top: 28px;
      left: 50%;
      transform: translateX(-50%);
      opacity:0;
      transition: 0.5s;
      @media all and (max-width: ${maxTablet}) {
        top: 10px;
        left: 95px;
        font-weight: ${wMedium};
        line-height: 16px;
        letter-spacing: -0.11753px;
        color: ${primaryHeadingColor};
        font-size: 16px;
        opacity:1;
      }
      &.sales-sigma {
        svg {
          @media all and (max-width: ${maxTablet}) {
            margin-top: 5px;
            margin-left: 5px;
          }
        }
      }
    }
    svg {
      height: 17px;
      width: auto;
      margin-top: 3px;
      transition: 0.5s;
      @media all and (max-width: ${maxTablet}) {
        margin-top: 10px;
      }
      path,g{transition: 0.5s;}
      &:hover{
        color: ${primaryColor};
      }
    }
  }
`;

const NavigationLinks = function NavigationLinks() {
  const { pathname } = window.location;
  const showDocumentHub = useSelector(selectShowDocumentHub);
  const isEnterprise = isEnterpriseUser();
  const dispatch = useDispatch();
  const chatbotAllowed = useSelector(selectShowChatbot);
  const showGlobalGilroy = useSelector(selectShowGlobalGilroy);
  const currentType = useSelector(selectActivetype);
  const isScroll = useSelector(selectIsScroll);
  const isHighlighted = useSelector(selectIsHighlighted);
  const host = window.location.href;

  useEffect(() => {
    if (!isHighlighted && currentType === 'document_hub_panel') {
      dispatch(certificationActions.setHighlight(true));
      dispatch(certificationActions.setIsHighlighted(false));
      dispatch(certificationActions.setIsScroll(false));
    }
  }, [isScroll, currentType]);

  return (
    <Nav isGenpact={isGenpact()}>
      <Link
        to={chatbotAllowed && showGlobalGilroy ? FEED_GILROY_ROUTE : FEED_SPOTLIGHT_ROUTE}
        className={classNames(
          'item',
          { active: pathname.includes(FEED) || pathname === '/' },
        )}
      >
        <FeedSvg />
        <span className="mt-c-6">Feed</span>
      </Link>
      <Link
        to="/people"
        className={classNames('item', {
          active: pathname.includes(PEOPLE)
            || pathname.includes(PERSON)
            || pathname.includes(GROUPDETAILS),
        })}
      >
        <PeopleSvg />
        <span className="mt-c-6">People</span>
      </Link>
      <Link
        to={COMPANIES_ROUTE}
        className={classNames('item', {
          active: pathname.includes(COMPANY)
            || pathname.includes(COMPANIES) || pathname.includes(NOTABLESTAFF),
        })}
      >
        <CompaniesSvg />
        <span className="mt-c-6">Companies</span>
      </Link>
      <Link
        to="/industries"
        className={classNames('item', {
          active: pathname.includes(INDUSTRIES)
            || pathname.includes(INDUSTRY),
        })}
      >
        <IndustriesSvg />
        <span className="mt-c-6">Industries</span>
      </Link>
      {showDocumentHub && isEnterprise ? (
        <Link
          to="/documents"
          onClick={() => {
            dispatch(UiAction.isShowGilroyGlobalToggle(false));
            dispatch(chatbotV2Actions.setShowLibrary(true));
            dispatch(chatbotV2Actions.setShowExamplePrompts(false));
            dispatch(chatbotV2Actions.resetChatHistory());
            dispatch(chatbotV2Actions.setSelectedConversationId(-1));
            if (!host.includes(DOCUMENT)) {
              dispatch(docActions.setSearchText(''));
              dispatch(docActions.resetSelectedDocuments(false));
            }
            dispatch(flurryActions.callFlurryEvent(
              valueToObject('documentHub_sidePanel_clicked', 0, {
                host,
              }),
            ));
          }}
          className={classNames('item', {
            active: pathname.includes(DOCUMENT)
              || pathname.includes(DOCUMENT),
          })}
        >
          <DocumentSvg />
          <span className="mt-c-6">Document</span>
        </Link>
      ) : null}
      {IsTablet() || isSalesforceRoute() ? null : (
        <Link
          to="/sri/overview"
          className={classNames('item', { active: pathname.includes(SRI) })}
        >
          <SRISvgAside />
          <span className="mt-c-6 sales-sigma">
            Sales
            <strong><SRISvg /></strong>
          </span>
        </Link>
      )}
      {isGenpact() ? (
        <Link
          to="/servicelines"
          className={classNames('item serviceLines', { active: pathname.includes(SERVICELINE) })}
        >
          <ServiceLinesSvg />
          <span className="mt-c-6">
            Service Lines
          </span>
        </Link>
      ) : null}
    </Nav>
  );
};

export default NavigationLinks;
