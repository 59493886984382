import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isGuest } from 'Utils/UtilityFunctions';
import { actions } from 'Slice/GlobalSearchContentSlice';
import { useDispatch } from 'react-redux';
import { SEARCH_ROUTE } from 'Utils/Constants';
import {
  SecondaryColor, secondaryFont, wMBold, maxWidth, retina, maxTablet, maxMobile,
} from '../../../Utils/utilsStyle';

const Tag = styled.span<{ color: string, marginY: string, marginYSm: string, marginRight: string }>`
  background-color : ${(props) => props.color};
  border: 1px solid #DADADA;
  display: inline-block;
  padding: 4px 18px 4px 10px;
  border-radius: 25px;
  margin-right: ${(props) => props.marginRight};
  margin-top: ${(props) => props.marginY};
  margin-bottom: ${(props) => props.marginY};
  @media all and (max-width: ${maxWidth}) , ${retina} {
    padding: 2px 18px 2px 10px;
    margin-top: ${(props) => props.marginYSm};
    margin-bottom: ${(props) => props.marginYSm};
  }
  @media all and (max-width: ${maxTablet}) {
    padding: 2px 15px 2px 5px;
    display: inline-flex;
    white-space: pre;
  }
  &.forGuestTip {
    cursor: none;
    padding: 8px 18px;
    display: inline-flex;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 4px 18px;
    }
    .tip-text {
      cursor: auto;
    }
  }
  &:last-child {
    margin-right:0px;
  }
  .tip-text{
   color: ${SecondaryColor};
   font-size: 14px;
   line-height: 16px;
   font-weight: ${wMBold};
   font-family: ${secondaryFont};
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 10px;
    }
    @media all and (max-width: ${maxMobile}) {
      font-size: 8px;
    }
  }
  .anticon-search {
    padding-right: 7px;
    border-right: 1px solid #DADADA;
    margin-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      padding-right: 3px;
      margin-right: 4px;
      svg{
        font-size:12px;
      }
    }
    @media all and (max-width: ${maxMobile}) {
      svg{
        font-size: 10px;
      }
    }
  }
  svg {
    font-size: 15px;
    path {
      fill: ${SecondaryColor};
    }
  }
  &:hover {
    cursor: pointer;
  }
  
`;

type Props = {
  color?: string,
  title: string,
  marginTopBottom?: string,
  marginTopBottomSm?: string,
  marginRight?: string,
} & typeof defaultProps;

const defaultProps = {
  color: '#E7EDFF',
  marginTopBottom: '5px',
  marginTopBottomSm: '5px',
  marginRight: '5px',
};

const TagTip = function TagTip(props: Props) {
  const {
    title, color, marginTopBottom, marginTopBottomSm, marginRight,
  } = props;
  const dispatch = useDispatch();
  const clickRecentSearch = (search: string) => {
    dispatch(actions.updateRecentSearch(search));
  };
  return (
    <Tag
      className={classNames('tag-tip', { forGuestTip: isGuest() })}
      color={color}
      marginY={marginTopBottom}
      marginYSm={marginTopBottomSm}
      marginRight={marginRight}
    >
      {isGuest() ? <span className="tip-text">{title}</span>
        : (
          <>
            <SearchOutlined />
            <Link
              onClick={() => clickRecentSearch(title.replace('#', ''))}
              to={`${SEARCH_ROUTE}${title.replace('#', '')}`}
              state={{ enableBackButton: true, query: title }}
              className="tip-text"
            >
              {title}
            </Link>
          </>
        )}
    </Tag>
  );
};

TagTip.defaultProps = defaultProps;
export default TagTip;
