import React from 'react';

type Props = {
  color?: string,
  isMob?: boolean,
};
const defaultProps = {
  color: '#001760',
  isMob: false,
};

const ToggleIconOpen = function ToggleIconOpen(props: Props) {
  const { color, isMob } = props;
  return (
    <div>
      {isMob ? (
        <svg width="14" height="52" viewBox="0 0 14 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_1146_8966)">
            <path d="M0 13C0 9.68629 2.68629 7 6 7H12V45H6C2.68629 45 0 42.3137 0 39V13Z" fill="white" />
          </g>
          <path d="M3.00088 26.0375C3.00906 26.2825 3.10777 26.516 3.27823 26.6936L8.11931 31.6926C8.50574 32.0912 9.14497 32.1038 9.54707 31.7207C9.94916 31.3377 9.96186 30.704 9.57543 30.3054L5.40882 26L9.57543 21.6946C9.96186 21.296 9.94916 20.6623 9.54707 20.2793C9.14497 19.8962 8.50574 19.9088 8.11931 20.3074L3.27823 25.3064C3.08961 25.5027 2.98951 25.7666 3.00088 26.0375Z" fill="#001760" />
          <defs>
            <filter id="filter0_d_1146_8966" x="-12" y="0" width="26" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1146_8966" />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1146_8966" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1146_8966" result="shape" />
            </filter>
          </defs>
        </svg>

      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="59" viewBox="0 0 51 59" fill="none">
          <g filter="url(#filter0_d_603_129122)">
            <path d="M14 14.6C14 10.9549 16.9549 8 20.6 8H48.1V50.9H20.6C16.9549 50.9 14 47.9451 14 44.3V14.6Z" fill="white" />
          </g>
          <path d="M27.2011 28.9473C27.2116 29.2618 27.3383 29.5614 27.5571 29.7894L33.7698 36.2047C34.2657 36.7163 35.086 36.7324 35.6021 36.2408C36.1181 35.7492 36.1344 34.936 35.6385 34.4244L30.2913 28.8992L35.6385 23.374C36.1344 22.8624 36.1181 22.0492 35.6021 21.5576C35.086 21.066 34.2657 21.0822 33.7698 21.5937L27.5571 28.0091C27.315 28.261 27.1865 28.5997 27.2011 28.9473Z" fill={color} />
          <defs>
            <filter id="filter0_d_603_129122" x="0.8" y="0.3" width="49.5" height="58.2984" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feMorphology radius="1.1" operator="erode" in="SourceAlpha" result="effect1_dropShadow_603_129122" />
              <feOffset dx="-5.5" />
              <feGaussianBlur stdDeviation="4.4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_603_129122" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_603_129122" result="shape" />
            </filter>
          </defs>
        </svg>
      )}
    </div>
  );
};

ToggleIconOpen.defaultProps = defaultProps;
export default ToggleIconOpen;
