import { primaryColor } from 'Utils/utilsStyle';
import React from 'react';
import styled from 'styled-components';

type Props = {
  color?: string;
  onClick?: () => void
} & typeof defaultProps;

const defaultProps = {
  color: '#667982',
  onClick: () => { },
};

const SVG = styled.svg`
  &:hover {
    path {
      fill: ${primaryColor};
    }
  }
`;

const CopyMailIcon = function CopyMailIcon({ color, onClick }: Props) {
  return (
    <SVG
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="copyIcon"
      id="copy-gpt-output-am"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11.4969 1H2.39529C1.19183 1 0.213867 1.97791 0.213867 3.18142V12.283C0.213867 13.4489 1.19178 14.4269 2.39529 14.4269H3.82452V15.8561C3.82452 17.0596 4.76466 18 5.9684 18H15.07C16.2734 18 17.2139 17.0599 17.2139 15.8561V6.75453C17.2139 5.55108 16.2737 4.61066 15.07 4.61066H13.6408V3.18142C13.6408 1.97797 12.6628 1 11.4969 1ZM5.59222 14.4265H11.4969C12.6628 14.4265 13.6408 13.4486 13.6408 12.2827V6.37801H15.07C15.1829 6.37801 15.258 6.41557 15.3333 6.4909C15.4086 6.56622 15.4462 6.64133 15.4462 6.75421V15.8558C15.4462 15.9687 15.4086 16.0438 15.3333 16.1191C15.258 16.1944 15.1829 16.232 15.07 16.232H5.9684C5.85552 16.232 5.78041 16.1944 5.70509 16.1191C5.62976 16.0438 5.59221 15.9687 5.59221 15.8558L5.59222 14.4265ZM2.39535 2.80496H11.4969C11.6098 2.80496 11.6849 2.84251 11.7602 2.91784C11.8356 2.95539 11.8731 3.06827 11.8731 3.18116V12.2827C11.8731 12.3956 11.8356 12.4707 11.7602 12.5461C11.6849 12.6214 11.6098 12.6589 11.4969 12.6589H2.39535C2.28246 12.6589 2.16958 12.6214 2.13203 12.5461C2.0567 12.4707 2.01915 12.3956 2.01915 12.2827V3.18116C2.01915 3.06827 2.0567 2.95539 2.13203 2.91784C2.16958 2.84251 2.28246 2.80496 2.39535 2.80496Z" fill={color} stroke="white" strokeWidth="0.3" />
    </SVG>

  );
};

CopyMailIcon.defaultProps = defaultProps;
export default CopyMailIcon;
