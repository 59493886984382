import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/GroupsSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.groups || initialState;
export const selectTitle = createSelector([selectDomain], (state) => state.title);
export const selectGroupCount = createSelector([selectDomain], (state) => state.groupCount);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.mostActiveGroupsLoading,
);
export const selectBuyerGroupsLoading = createSelector(
  [selectDomain],
  (state) => state.buyerGroupsLoading,
);
export const selectGroups = createSelector([selectDomain], (state) => state.mostActiveGroups);
export const selectAllLoading = createSelector(
  [selectDomain],
  (state) => state.allGroupsLoading,
);
export const selectAllGroups = createSelector([selectDomain], (state) => state.allGroups);
export const selectGroupsCount = createSelector([selectDomain], (state) => state.groupsCount);
export const selectSearchedGroupsCount = createSelector(
  [selectDomain],
  (state) => state.searchedGroupsCount,
);
export const selectSearchedGroups = createSelector([selectDomain], (state) => state.searchedGroups);
export const selectEndedGroups = createSelector([selectDomain], (state) => state.groupsEnded);
export const selectEndedSearchedGroups = createSelector(
  [selectDomain],
  (state) => state.searchedGroupsEnded,
);
export const selectDeletingLoader = createSelector([selectDomain], (state) => state.deletingLoader);
export const selectCreatingLoader = createSelector(
  [selectDomain],
  (state) => state.creatingGroupLoader,
);
export const selectNewGroupId = createSelector(
  [selectDomain],
  (state) => state.newlycreatedGroupId,
);
export const selectNotification = createSelector(
  [selectDomain],
  (state) => state.notification,
);
export const selectCreateNewGroupId = createSelector(
  [selectDomain],
  (state) => state.newGroupId,
);
export const selectCreateMessage = createSelector(
  [selectDomain],
  (state) => state.message,
);
export const selectAddPersonLoading = createSelector(
  [selectDomain],
  (state) => state.addingPerson,
);
export const selectGroupCreateSuccessfully = createSelector(
  [selectDomain],
  (state) => state.groupCreateSuccessfully,
);
export const selectDropComplete = createSelector(
  [selectDomain],
  (state) => state.dropComplete,
);
export const selectDeleted = createSelector(
  [selectDomain],
  (state) => state.deleted,
);
export const selectExecutivesLoading = createSelector(
  [selectDomain],
  (state) => state.executivesLoading,
);
export const selectOrganizationName = createSelector(
  [selectDomain],
  (state) => state.organizationName,
);
export const selectOrganizationExecutives = createSelector(
  [selectDomain],
  (state) => state.organizationExecutives,
);
export const selectExecutiveCount = createSelector(
  [selectDomain],
  (state) => state.totalExecutives,
);
export const selectExecutivesSearched = createSelector(
  [selectDomain],
  (state) => state.executivesSearched,
);
export const selectExecutivesPage = createSelector(
  [selectDomain],
  (state) => state.executivesPage,
);
export const selectSearchedPage = createSelector(
  [selectDomain],
  (state) => state.searchedPage,
);
export const selectExecutivesEnded = createSelector(
  [selectDomain],
  (state) => state.executivesEnded,
);
export const selectSearchedEnded = createSelector(
  [selectDomain],
  (state) => state.searchedEnded,
);
export const selectSearchedCount = createSelector(
  [selectDomain],
  (state) => state.searchedCount,
);
export const selectSelectedGroups = createSelector(
  [selectDomain],
  (state) => state.selectedGroups,
);
export const selectSelectedExecutives = createSelector(
  [selectDomain],
  (state) => state.selectedExeutives,
);
export const selectGroupShared = createSelector(
  [selectDomain],
  (state) => state.groupShared,
);
export const selectShareLoading = createSelector(
  [selectDomain],
  (state) => state.shareLoading,
);
export const selectShowMergePopup = createSelector(
  [selectDomain],
  (state) => state.showMergePopup,
);
export const selectAllGroupsLoaded = createSelector(
  [selectDomain],
  (state) => state.allGroupsLoaded,
);
export const selectIsDuplication = createSelector(
  [selectDomain],
  (state) => state.isDuplication,
);

export const selectExecutivesLoaded = createSelector(
  [selectDomain],
  (state) => state.executivesLoaded,
);
export const selectGroupDrawerOpen = createSelector(
  [selectDomain],
  (state) => state.groupDrawerOpen,
);
