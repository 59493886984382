import {
  maxMobile,
  maxTablet,
  maxWidth,
  maxWidth1300,
  primaryFont, primaryHeadingColor, retina,
  wMedium,
} from 'Utils/utilsStyle';
import React, {
  Suspense, lazy,
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'Slice/ChatbotV2Slice';
import {
  selectAiCertificationLoading,
  selectIsAiCertificationCompleted,
} from 'Selector/CertificationSelector';
import { selectSelectedPromptCateogry, selectShowExamplePrompts } from 'Selector/ChatBotV2Selector';
import { showCertificationInApp } from 'Utils/UtilityFunctions';
import classNames from 'classnames';

import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import BackButtonSecondaryHeader from '../BackButtonSecondaryHeader';
import LibraryCategoryCmpSkeleton from './component/LibraryCategoryCmpSkeleton';
import GetCertifiedButton from '../GetCertifiedButton';

const LibraryPromptSlider = lazy(() => import('./component/LibraryPromptSlider'));

const StyledWrapper = styled.div`
position: relative;
height: 100%;
width: 100%;
z-index: 0;
.get-certified-ai-button-wrapper {
  position: absolute;
  top: 6px;
  right: 44px;
  padding: 6px 16px 6px 12px;
  height: 30px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    top: 9px;
  }
  @media all and (max-width: ${maxWidth1300}) {
    top: 8px;
  }
}
.heading-div-library {
  display: inline-flex;
  align-items: center;
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.792px;
  color: ${primaryHeadingColor};
  padding-top: 12px;
  margin-bottom: 14px;
  padding-left: 8px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    line-height: 22px;
  }
  @media all and (max-width: ${maxTablet}) {
    padding: 8px 0px 12px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
  }
  @media all and (max-width: ${maxMobile}) {
    padding: 9px 0px 12px;
  }
}
`;

type Props = {
  setInputText: (a: string) => void,
  isGilroyV3?: boolean,
};
const defaultProps = {
  isGilroyV3: false,
};

const ChatBotLibrary = function ChatBotLibrary(props: Props) {
  const { setInputText, isGilroyV3 } = props;
  const dispatch = useDispatch();
  const selectedPromptCategory = useSelector(selectSelectedPromptCateogry);
  const showExamplePrompts = useSelector(selectShowExamplePrompts);
  const aiLoading: boolean = useSelector(selectAiCertificationLoading);
  const isAiCertificationCompleted = useSelector(selectIsAiCertificationCompleted);
  const certificationAllowed = showCertificationInApp();
  const disclessPersonality = useSelector(selectDisclessPersonality);

  return (
    <StyledWrapper className={classNames('chatbot-library', { notLibraryExample: !showExamplePrompts })}>
      {showExamplePrompts ? (
        <BackButtonSecondaryHeader
          title={selectedPromptCategory}
          onClick={() => dispatch(actions.setShowExamplePrompts(false))}
        />
      ) : (
        <>
          <span className="heading-div-library">
            Explore our vast Prompt Library
          </span>
          {!isAiCertificationCompleted && certificationAllowed && !aiLoading
            && !isGilroyV3 && disclessPersonality
            ? <GetCertifiedButton /> : null}
        </>
      )}
      <Suspense
        fallback={
          <LibraryCategoryCmpSkeleton />
        }
      >
        <LibraryPromptSlider
          showExamplePrompt={showExamplePrompts}
          setInputText={setInputText}
          isGilroyV3={isGilroyV3}
        />
      </Suspense>
    </StyledWrapper>
  );
};
ChatBotLibrary.defaultProps = defaultProps;
export default ChatBotLibrary;
