import { Menu } from 'antd';
import styled from 'styled-components';
import {
  Link,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { GROUPDETAILS, SEARCH_ROUTE } from 'Utils/Constants';
import { selectLoading, selectRecentSearches, selectTrendingSearches } from 'Selector/GlobalSearchContentSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Section } from 'Types/GlobalSearchContentTypes';
import { actions } from 'Slice/GlobalSearchContentSlice';
import { isSalesforce } from 'Utils/UtilityFunctions';
import TagTip from '../../common/components/TagTip';
import {
  primaryFont,
  wLight,
  wMedium,
  primaryBgColor,
  primaryColor,
  maxWidth,
  retina,
  maxMobile,
} from '../../../Utils/utilsStyle';

const MenuWrapper = styled.div`
  color:red;
  .m-left-50 {
    margin-left: 50px;
    @media all and (max-width: ${maxMobile}) {
     margin-left: 20px;
    }
  }
  .recent-search-text {
    color: #67696a;
    font-weight: ${wMedium};
    font-size: 18px;
    line-height: 34px;
    font-family: ${primaryFont};
    padding-top: 5px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 13px;
      line-height: 24px;
    }
  }
  .recent-search-item {
    color: #5e7079;
    font-family: ${primaryFont};
    font-weight: ${wLight};
    line-height: 30px;
    font-size: 16px;
    margin-left: 34px;
    height: auto;
    background-color: transparent !important;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      line-height: 24px;
      font-size: 12px;
    }
    @media all and (max-width: ${maxMobile}) {
     margin-left: 10px;
    }

    &:focus {
      background-color: transparent;
    }
    a {
      &:hover {
        color: ${primaryColor}
      }
    }
  }
  .trending-topics-menu {
    background-color: ${primaryBgColor};
  }
  .padded-heading{
    padding: 5px 0px 10px 0px;
    border-radius: 0px 0px 25px 25px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      padding: 5px 0px 10px 0px;
      border-radius: 0px 0px 15px 15px;
    }
  }
  .ant-menu-item-divider {
    margin: 0px;
    /* border-width: 2px 0 0; */
    border:none;
  }
`;

type Props = {
  getScreenNameFun: () => void;
};

export const GlobalSearchMenu = function GlobalSearchMenu(props: Props) {
  const dispatch = useDispatch();
  const { getScreenNameFun } = props;
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId') || '';
  const groupIdParsed = groupId ? parseInt(groupId, 10) : 0;
  const loading: boolean = useSelector(selectLoading);
  const recentSearches: Section = useSelector(selectRecentSearches) as Section;
  const trendingSearches: Section = useSelector(selectTrendingSearches) as Section;
  const reversedSearches = recentSearches.searches ? [...recentSearches.searches].reverse() : [];
  // const dummyTrendingSearches: string[] = ['Metaverse', 'NFTs', 'DAOs'];

  const clickRecentSearch = (search: string) => {
    getScreenNameFun();
    dispatch(actions.updateRecentSearch(search));
  };
  const route = (search: string) => {
    let path = '';

    if (!isSalesforce()) {
      path = `${SEARCH_ROUTE}${search?.replace(/&/g, '%26')}`;
      if (pathname.includes(GROUPDETAILS) && groupId && parseInt(groupId, 10) > 0) {
        path = `${path}&groupId=${groupId}`;
      }
    } else {
      const section = searchParams.get('extend') || '';
      path = `${SEARCH_ROUTE}${search?.replace(/&/g, '%26')}&extend=${section}`;
    }
    return path;
  };
  return (
    loading === true ? <> </>
      : (
        <MenuWrapper>
          <Menu>
            {reversedSearches?.length
              ? (
                <div className="recent-search-text m-left-50">
                  {`${recentSearches.title}:`}
                </div>
              ) : null}
            {reversedSearches.map((search) => (
              <Menu.Item className="recent-search-item ">
                <Link
                  onClick={() => clickRecentSearch(search)}
                  to={route(search)}
                  state={!isSalesforce() ? {
                    enableBackButton: true,
                    query: search,
                    ...(pathname.includes(GROUPDETAILS)

                      && groupIdParsed > 0 && { group_id: groupIdParsed }),
                  } : {}}
                >
                  {search}
                </Link>
              </Menu.Item>
            ))}
            <Menu.Divider />
          </Menu>

          <Menu className="trending-topics-menu padded-heading">
            {isSalesforce() ? null : (
              <>
                <div className="recent-search-text m-left-50">
                  {`${trendingSearches.title}:`}
                </div>
                <div className="m-left-50">
                  {
                    trendingSearches.searches
                    && trendingSearches.searches.map((topic: string) => <TagTip title={topic} />)
                  }
                </div>
              </>
            )}

            <Menu.Divider />
          </Menu>

        </MenuWrapper>
      )
  );
};
