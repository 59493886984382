import PeopleWidgetWithOutHolo from 'Components/common/components/PeopleWidgetWithOutHolo';
import { translateSkipAreas } from 'Utils/Constants';
import { getLocalStorageValue } from 'Utils/UtilityFunctions';
import {
  SecondaryColor, maxTablet, primaryFont, wLight, wMBold,
} from 'Utils/utilsStyle';
import HTMLReactParser from 'html-react-parser';
import React from 'react';
import styled from 'styled-components';

type Props = {
  queryTitle?: string
};

const defaultProps = {
  queryTitle: '',
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 22px 20px 22px 14px;
  background: #F9F8F6;
  border-radius: 8px;
  @media all and (max-width: ${maxTablet}) {
    padding: 8px 14px 8px 14px;
  }
  .left-side {
    .person-without-holo {
      @media all and (max-width: ${maxTablet}) {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin: 1.5px 0px;
        padding: 1px !important;
      }
    }
  }
  .right-side {
    font-family: ${primaryFont};
    font-weight: ${wLight};
    font-size: 16px;
    line-height: 24px;
    color: #0D0D0D;
    margin-left: 15px;
    word-break: break-word;
    margin-top: 8px;
    @media all and (max-width: ${maxTablet}) {
      font-size: 12px;
      line-height: 24px;
      margin-left: 5px;
      padding-left: 3px;
    }
    span , .prompt-input-style {
      color: ${SecondaryColor};
      font-family: ${primaryFont};      
      font-weight: ${wMBold};
      font-style: italic;
      &.highlight {
        background: unset;
        border: 0;
      }
    }
  }
`;

const UserQuery = function UserQuery(props: Props) {
  const { queryTitle } = props;
  const profilePic = getLocalStorageValue('profilePic');
  const personalityColor = getLocalStorageValue('personalitycolors');

  return (
    <Wrapper className="query-js">
      <div className="left-side">
        <PeopleWidgetWithOutHolo
          logo={profilePic || ''}
          diameter="35px"
          diameterSm="35px"
          diameterXsm="35px"
          ringPadding="2px"
          ringPaddingSm="2px"
          margin="0px 3px 0px"
          wrapperColor={personalityColor || ''}
        />
      </div>
      <div className={`right-side ${translateSkipAreas}`}>
        {HTMLReactParser(queryTitle || '')}
      </div>
    </Wrapper>
  );
};

UserQuery.defaultProps = defaultProps;
export default UserQuery;
