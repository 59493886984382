import Empty from 'Components/common/assets/svgIcons/Empty';
import { selectConversationSections, selectConversationsLoading } from 'Selector/ChatBotV2Selector';
import {
  maxWidth,
  primaryColor, primaryHeadingColor, retina, secondaryFont, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledWrapper = styled.span`
  &.notActive{
    background-color: #DEF6E6;
    color: ${primaryHeadingColor};
    font-weight: ${wMedium};
    svg {
      path {
        fill: ${primaryHeadingColor};
      }
    }
    .history-icon {
      path {
        &:last-child {
          stroke: ${primaryHeadingColor};
        }
      }
    }
  }
  &.disabled{
    pointer-events: none;
    opacity: 0.6;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${primaryColor};
  color: #fff;
  border-radius: 6.019px;
  padding: 10px 0;
  font-family: ${secondaryFont};
  font-weight: ${wMedium};
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.4px;
  cursor: pointer;
  @media all and (max-width: ${maxWidth}), ${retina} {
    padding: 5px 0px;
    font-size: 14px;
    line-height: 20px;
  }
  svg {
    margin-right: 10px;
    path {
      fill: #fff;
    }
  }
  .history-icon {
    path {
      &:last-child {
        stroke: #fff;
      }
    }
  }
  &.highlightedComponent{
    z-index: 1001;  // to highlight increased z-index
  }
`;
type Props = {
  leftIcon?: object,
  text?: string,
  activeState?: boolean,
  onClick?: () => void,
  highlightedComponent?: boolean

} & typeof defaultProps;

const defaultProps = {
  leftIcon: <Empty />,
  text: '',
  activeState: false,
  onClick: () => { },
  highlightedComponent: false,
};

const CustomTabGilroy = function CustomTabGilroy(props: Props) {
  const {
    leftIcon, text, activeState, onClick, highlightedComponent,
  } = props;
  const conversationsLoading = useSelector(selectConversationsLoading);
  const conversations = useSelector(selectConversationSections);

  return (
    <StyledWrapper
      tabIndex={0}
      role="button"
      className={classNames(
        'gilroy-custom-tab',
        { notActive: !activeState, disabled: conversationsLoading && !conversations?.length },
        { highlightedComponent },
      )}
      onClick={onClick}
      onKeyPress={onClick}
    >
      {leftIcon}
      {text}
    </StyledWrapper>
  );
};
CustomTabGilroy.defaultProps = defaultProps;
export default CustomTabGilroy;
