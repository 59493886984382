import React from 'react';

const WhiteBotIcon = function WhiteBotIcon() {
  return (

    <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.4208 8.4011H23.5866C24.7795 8.4011 25.7555 9.38305 25.7555 10.5832V13.6382C25.7555 14.8383 24.7795 15.8203 23.5866 15.8203H22.4208V16.7477C22.4208 18.9298 20.6586 20.73 18.4626 20.73H7.29286C5.12398 20.73 3.33465 18.957 3.33465 16.7477V15.8203H2.16888C0.975996 15.8203 0 14.8383 0 13.6382V10.5832C0 9.38305 0.975996 8.4011 2.16888 8.4011H3.33465V7.47371C3.33465 5.2916 5.09687 3.49137 7.29286 3.49137H12.0102V0.872842C12.0102 0.381868 12.4168 0 12.8777 0C13.3657 0 13.7453 0.409145 13.7453 0.872842V3.49137H18.4626C20.6315 3.49137 22.4208 5.26433 22.4208 7.47371V8.4011ZM1.70916 13.6108C1.70916 13.8835 1.92605 14.0745 2.17005 14.0745H3.33582V10.1194H2.17005C1.89894 10.1194 1.70916 10.3376 1.70916 10.5831V13.6108ZM20.7133 16.7208C20.7133 17.9755 19.7102 18.9847 18.4631 18.9847H7.29339C6.04628 18.9847 5.04317 17.9755 5.04317 16.7208V7.47408C5.04317 6.21937 6.04628 5.21015 7.29339 5.21015H18.4631C19.7102 5.21015 20.7133 6.21937 20.7133 7.47408V16.7208ZM23.5851 14.1018C23.8562 14.1018 24.046 13.8835 24.046 13.6381V10.5831C24.046 10.3103 23.8291 10.1194 23.5851 10.1194H22.4193V14.1018H23.5851Z" fill="white" />
      <ellipse cx="9.46181" cy="9.71027" rx="1.78933" ry="1.80024" fill="white" />
      <ellipse cx="16.2954" cy="9.71027" rx="1.78933" ry="1.80024" fill="white" />
      <path d="M16.5646 13.2563C16.1309 13.0653 15.6158 13.2563 15.426 13.6927C15.0735 14.4564 14.0433 15.0019 12.8775 15.0019C11.7118 15.0019 10.6815 14.4564 10.3291 13.6927C10.1393 13.2563 9.62422 13.0653 9.19044 13.2563C8.75667 13.4472 8.56689 13.9654 8.75667 14.4019C9.38022 15.793 11.034 16.7204 12.8775 16.7204C14.7211 16.7204 16.3749 15.793 16.9984 14.4019C17.1882 13.9654 16.9984 13.4472 16.5646 13.2563Z" fill="white" />
    </svg>

  );
};

export default WhiteBotIcon;
