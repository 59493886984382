/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */

import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { actions as peopleFollowActions } from 'Slice/PeopleFollowSlice';
import { actions as companyActions } from 'Slice/CompaniesSlice';
import { actions as competitorsActions } from 'Slice/TopCompanyEntitySlice';
import { actions as peopleActions } from 'Slice/PeopleSlice';
import { actions as searchActions } from 'Slice/GlobalSearchResultsSlice';
import { actions as buyerGroupPageActions } from 'Slice/BuyerGroupsPageSlice';
import { actions as OnboardingActions } from 'Slice/OnboardingSlice';
import { actions as personProfileActions } from 'Slice/PersonProfileSlice';
import { API } from 'Utils/Constants';

import axios from 'Utils/Axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { PersonData, UpdatePersonProfileFollow } from 'Types/RequestBodies';
import { PeopleFollowResponse } from 'Types/PeopleFollowTypes';
import { actions as helperActions } from 'Slice/HelperSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';

export function* updateProfileFollow(dispatched: PayloadAction<UpdatePersonProfileFollow>) {
  const startTime = getCurrentTime();

  try {
    const { payload } = dispatched;
    const personData: PersonData[] = payload.person_data || [];
    const feedIds = payload.feedids;
    const data = {
      ...personData && personData.length > 0
      && { person_data: JSON.stringify(personData) },
      ...feedIds && feedIds.length > 0 && {
        feedids: JSON.stringify(payload.feedids),
      },
      ...payload.group_id && { group_id: payload.group_id },
      ...payload.type && { type: payload.type },
    };
    const response: PeopleFollowResponse = yield call(
      axios.post,
      API.UPDATE_PERSON_PROFILE_FOLLOW,
      data,
    );
    if (response?.status === true) {
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: response?.response?.groupId,
        classnameFlag: true,
      }));
      if (personData && personData.length > 0) {
        yield all([
          put(peopleActions.updateRecommendedPeopleList(personData[0]?.entity_id)),
          put(searchActions.searchFollowHandling(personData[0]?.entity_id)),
          put(companyActions.handleFollowUnfollowPeople([personData[0]?.entity_id])),
          put(OnboardingActions.setPeopleFollowed(personData[0]?.entity_id)),
          put(competitorsActions.handlePeopleFollowUnFollow([personData[0]?.entity_id])),
          put(personProfileActions.handleSimilarPeopleFollowUnfollow([personData[0]?.entity_id])),
        ]);

        yield put(helperActions.callFlurryEvent(
          valueToObject('people_follow_succeded', startTime, dispatched?.payload),
        ));
      }
      if (response?.response?.groupId && feedIds && feedIds.length > 0) {
        yield put(helperActions.callFlurryEvent(
          valueToObject('remove_member_bgroup_complete', startTime, dispatched?.payload),
        ));
      }
      if (feedIds && feedIds.length > 0) {
        yield put(personProfileActions.handleSimilarPeopleFollowUnfollow(feedIds));
        yield put(OnboardingActions.setPeopleUnFollowed(feedIds));
        // yield put(buyerGroupPageActions.resetHighlights({}));
        // yield put(buyerGroupPageActions.isAddRemovePersonInGroup(true));
        yield put(peopleActions.setPeopleRemoved(true));
        // yield put(buyerGroupPageActions.handleRemovedPeople(feedIds));
        yield put(peopleActions.filterFollowedPeople({
          feedIds,
          count: response?.response?.personCount,
        }));
        yield put(searchActions.searchUnfollowHandling(feedIds));
        yield put(companyActions.handleFollowUnfollowPeople(feedIds));
        yield put(companyActions.handleFollowUnfollowStaffIFollow(feedIds));
        yield put(competitorsActions.handlePeopleFollowUnFollow(feedIds));
      }

      yield put(peopleFollowActions.setProfileFollowSuccess());
    } else {
      yield put(peopleFollowActions.setProfileFollowFailure());
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: true,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    yield put(peopleFollowActions.setProfileFollowFailure());
  }
}

export function* peopleFollowSaga() {
  yield takeEvery(peopleFollowActions.updateProfileFollow.type, updateProfileFollow);
}
