import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/BuyerGroupsPageSlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.buyerGroupsPage || initialState;

export const selectTopPeople = createSelector([selectDomain], (state) => state.topPeople);
export const selectTopPeopleLoading = createSelector(
  [selectDomain],
  (state) => state.topPeopleLoading,
);

export const selectBuyersHighlightsLoading = createSelector(
  [selectDomain],
  (state) => state.highlightsLoading,
);

export const selectBuyersHighlights = createSelector(
  [selectDomain],
  (state) => state.highlights,
);

export const selectBuyersHighlightsPageNumber = createSelector(
  [selectDomain],
  (state) => state.highlightsPageNumber,
);

export const selectBuyersHighlightsEnded = createSelector(
  [selectDomain],
  (state) => state.highlightsEnded,
);

export const selectTitleUpdateLoading = createSelector(
  [selectDomain],
  (state) => state.titleUpdateLoading,
);

export const selectTitleUpdateLoadingForce = createSelector(
  [selectDomain],
  (state) => state.titleUpdateLoadingForce,
);

export const selectMembersPageNumber = createSelector(
  [selectDomain],
  (state) => state.membersPageNumber,
);
export const selectMembers = createSelector(
  [selectDomain],
  (state) => state.members,
);
export const selectMembersEnded = createSelector(
  [selectDomain],
  (state) => state.membersEnded,
);
export const selectMembersLoading = createSelector(
  [selectDomain],
  (state) => state.membersLoading,
);
export const selectReasonPopupLoading = createSelector(
  [selectDomain],
  (state) => state.reasonPopupLoading,
);
export const selectMembersInitialLoading = createSelector(
  [selectDomain],
  (state) => state.membersInitialLoading,
);

export const selectMembersFilters = createSelector(
  [selectDomain],
  (state) => state.filtersArray,
);

export const selectMembersFiltersLoading = createSelector(
  [selectDomain],
  (state) => state.filtersArrayLoading,
);

export const selectPeopleRemovedFlag = createSelector(
  [selectDomain],
  (state) => state.peopleRemovedFlag,
);
export const selectNotMembers = createSelector(
  [selectDomain],
  (state) => state.noGroupsMembers,
);
export const selectNotMembersLoad = createSelector(
  [selectDomain],
  (state) => state.noGroupsMembersLoad,
);

export const selectNoMembersPage = createSelector(
  [selectDomain],
  (state) => state.noMembersPage,
);

export const selectAddRemovePersonInGroup = createSelector(
  [selectDomain],
  (state) => state.isAddRemovePerson,
);
export const selectFilterArray = createSelector(
  [selectDomain],
  (state) => state.selectedFiltersArray,
);
export const selectIsError = createSelector(
  [selectDomain],
  (state) => state.isError,
);

export const selectGroupDeleted = createSelector(
  [selectDomain],
  (state) => state.groupDeleted,
);
export const selectPersonRemoved = createSelector(
  [selectDomain],
  (state) => state.personRemoved,
);
export const selectPeopleDrawerOpen = createSelector(
  [selectDomain],
  (state) => state.peopleDrawerOpen,
);
export const selectTotalMembers = createSelector(
  [selectDomain],
  (state) => state.totalMembers,
);

export const selectChatgptAllowed = createSelector(
  [selectDomain],
  (state) => state.topPeople.chatgptAllowed === 1,
);

export const selectRecommendedPeople = createSelector(
  [selectDomain],
  (state) => state.recommendedPeople,
);

export const selectRecommendedPeopleCount = createSelector(
  [selectDomain],
  (state) => state.recomendedpeopleCount,
);

export const selectFollowedPeopleCount = createSelector(
  [selectDomain],
  (state) => state.followedPeopleCount,
);

export const selectRecommendedEnded = createSelector(
  [selectDomain],
  (state) => state.recommendedEnded,
);

export const selectType = createSelector(
  [selectDomain],
  (state) => state.type,
);

export const selectRecommendedPage = createSelector(
  [selectDomain],
  (state) => state.recommendedPage,
);

export const selectRecommendedLoad = createSelector(
  [selectDomain],
  (state) => state.recommendedLoad,
);

export const selectNoGroupMembersEnded = createSelector(
  [selectDomain],
  (state) => state.noGroupMembersEnded,
);
