import { Drawer } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MinWidthApp } from 'Utils/UtilityFunctions';
import { actions } from 'Slice/DocumentHubSlice';
import {
  SecondaryColor,
  maxMobile,
  primaryFont,
  wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import GilroyBackSvg from 'Components/common/assets/svgIcons/GilroyBackSvg';

const Wrapper = styled.div`
position: relative;
.create-group {
  padding: 0px 30px;
  @media all and (max-width: ${maxMobile}) {
    padding: 0px 16px;
  }
}
`;
const BackGilroyBtn = styled.span`
display: flex;
align-items: center;
position: absolute;
left: -81px;
top: 30%;
font-family: ${primaryFont};
font-weight: ${wMedium};
padding: 4px 10px 3px 8px;
background: ${SecondaryColor};
color: #FFF;
transform: rotate(-90deg);
font-size: 14px;
line-height: 12px;
letter-spacing: -0.3px;
text-align: center;
border-radius: 6px 6px 0px 0px;
box-shadow: -3px 0px 24px 0px rgba(0, 0, 0, 0.10);
height: 29px;
width: 133px;
cursor: pointer;
svg {
  margin-right: 6px;
  transform: rotate(90deg);
}
`;

type Props = {
  setDrawerOpen: (p: boolean) => void;
  drawerOpen: boolean;
  docViewGilV3Flag?: boolean;
  URL: string;
  setTableRowId?: (p: number | null) => void;
} & typeof defaultProps;

const defaultProps = {
  setTableRowId: (p: number | null) => {
    console.log(p);
  },
  docViewGilV3Flag: false,
};

const DrawerDocViewer = function DrawerDocViewer(props: Props) {
  const {
    setDrawerOpen, drawerOpen, URL, setTableRowId, docViewGilV3Flag,
  } = props;
  const dispatch = useDispatch();
  const isTab = MinWidthApp();
  const onClose = () => {
    setTableRowId(null);
    setDrawerOpen(false);
    dispatch(actions.clearPreviewDocument());
  };
  const isGilroyV3 = !window.location.pathname.includes('feed');
  return (

    <Wrapper>
      <Drawer
        className={classNames('drawer-alerts people-group-drawer doc-viewer', { docViewGilV3: docViewGilV3Flag })}
        placement="right"
        visible={!isTab && drawerOpen}
        onClose={onClose}
        destroyOnClose
      >
        {docViewGilV3Flag && isGilroyV3 ? (
          <BackGilroyBtn className="back-to-gilroy-btn" onClick={onClose}>
            <GilroyBackSvg />
            {' '}
            Back To Gilroy
          </BackGilroyBtn>
        ) : null}
        <div style={{ height: '100%' }}>
          <iframe
            src={URL}
            title="file"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
          />
        </div>
      </Drawer>
    </Wrapper>
  );
};

DrawerDocViewer.defaultProps = defaultProps;
export default DrawerDocViewer;
