import styled from 'styled-components';

type Props = {
  color?: string,
  onClick: () => void,
} & typeof defaultProps;

const defaultProps = {
  color: '#BBB8B1',
};
const SVGWrapper = styled.svg`
cursor: pointer;
&:hover {
  path {
    fill: #616161;
  }
}
`;
const SliderForwardIcon = function SliderForwardIcon(props: Props) {
  const { color, onClick } = props;
  return (
    <SVGWrapper xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none" className="slider-forward-icon" onClick={onClick}>
      <path d="M11.9985 10.9329C11.9841 10.4919 11.8114 10.0716 11.5131 9.7519L3.04122 0.75371C2.36496 0.0362244 1.24631 0.0135689 0.542639 0.703104C-0.16103 1.39264 -0.18325 2.53326 0.493007 3.25074L7.78458 11.0004L0.493007 18.7501C-0.18325 19.4676 -0.16103 20.6082 0.542639 21.2977C1.24631 21.9873 2.36496 21.9646 3.04122 21.2471L11.5131 12.2489C11.8432 11.8955 12.0184 11.4206 11.9985 10.9329Z" fill={color} />
    </SVGWrapper>
  );
};
SliderForwardIcon.defaultProps = defaultProps;
export default SliderForwardIcon;
