import React from 'react';

type Props = {
  color?: string,
} & typeof defaultProps;

const defaultProps = {
  color: '#424B50',
};

const DocumentEyeIcon = function DocumentEyeIcon(props: Props) {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <mask id="mask0_2932_4697" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#667982" />
      </mask>
      <g mask="url(#mask0_2932_4697)">
        <path d="M8.00222 5C4.97266 5 3.07712 7.92808 3.07712 7.92808C2.97429 8.08128 2.97429 8.28167 3.07712 8.43488C3.07712 8.43488 4.97266 11.363 8.00222 11.363C11.0318 11.363 12.9229 8.43488 12.9229 8.43488C13.0257 8.28167 13.0257 8.08128 12.9229 7.92808C12.9229 7.92808 11.0318 5 8.00222 5ZM8.00222 6.36508C9.00074 6.36508 9.8164 7.18162 9.8164 8.18015C9.8164 9.17868 9.00074 9.99788 8.00222 9.99788C7.00369 9.99788 6.1836 9.17868 6.1836 8.18015C6.1836 7.18162 7.00369 6.36508 8.00222 6.36508ZM8.00222 7.27039C7.49488 7.27039 7.09246 7.6728 7.09246 8.18015C7.09246 8.68748 7.49488 9.08901 8.00222 9.08901C8.50955 9.08901 8.91109 8.68748 8.91109 8.18015C8.91109 7.6728 8.50955 7.27039 8.00222 7.27039Z" fill={color} />
      </g>
    </svg>
  );
};
DocumentEyeIcon.defaultProps = defaultProps;
export default DocumentEyeIcon;
