import BackIcon from 'Components/common/assets/svgIcons/BackIcon';
import {
  maxTablet,
  maxWidth,
  primaryFont, primaryHeadingColor, retina, wMedium,
} from 'Utils/utilsStyle';
import { Divider } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.span`
display: flex;
align-items: center;
padding-top: 8px;
padding-left: 8px;
padding-right: 8px;
.back-icon-svg {
  width: 18px;
  height: 18px;
  @media all and (max-width: ${maxWidth}), ${retina} {
    width: 20px;
    height: 20px;
  }
  @media all and (min-height: ${maxTablet}) {
    width: 18px;
    height: 18px;
  }
  @media all and (max-width: ${maxTablet}){
    width: 12px;
    height: 12px;
  }
}
.ant-divider {
  height: 20px;
  border-color: #DDD;
  @media all and (max-width: ${maxTablet}){
    height: 16px;
    margin: 0px 5px;
  }
}
.back-icon-text {
  font-family: ${primaryFont};
  font-weight: ${wMedium};
  font-size: 20px;
  line-height: 40px;
  letter-spacing: -0.792px;
  color: ${primaryHeadingColor};
  @media all and (max-width: ${maxTablet}){
    font-size: 16px;
    line-height: 26px;
  }
  }
`;
type Props = {
  title?: string,
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  title: '',
  onClick: () => { },
};
const BackButtonSecondaryHeader = function BackButtonSecondaryHeader(props: Props) {
  const { title, onClick } = props;
  return (
    <StyledWrapper className={classNames('back-button-secondary-header')}>
      <BackIcon onClick={onClick} />
      <Divider type="vertical" />
      <span className="back-icon-text">{title}</span>
    </StyledWrapper>
  );
};
BackButtonSecondaryHeader.defaultProps = defaultProps;
export default BackButtonSecondaryHeader;
