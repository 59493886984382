import { createGlobalStyle } from 'styled-components';
import {
  boxShadow,
  maxMobile,
  maxTablet,
  minExtraLargeScreen,
  minStandardScreen,
  primaryColor,
  primaryFont, primaryHeadingColor, retina, textTruncateSnippetFour, wMedium,
} from '../Utils/utilsStyle';

const GeneralStyleV2 = createGlobalStyle`
.image-title-tooltip-gilroy {
  .ant-tooltip-content {
    border-radius: 8px;
    .ant-tooltip-inner {
      border-radius: 8px;
      color: ${primaryHeadingColor};
      font-weight: ${wMedium};
      font-style: ${primaryFont};
      max-height: 100px;
      overflow: hidden;
      ${textTruncateSnippetFour};
    }
  }
}
.select-highlight-none {
  user-select: none;
}
/* Sharing Popup common styles */
.popoverSharingCustom{
  .ant-popover{
    .ant-popover-content{
      .ant-popover-inner{
        border-radius: 12px;
        overflow: hidden;
        .ant-popover-inner-content{
          padding: 0;
          .sharingPopoverContentWrapper{
            padding: 10px 16px 14px 16px;
            border-radius: 12px;
            /* min-width: 238px; */
            box-shadow: ${boxShadow};
            @media all and (${retina}) {
              padding: 14px 18px;
            }
            .heading-popover {
              font-family: ${primaryFont};
              font-size: 16px;
              font-weight: ${wMedium};
              color: ${primaryColor};
              @media all and (${retina}) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  &.popoverSharingCustom, // General Styling
  &.popoverSharingListingView, // For listview
  &.popoverSharingGridTile{ // for grid Tile 
    .ant-popover{
      .ant-popover-content{
        .ant-popover-inner{
          @media all and (min-width: ${minExtraLargeScreen}) {
          }
          @media all and (${retina}) {
          }
          @media all and (max-width: ${minStandardScreen}) {
            border-radius: 12px;
          }
          @media all and (max-width: ${maxTablet}) {
            border-radius: 8px;
          }
          @media all and (max-width: ${maxMobile}) {
          }
          .ant-popover-inner-content{
            .sharingPopoverContentWrapper{
              @media all and (min-width: ${minExtraLargeScreen}) {
              }
              @media all and (${retina}) {
              }
              @media all and (max-width: ${minStandardScreen}) {
                padding: 8px 12px 10px 12px;
                border-radius: 12px;
              }
              @media all and (max-width: ${maxTablet}) {
                padding: 6px 10px 8px 10px;
                border-radius: 8px;
              }
              @media all and (max-width: ${maxMobile}) {
              }
              .heading-popover {
                @media all and (min-width: ${minExtraLargeScreen}) {
                }
                @media all and (${retina}) {
                }
                @media all and (max-width: ${minStandardScreen}) {
                  font-size: 12px;
                }
                @media all and (max-width: ${maxTablet}) {
                  font-size: 10px;
                }
                @media all and (max-width: ${maxMobile}) {
                }
              }
              .shareIconsWrapper{
                @media all and (min-width: ${minExtraLargeScreen}) {
                }
                @media all and (${retina}) {
                }
                @media all and (max-width: ${minStandardScreen}) {
                  gap: 8px;
                }
                @media all and (max-width: ${maxTablet}) {
                  gap: 6px;
                }
                @media all and (max-width: ${maxMobile}) {
                }
                .evernote-share-button,
                .social-icons-slide-mail,
                .social-icons-slide-linkedin,
                .social-icons-slide-twitter,
                .social-icons-slide-facebook,
                .teams-share-button{
                  @media all and (min-width: ${minExtraLargeScreen}) {
                  }
                  @media all and (${retina}) {
                  }
                  @media all and (max-width: ${minStandardScreen}) {
                    height: 16px !important;
                    width: 16px;
                  }
                  @media all and (max-width: ${maxTablet}) {
                    height: 14px !important;
                    width: 14px;
                  }
                  @media all and (max-width: ${maxMobile}) {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-popover-placement-leftBottom{
    padding-right: 0 !important;
  }
}
`;
export default GeneralStyleV2;
