import {
  maxMobile,
  maxTablet, maxWidth, midWidthApp, primaryFont, retina, scrollbarTransparent, smMobile400, wMedium,
} from 'Utils/utilsStyle';
import { createGlobalStyle } from 'styled-components';

const GilRoyV3Style = createGlobalStyle`
  body {
    .chatbot-v3-styled-wrapper {
      &.TabMode {
      .chatbot-v3-container {
      .container-inner-area {
      .chatbot-v3-body-area {
        padding: 8px 16px 0px;
        height: calc(100% - 189px);
        &.bodyContentHeight {
          height: calc(100% - 242px);
        }
        .tabs-div {
          height: 30px;
          max-height: 30px;
          .gilroy-custom-tab {
            font-size: 12px;
            line-height: 14px;
            svg {
              width: 14px;
              margin-right: 5px;
            }
          }
        }
        .body-content {
          height: calc(100% - 36px);
          .chatbot-library {
          .heading-div-library {
            top: -30px;
            font-size: 14px;
            line-height: 16px;
            padding: 8px 0px 12px;
            svg {
              width: 20px;
            }
          }
          .chatbot-library-slider-cmp {
            .library-prompt-example-wrapper {
              height: calc(100% - 5px);
              padding-bottom: 0px;
              .tip-wrapper {
                .title {
                  font-size: 12px;
                  line-height: 19px;
                }
              }
            }
            .library-slider {
              .slick-dots {
                top: -26px;
                right: 4px;
                li {
                  width: 12px;
                  button {
                    ::before {
                      font-size: 8px;
                    }
                  }
                }
              }
              .slick-list {
                margin: 30px -1px 0px;
                .slick-track {
                  .slick-slide {
                    div {
                      .library-category-v2 {
                        padding: 14px;
                        height: 96px;
                        min-height: 96px;
                        min-width: 155px;
                        .arrow {
                          right: 7px;
                          bottom: 0px;
                        }
                        .category-img-type-div {
                          .img-icon-category {
                            width: 25px;
                            height: 25px;
                            margin-top: -5px;
                            margin-left: -6px;
                            box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 17px -2px;
                          }
                          .category-type {
                            font-size: 8px;
                            right: -10px;
                            top: -6px;
                          }
                        }
                        .heading-category {
                          font-size: 12px;
                          line-height: 12px;
                        }
                      }
                    }
                  }
                } 
              }
            }
          }
          }
          .chatbot-history {
            .scroll-wrapper-chat-history {
              &::-webkit-scrollbar {
                 width: 4px;
              }
            }
          }
        }
      }
      .chatbot-v3-footer-area {
        div {
          .custom-text-area-gilroy {
            margin-bottom: 4px;
            .textarea-input-gilroy {
              min-height: 34px;
              max-height: 34px;
            }
            &.notHtmlCase  {
              .textarea-input-gilroy { 
                line-height: 20px;
                padding: 7px 0px 7px 16px;
              }
            }
            &.changeHeight {
              padding: 8px;
              .placeholder-content-editable {
                top: 13px !important;
              }
              .textarea-input-gilroy {
                line-height: 20px;
                height: 71px;
                max-height: 71px;
              }
            }
            &.isSearchBarSmall  {
              .textarea-input-gilroy { 
                /* padding: 5px 0px 5px 16px; */
              }
            }
            .generate-btn-gilroy {
              
            }
          }
        }
        .disclaimer-footer {
          position: absolute;
          bottom: 21px;
          width: calc(100% - 32px);
          align-items: center;
          @media all and (max-width: ${maxMobile}) {
            bottom: 10px;
          }

          .disclaimer-area {}
          .powered-div {
            .powered-text {}
          }
        }
      }
    }
      }
      .header-v3-heading {
        font-size: 24px;
      }
      .cross-icon-x3 {
        top: 24px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .animation-div-v3 {
        .image-container-v3 {
          width: 76px;
          height: 75px;
          top: 50%;
          right: 35px;
          transform: translateY(-50%);
          margin-top: 0px;
        }
        .image-container-v3-gif {
          height: 50px;
          width: 50px;
          top: 0px;
          left: 11px;
          @media all and (max-width: ${maxMobile}) {
            left: -12px;
          }
        }
      }
      }
      &.mobileMode {
        .chat-container {
          .container-inner-area {
            border-radius: 12px;
            .chatbot-v3-body-area {
              &.bodyContentHeight {
                height: calc(100% - 238px);
              }
              padding: 8px 6px 0px;
              height: calc(100% - 185px);
            }
          }
        }
      }
      .chatbot-v3-container {
        .container-inner-area {
          &.conversationalPanelOpen {
            .ant-modal-root {
              .ant-modal-mask {    
                position: absolute;
                width: calc(100% + 226px);
                transform: translateX(-226px);
              }
              .ant-modal-wrap {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-60%, -50%);
                width: 100%;
                overflow-x: hidden;
              }
            }
          }
          .chatbot-v3-heading-area {}
          .chatbot-v3-body-area {
            &.bodyContentHeight {
              height: calc(100% - 375px);
              @media all and (max-width: ${maxWidth}), ${retina} {
                height: calc(100% - 275px);
              }
              @media all and (min-height: ${maxTablet}) {
                height: calc(100% - 559px);
              }
            }
            padding: 12px 33px 0px 32px;
            height: calc(100% - 159px);
            @media all and (max-width: ${maxWidth}), ${retina} {
              padding: 8px 14px 0;
              height: calc(100% - 156px);
            }
            .tabs-div {
              border-radius: 6.019px;
              background: #DEF6E6;
              width: 100%;
              display: flex;
              flex-direction: row-reverse;
              padding: 4px;
              max-height: 40px;
              height: 40px;
              @media all and (max-width: ${maxWidth}), ${retina} {
                width: calc(100% - 1px);
                height: 32px;
                max-height: 32px;
              }
              .gilroy-custom-tab {
                font-family: ${primaryFont};
                font-size: 14px;
                font-weight: ${wMedium};
                padding: 5px 0;
                border-radius: 6.019px;
                letter-spacing: -0.604px;
                width: calc(50% + 2px);
                &.notActive {
                  background-color: #DEF6E6;
                  font-weight: ${wMedium};
                  width: calc(50% - 2px);
                }
              }
            }
            .body-content {
              &.recentTags {
                padding-bottom: 37px;
              }
              &.contentHeight {
                .chatbot-library {
                  .chatbot-library-slider-cmp {
                    .library-prompt-example-wrapper {
                      padding-bottom: 40px;
                    }
                  }
                }
              }
              height: calc(100% - 38px);
              @media all and (max-width: ${maxWidth}), ${retina} {
                height: calc(100% - 45px);
              }
              .chatbot-library {
                height: calc(100% - 0px);
                padding: 0 9px;
                 .back-button-secondary-header {
                    padding-left: 0px;
                  }
                &.notLibraryExample {
                  height: calc(100% - 30px);
                  max-height: calc(100% - 30px);
                  width: calc(100% + 28px);
                  padding: 0 10px;
                  margin: 0 -15px;
                  @media all and (max-width: ${maxWidth}), ${retina} {
                    height: calc(100% - 15px);
                    max-height: calc(100% - 15px);
                  }
                  @media all and (max-width: ${maxTablet}) {
                    height: calc(100% - 24px);
                    max-height: calc(100% - 24px);
                  }
                  @media all and (max-height: 690px) {
                    overflow: hidden scroll;
                    ${scrollbarTransparent};
                  }
                  .chatbot-library-slider-cmp {
                    height: fit-content;
                    @media all and (max-height: 690px) {
                      position: relative;
                    }
                  }
                }
                .heading-div-library {
                  position: absolute;
                  top: -32px;
                  left: 16px;
                  font-size: 18px;
                  line-height: 21px;
                  padding-left: 0px;
                  @media all and (max-width: ${maxWidth}), ${retina} {
                    top: -31px;
                    left: 16px;
                  }
                  svg {
                    width: 24px;
                    height: 18px;
                  }
                  @media all and (max-height: 690px) {
                    display: none;
                  }
                }
                .library-category-skeleton {
                  .row-skelton {
                      margin: 30px 0px 0px;
                      @media all and (max-width: ${maxWidth}), ${retina} {
                        margin: 25px 15px 0px;
                      }
                      .col-skeleton {
                        .skeleton-box {
                          width: calc(100% - 15px);
                          min-width: calc(100% - 15px);
                          margin: 0px 10px 10px;
                          @media all and (max-width: ${maxWidth}), ${retina} {
                            margin: 0px 5px 10px;
                          }
                      }
                    }
                  }
                }
                .chatbot-library-slider-cmp {
                  padding: 0px;                 
                  .heading-div-library {
                    @media all and (max-height: 690px) {
                      display: inline-flex;
                    }
                  }
                  .library-slider {
                    .slider-circled-back-icon {
                      top: 46%;
                      left: 5px;                    
                      @media all and (max-width: ${maxWidth}), ${retina} {
                        top: 46%;
                        left: 5px;
                      }
                      @media all and (max-width: ${maxTablet}) {
                        top: 46%;
                        left: 5px;
                      }
                    }
                    .slick-list {
                      margin: 30px 0px 0px;
                      @media all and (max-width: ${maxMobile}) {
                        margin: 30px 5px 0px;
                      }
                      .slick-track {
                        .slick-slide {
                          div {
                            .skeleton-box {
                              margin: 10px 6px 0px;
                              width: calc(100% - 15px);
                              min-width: calc(100% - 15px);
                            }
                            .library-category-v2 {
                              margin: 12px 6px 12px;
                              padding: 13.2px 13.2px 13.2px 22px;
                              height: 131px;
                              min-height: 131px;
                              min-width: 230px;
                              .category-img-type-div {
                                margin-bottom: 1px;
                                .img-icon-category {
                                  width: 44px;
                                  height: 44px;
                                  margin-top: 0px;
                                  margin-left: -8px;
                                }
                                .category-type {
                                  font-size: 11px;
                                  letter-spacing: -0.466px;
                                  line-height: normal;
                                  padding: 3.2px 8.2px;
                                }
                              }
                              .heading-category {
                                font-size: 14px;
                                line-height: 16px;
                                margin-top: 0px;
                              }
                              .description-category {
                                line-height: normal;
                                margin-top: 5px;
                                margin-bottom: 0px;
                              }
                              .arrow {
                                right: 13px;
                              }
                            }
                          }
                        }
                      }
                    }
                    .slider-circled-forward-icon {
                      top: 46%;
                      right: 5px;
                      @media all and (max-width: ${maxWidth}), ${retina} {
                        top: 46%;
                        right: 5px;
                      }
                      @media all and (max-width: ${maxTablet}) {
                        top: 46%;
                        right: 4px;
                      }
                    }
                    .slick-dots {
                      top: -24px;
                      right: 3px;
                    }
                  }
                  .library-prompt-example-wrapper {
                    padding-bottom: 25px;
                    padding-right: 5px;
                    .skeleton-box {
                      width: calc(100% - 10px);
                      min-width: calc(100% - 10px);
                      margin-bottom: 5px;
                    }
                    .tip-wrapper {
                      .title {
                        line-height: 24px;
                      }
                    }
                  }
                }
              }
              .chatbot-history {
                &.globalGilroyV3Wrapper {
                  /* padding: 0px; */
                  .history-empty-state {
                    height: 100%;
                    display: flex;
                    margin: 0px;
                    font-size: 18px;
                    line-height: 32px;
                    .empty-inner-state {
                      .text-decor {
                        svg {
                          height: 17px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .chatbot-v3-footer-area {
            padding: 0px 33px 16px 32px;
            @media all and (max-width: ${maxWidth}), ${retina} {
              padding: 0px 14px 16px;
            }
            @media all and (max-width: ${maxMobile}) {
              padding: 0px 8px 12px;
            }
            div {
              .custom-text-area-gilroy {
                &.notHtmlCase {
                  padding-right: 12px;
                  .textarea-input-gilroy {
                    
                  }
                }
                &.isSearchBarSmall {
                  .textarea-input-gilroy {
                  }
                }
                &.changeHeight {
                  /* padding-bottom: 11px; */
                  .textarea-input-gilroy {
                    height: 213px;
                    max-height: 213px;
                    line-height: 24px;
                    @media all and (max-width: ${maxWidth}), ${retina} {
                      height: 116px;
                      max-height: 116px;
                    }
                    @media all and (min-height: ${maxTablet}) {
                      height: 400px;
                      max-height: 400px;
                    }
                     .global-search-div {
                      .global-search-inner-div {
                        &::after {
                          top: 4px;
                        }
                        &::before {
                          top: 5px;
                        }
                        .global-search-input-content-editable {}
                      }
                    }
                  }
                }
                border-radius: 9px;
                margin-bottom: 4px;
                .textarea-input-gilroy {
                  min-height: 40px;
                  max-height: 40px;
                }
                .generate-btn-gilroy {
                 
                }
              }
            }
            .disclaimer-footer {
              align-items: center;
              .disclaimer-area {
                @media all and (max-width: ${midWidthApp}) {
                  width: calc(100% - 88px);
                }
                .disclaimer-text {
                  @media all and (max-width: ${midWidthApp}) {
                    font-size: 8px;
                  }
                }
              }
              .powered-div {
                .powered-text {
                  @media all and (max-width: ${midWidthApp}) {
                    font-size: 10px;
                  }
                }
              }
            }
          }
          .aside-toggler {}
          .aside-history {
            .chat-bot-history-panel {
              padding-right: 34px;
              @media all and (max-width: ${maxTablet}) {
                padding-right: 0px;
              }
              .new-chat-div {
                .header-panel {
                  padding: 0px;
                }
              }
              .scroll-wrapper {
                max-height: calc(100% - 55px);
                ::-webkit-scrollbar { width: 4px; }
                .infinite-scroll-component__outerdiv {
                  .infinite-scroll {
                    min-height: calc(100vh - 96px);
                    .chat-bot-history-title-tile {
                      .img-title-div {}
                      .action-div-wrapper {
                        .side-menu-popover {
                          .ant-popover {
                            left: 127px !important;
                            @media all and (max-width: ${maxTablet}) {
                              left: 417px !important;
                            }
                            @media all and (max-width: ${maxMobile}) {
                              left: 280px !important;
                            }
                            @media all and (max-width: ${smMobile400}) {
                              left: 242px !important;
                            }
                            .ant-popover-content {
                              .ant-popover-inner {
                                .ant-popover-inner-content {
                                  width: unset !important;
                                  height: unset !important;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .history-empty-panel {
                .gilroy-white-svg {
                  width: 92px;
                  height: 63px;
                }
                .heading-empty-state {
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }
          }
          .upload-document-btn {
            bottom: 65px;
            left: -96px;
            @media all and (max-width: ${maxMobile}) {
              display: none;
            }
          }
          .ant-modal-root {
            .ant-modal-mask {    
              position: absolute;
              width: 100%;
            }
            .ant-modal-wrap {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

export default GilRoyV3Style;
