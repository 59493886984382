import React from 'react';

const StopIcon = function StopIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="6" stroke="#DD0000" strokeWidth="1.3" />
      <rect x="4" y="6.5" width="6" height="1.5" fill="#DD0000" />
    </svg>
  );
};
export default StopIcon;
