import StopIcon from 'Components/common/assets/svgIcons/StopIcon';
import {
  maxMobile,
  maxTablet,
  maxWidth, primaryColor, primaryFont, retina, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onClick: (a: string) => void,
  stopFlag?: boolean,
  prompt: string,
  chatBoxOpen?: boolean,
};

const defaultProps = {
  stopFlag: false,
  chatBoxOpen: false,
};

const Wrapper = styled.span`
  z-index: 1;
  position: relative;
  cursor: pointer;
  &.stopGen {
    display: flex;
    border: 1px solid #DD0000;
    color: #DD0000;
    right: calc(50% - 63px);
  }
  display: none;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: calc(50% - 50px);
  border-radius: 6px;
  border: 1px solid ${primaryColor};
  background: #FFF;
  box-shadow: 0px 2px 13px 0px rgba(141, 141, 141, 0.39);
  color: ${primaryColor};
  font-family: ${primaryFont};
  font-size: 14px;
  font-weight: ${wMedium};
  letter-spacing: -0.5px;
  padding: 5px 10px;
  &.chatBoxOpenFlag {
    bottom: 50px;
    @media all and (max-width: ${maxMobile}) {
      bottom: 30px;
    }
  }
  @media all and (max-width: ${maxWidth}), ${retina} {
    right: calc(50% - 50px);
  }
  @media all and (max-width: ${maxTablet}) {
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 4px;
  }
  @media all and (max-width: ${maxMobile}) {
    bottom: -5px;
  }
  svg {
    width: 14px;
    height: 14px;
    margin-left: 4px;
    @media all and (max-width: ${maxTablet}) {
      width: 12px;
      height: 12px;
    }
  }

`;

const RegenerateBtnGilroyChat = function RegenerateBtnGilroyChat(props: Props) {
  const {
    stopFlag, onClick, prompt, chatBoxOpen,
  } = props;
  const title = 'Stop generating';
  return (
    <Wrapper
      className={classNames('regenerate-btn-gilroy-chat', {
        stopGen: stopFlag,
        chatBoxOpenFlag: chatBoxOpen,
      })}
      onClick={() => onClick(prompt)}
    >
      {title}
      {stopFlag ? (
        <StopIcon />
      ) : null}
    </Wrapper>
  );
};

RegenerateBtnGilroyChat.defaultProps = defaultProps;
export default RegenerateBtnGilroyChat;
