import WorldIcon from 'Components/common/assets/svgIcons/WorldIcon';
import CompanyWidgetSmall from 'Components/common/components/CompanyWidgetSmall';
import CompanySkeleton from 'Components/common/skeleton/CompanySkeleton';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import { TagItem } from 'Types/ChatBotV2Types';
import { Company } from 'Types/CompaniesTypes';
import { translateSkipAreas } from 'Utils/Constants';
import {
  maxMobile,
  maxTablet,
  maxWidth,
  midMobile,
  midWidthApp,
  minTablet,
  primaryColor, primaryFont, primaryHeadingColor, retina,
  scrollbar, secondaryFont, wMBold, wMedium,
} from 'Utils/utilsStyle';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const CompanyWrapper = styled.div`
  &.hide{
    display: none;
  }
  margin-top: 5px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  ${scrollbar};
  ::-webkit-scrollbar { width: 6px; } 
  ::-webkit-scrollbar-track { background-color: transparent; } 
   @media all and (max-width: ${maxTablet}) {
    ::-webkit-scrollbar { width: 3px; } 
  } 
  .grid-for-result {
     &.gridForV3 {
      .grid-col {
        flex: 0 0 25%;
        max-width: 25%;
        padding-left: 8px !important;
        padding-right: 8px !important;
        @media all and (max-width: ${maxTablet}) {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }
        @media all and (max-width: ${maxMobile}) {
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 24px !important;
          padding-right: 24px !important;
        }
      }
    }
    margin: 0px !important;
    width: 100%;
    row-gap: 16px !important;
    .grid-col {
      @media all and (max-width: 1750px) {
        flex: 0 0 14.28%;
        max-width: 14.28%;
      }
      @media all and (max-width: 1600px) {
        flex: 0 0 16.66%;
        max-width: 16.66%;
      }
      @media all and (max-width: 1400px) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      @media all and (max-width: ${midWidthApp}) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media all and (max-width: ${maxTablet}) {
        flex: 0 0 16.66%;
        max-width: 16.66%;
      }
      @media all and (max-width: ${minTablet}) {
        flex: 0 0 20%;
        max-width: 20%;
      }
      padding-left: 16px !important;
      padding-right: 16px !important;
      @media all and (max-width: ${maxTablet}) {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
      @media all and (max-width: ${maxMobile}) {
        padding-left: 3px !important;
        padding-right: 1px !important;
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media all and (max-width: ${midMobile}) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      .company-skeleton {
        margin: 0px 10px 0px;
        .person-skeleton-wrapper {
          .person-box {
            height: 65px;
            margin: 8px auto 10px;
          }
          .title-box-short {
            display: none;
          }
          .btn {
            display: none;
          }
        }
      }
      .company-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        padding: 4px 6px;
        border: 2px solid #fff;
        max-width: 112px;
        min-height: 117px;
        cursor: pointer;
         &.activeCard {
          border-radius: 12px;
          border: 2px solid ${primaryColor};
          background: #DEF6E6;
        }
        .company-widget-small {
          width: 88px;
          height: 66px;
          flex-shrink: 0;
          border-radius: 12px;
          margin: 0px 0px 5px;
          .image-placeholder {
            background-position: center center;
            border-radius: 10px;
            width: 95%;
            height: 93%;
            @media all and (max-width: ${maxWidth}), ${retina} {
              width: 95%;
              height: 93%;
            }
            @media all and (min-height: ${maxTablet}) {
              width: 95%;
              height: 93%;
            }
          }
          .progress-wrapper {
            display: none;
          }
        }
        .company-name {
          width: 100%;
          color: ${primaryHeadingColor};
          font-family: ${secondaryFont};
          font-size: 14px;
          font-weight: ${wMBold};
          line-height: 16px;
          letter-spacing: -0.254px;
          margin-bottom: 0px;
          @media all and (max-width: ${maxTablet}) {
            font-size: 12px;
          }
        }
        .link-div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .world-icon-svg {
            width: 11px;
            min-width: 11px;
            height: 12px;
            min-height: 12px;
            margin-right: 5px;
          }
          .link-company {
            color: ${primaryColor};
            font-family: ${primaryFont};
            font-size: 12px;
            font-weight: ${wMedium};
            letter-spacing: -0.409px;
            @media all and (max-width: ${maxTablet}) {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
`;

type Props = {
  companies: Company[]
  fetchMore: (type: string) => void
  handleClick: (item: TagItem) => void
  active: number
  loading: boolean
};

const CompanyResultTagging = function CompanyResultTagging(props: Props) {
  const {
    companies, fetchMore, handleClick, active, loading,
  } = props;
  const [activeCard, setActiveCard] = useState(-1);
  const disclessPersonality = useSelector(selectDisclessPersonality);

  const isGilroyV3 = disclessPersonality ? (!window.location.pathname.includes('feed') && !window.location.pathname.includes('person'))
    : !window.location.pathname.includes('feed');
  const handleMouseEnter = (index: number) => {
    setActiveCard(index);
  };

  const handleMouseLeave = () => {
    setActiveCard(-1);
  };

  return (
    <CompanyWrapper id="gilroySearchCompaniescrollWrapper" className={classNames('', { hide: active !== 2 })}>
      <InfiniteScroll
        hasMore
        dataLength={companies.length}
        next={() => fetchMore('company')}
        className=""
        loader={false}
        scrollableTarget="gilroySearchCompaniescrollWrapper"
      >
        <Row gutter={[48, 24]} className={classNames('grid-for-result', { gridForV3: isGilroyV3 })}>
          {companies.map((company, index) => (
            <Col span={3} className="grid-col">
              <div
                className={classNames('company-card', { activeCard: index === activeCard })}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick({
                  type: 'company', id: company.companyId, name: company.companyName, image: company.companyLogo, color: company.companyPersonality[0]?.color,
                })}
                onKeyDown={() => handleClick({
                  type: 'company', id: company.companyId, name: company.companyName, image: company.companyLogo, color: company.companyPersonality[0]?.color,
                })}
                role="button"
                tabIndex={0}
              >
                <CompanyWidgetSmall
                  logo={company.companyLogo}
                  persona={company.companyPersonality}
                  checkBox={false}
                  companyId={company.companyId.toString()}
                  backgroundPosition="center 5px"
                  backgroundSize="50%"
                  wrapperClassName="company-widget-small"
                  triggerColor={company.companyPulse}
                />
                <h6 className="company-name text-truncate">{company.companyName}</h6>
                <div className="link-div">
                  <WorldIcon color="#979797" />
                  <span className={`link-company text-truncate ${translateSkipAreas}`}>{company.companyDomain}</span>
                </div>
              </div>
            </Col>
          ))}
          {loading ? (
            <Col span={3} className="grid-col">
              <CompanySkeleton />
            </Col>
          ) : null}
        </Row>
      </InfiniteScroll>
    </CompanyWrapper>
  );
};

export default CompanyResultTagging;
