import {
  loadingGifGlobalSearch, maxMobile, maxTablet,
} from 'Utils/utilsStyle';
import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchData, selectUniqueTagItemList } from 'Selector/ChatBotV2Selector';
import { actions } from 'Slice/ChatbotV2Slice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { TagItem } from 'Types/ChatBotV2Types';
import NoPersonsFound from 'Components/common/components/NoPersonsFound';
import { TAG_LIMIT } from 'Utils/Constants';
import classNames from 'classnames';
import { valueToObject, getCurrentTime, getLangCookie } from 'Utils/UtilityFunctions';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import PopoverTabs from './PopoverTabs';
import BRMResultTagging from './BRMResultTagging';
import CompanyResultTagging from './CompanyResultTagging';
import PeopleResultTagging from './PeopleResultTagging';
import DocResultTagging from './DocResultTagging';

const ContentWrapper = styled.div`
&.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
&.ContentV3 {
  .no-person-found-wrapper {
    height: calc(100% - 60px);
  }
}
height: 100%;
padding: 12px 16px 2px;
@media all and (max-width: ${maxTablet}) {
padding: 8px 2px;
}
.gif-loader {
  width: 125px;
  height: 125px;
  @media all and (max-width: ${maxMobile}) {
    width: 110px;
    height: 110px;
  }
}
.no-person-found-wrapper {
 height: calc(100% - 60px);
  h3 {
    font-size: 20px;
  }
}
`;

type Props = {
  activeTab: number;
  children: object
  openPopover: boolean
  onCrossClick: () => void,
};

const PopoverContent = function PopoverContent(
  { activeTab, onCrossClick, popoverOpen }: {
    activeTab: number,
    onCrossClick: () => void, popoverOpen: boolean,
  },
) {
  const host = window.location.href;
  const dispatch = useDispatch();
  const [active, setActive] = useState(activeTab);
  const searchData = useSelector(selectSearchData);
  // const taggedList = useSelector(selectTaggedList);
  const uniqueTagList = useSelector(selectUniqueTagItemList);
  const {
    peopleSection, buyerSection, companiesSection, searchLoading, extendedSearchLoaded,
    query, peoplePage, extendedSearchLoading, companiesPage, buyerPage, searchLoaded,
    documentPage, documentSection,
  } = searchData;
  const startTime = getCurrentTime();

  const peopleCount = peopleSection.count;
  const companiesCount = companiesSection.count;
  const buyerCount = buyerSection.count;
  const documentCount = documentSection.count;
  const disclessPersonality = useSelector(selectDisclessPersonality);

  const isGilRoyV3 = disclessPersonality ? (!window.location.pathname.includes('feed') && !window.location.pathname.includes('person'))
    : !window.location.pathname.includes('feed');

  const fetchMore = (type: string) => {
    if (type === 'person') {
      dispatch(actions.globalSearchResults({
        query: query.includes(type) ? query : `${type}:${query}`,
        screenName: 'Gilroy',
        page: peoplePage + 1,
        defaultLanguage: getLangCookie(),
      }));
    } else if (type === 'company') {
      dispatch(actions.globalSearchResults({
        query: query.includes(type) ? query : `${type}:${query}`,
        screenName: 'Gilroy',
        page: companiesPage + 1,
        defaultLanguage: getLangCookie(),
      }));
    } else if (type === 'group') {
      dispatch(actions.globalSearchResults({
        query: query.includes(type) ? query : `${type}:${query}`,
        screenName: 'Gilroy',
        page: buyerPage + 1,
        defaultLanguage: getLangCookie(),
      }));
    } else if (type === 'document') {
      dispatch(actions.globalSearchResults({
        query: query.includes(type) ? query : `${type}:${query}`,
        screenName: 'Gilroy',
        page: documentPage + 1,
        defaultLanguage: getLangCookie(),
      }));
    }
  };

  const onClick = (item: TagItem) => {
    onCrossClick();
    const isIdExists = uniqueTagList?.some((tag) => item.id === tag.id);
    if (uniqueTagList.length < TAG_LIMIT || isIdExists) {
      dispatch(actions.setTaggedItem(item));
      dispatch(actions.setUniqueTaggedItem(item));
      if (item.personType === 'LIVESEARCH') {
        dispatch(actions.tagLiveSearchPerson({
          personId: item.id,
          type: item.personType,
          screenName: 'gilroy',
        }));
      } else if (item.type === 'company') {
        dispatch(actions.companySetup({ companyId: item.id }));
      } else if (item.type === 'group') {
        dispatch(actions.groupSetup({ groupId: item.id }));
      } else if (item.type === 'person') {
        dispatch(actions.personSetup({ personId: item.id }));
      }
      const { type, id } = item;
      if (type === 'document') {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_document_selected_searchPopup', 0, { document_id: id, host }),
        ));
      } else if (type === 'company') {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_company_selected_searchPopup', 0, { company_id: id, host }),
        ));
      } else if (type === 'person') {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_person_selected_searchPopup', 0, { person_id: id, host }),
        ));
      } else if (type === 'group') {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_buyerGroup_selected_searchPopup', 0, { group_id: id, host }),
        ));
      }
    } else {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_tagLimitReached', startTime, {
          host,
        }),
      ));
      dispatch(actions.setLimitPopupVisible(true));
    }
  };

  useEffect(() => {
    if (searchLoaded && popoverOpen) {
      setActive(activeTab);
    }
  }, [searchLoaded]);

  const paginationLoading = (searchLoaded || extendedSearchLoaded)
    && (searchLoading);

  return (
    <ContentWrapper
      className={classNames({
        ContentV3: isGilRoyV3,
        loaderWrapper: searchLoading && !searchLoaded,
      })}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => { e.stopPropagation(); }}
    >
      {(searchLoading && !searchLoaded)
        ? (
          <img src={loadingGifGlobalSearch} alt="loader" className="gif-loader" />
        ) : null}
      {(searchLoaded) ? (
        <PopoverTabs
          active={active}
          setActive={setActive}
          peopleCount={peopleCount}
          companiesCount={companiesCount}
          buyerCount={buyerCount}
          onCrossClick={onCrossClick}
          docCount={documentCount}
        />
      ) : null}
      {popoverOpen && searchLoaded
        && ((active === 1 && extendedSearchLoaded && !peopleCount)
          || (active === 2 && !companiesCount) || (active === 3 && !buyerCount)
          || (active === 4 && !documentCount))
        ? <NoPersonsFound title="No Results Found" />
        : null}

      {(searchLoaded) ? (
        <>
          <PeopleResultTagging
            people={[...peopleSection?.persons ?? [], ...peopleSection.liveSearchPersons ?? []]}
            fetchMore={fetchMore}
            handleClick={onClick}
            active={active}
            pagination={paginationLoading}
            extendedLoading={searchLoaded && extendedSearchLoading && !extendedSearchLoaded}
          />
          <CompanyResultTagging
            companies={companiesSection?.companies ?? []}
            fetchMore={fetchMore}
            handleClick={onClick}
            active={active}
            loading={paginationLoading && !!companiesSection.companies?.length}
          />
          <BRMResultTagging
            buyers={buyerSection.groups ?? []}
            fetchMore={fetchMore}
            handleClick={onClick}
            active={active}
            loading={paginationLoading && !!buyerSection.groups?.length}
          />
          <DocResultTagging
            documents={documentSection.documents ?? []}
            fetchMore={fetchMore}
            handleClick={onClick}
            active={active}
            isGilRoyV3={isGilRoyV3}
          />
        </>
      ) : null}

    </ContentWrapper>
  );
};

const SearchTaggingPopover = function SearchTaggingPopover(props: Props) {
  const {
    activeTab, children, openPopover, onCrossClick,
  } = props;
  const isGilRoyV3 = !window.location.pathname.includes('feed');
  return (
    <Popover
      open={openPopover}
      placement="top"
      content={(
        <PopoverContent
          activeTab={activeTab}
          onCrossClick={onCrossClick}
          popoverOpen={openPopover}
        />
      )}
      overlayClassName={classNames('popover-tagging-gilroy', { gilroyV3TaggingPopover: isGilRoyV3 })}
      getPopupContainer={() => document.querySelector('.custom-text-area-gilroy') as HTMLElement}
    >
      {children}
    </Popover>
  );
};

export default SearchTaggingPopover;
