import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// UI & Style
import 'antd/dist/antd.variable.min.css';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import ScrollToTop from 'Utils/ScrollToTop';
import { configureAppStore } from './Store/SetupStore';
import App from './App';
import Style from './Style/Style';

const store = configureAppStore();
const siteKey: string = process.env.REACT_APP_CAPTCHA_SITE_KEY || '';

ReactDOM.render(
  <Provider store={store}>
    <GoogleReCaptchaProvider
      reCaptchaKey={`${siteKey}`}
    >
      <Router>
        <Style />
        <DndProvider
          backend={TouchBackend}
          options={{ enableMouseEvents: true, enableTouchEvents: false }}
        >
          <ScrollToTop />
          <App />
        </DndProvider>

      </Router>
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById('root'),
);
