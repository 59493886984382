import { actions as flurryActions } from 'Slice/HelperSlice';
import { DISCLAIMER_MESSAGE_M1, translateSkipAreas } from 'Utils/Constants';
import { valueToObject } from 'Utils/UtilityFunctions';
import {
  SecondaryColor, maxMobile, maxTablet, maxWidth, maxWidth1300,
  midWidthApp, primaryColor, primaryFont, retina, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  @media all and (max-width: ${maxTablet}) {
    align-items: center;
  }
  .disclaimer-area {
    width: calc(100% - 125px);
    @media all and (max-width: ${maxWidth1300}) {
      width: calc(100% - 110px);
      display: flex;
      flex-direction: column;
    }
    @media all and (max-width: ${midWidthApp}) {
      width: calc(100% - 95px);
    }
    @media all and (max-width: ${maxMobile}) {
      width: calc(100% - 68px);
    }
    .disclaimer-text {
      font-family: ${primaryFont};
      font-size: 11px;
      line-height: 8px;
      letter-spacing: -0.311px;
      color: #6A6A6A;
      margin-bottom: 0;
      @media all and (max-width: ${maxWidth}), ${retina} {
        margin-bottom: -4px;
      }
      @media all and (min-height: ${maxTablet}) {
        font-size: 11px;
        line-height: 8px;
      }
      @media all and (max-width: ${maxWidth1300}) {
        margin-bottom: 0px;
        font-size: 10px;
        line-height: 12px;
      }
      @media all and (max-width: ${midWidthApp}) {
        font-size: 9px;
      }
      @media all and (max-width: ${maxTablet}) {
        font-size: 8px;
        line-height: 9px;
      }
      .primary-color{
        cursor: pointer;
      }
    }
  }
  .powered-div {
    display: flex;
    align-items: center;
    margin-top: -5px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      margin-top: -2px;
    }
    .powered-text {
      font-family: ${primaryFont};
      font-weight: ${wMedium};
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.311px;
      color: #A0A0A0;
      margin-bottom: 0;
      @media all and (max-width: ${maxWidth1300}) {
        font-size: 12px;
        line-height: 14px;
      }
      @media all and (max-width: ${midWidthApp}) {
        font-size: 11px;
      }
      @media all and (max-width: ${maxTablet}) {
        font-size: 10px;
        line-height: 12px;
      }
      @media all and (max-width: ${maxMobile}) {
        font-size: 8px;
        line-height: 9px;
      }
    }
  }
  .primary-color {
    color: ${primaryColor} !important;
  }
  .secondary-color {
    color: ${SecondaryColor} !important;
  }
`;
type Props = {
  newStyle?: boolean,
} & typeof defaultProps;

const defaultProps = {
  newStyle: false,
};
const DisclaimerCmpFooter = function DisclaimerCmpFooter(props: Props) {
  const { newStyle } = props;
  const dispatch = useDispatch();
  const host = window.location.href;

  const handleClick = () => {
    dispatch(flurryActions.callFlurryEvent(
      valueToObject('xcgpt_footer_email_clicked', 0, {
        host,
      }),
    ));
  };

  return (
    <StyledWrapper className={classNames(`disclaimer-footer ${translateSkipAreas}`)}>
      <div className="disclaimer-area">
        <p className="disclaimer-text">
          {DISCLAIMER_MESSAGE_M1}
          {newStyle ? (
            <span className="disclaimer-text">
              {' '}
              Please contact at
              {' '}
              <a onClick={handleClick} href="mailto:gilroy@xiqinc.com" className="disclaimer-text primary-color">gilroy@xiqinc.com</a>
            </span>
          ) : null}
        </p>
        {!newStyle ? (
          <span className="disclaimer-text">
            Please contact at
            {' '}
            <a onClick={handleClick} href="mailto:gilroy@xiqinc.com" className="disclaimer-text primary-color">gilroy@xiqinc.com</a>
          </span>
        ) : null}
      </div>
      <div className="powered-div">
        <span className="powered-text">
          Powered by
          {' '}
          <span className="powered-text primary-color">xiQ</span>
          {' '}
          <span className="powered-text secondary-color">GPT</span>
        </span>
      </div>
    </StyledWrapper>
  );
};
DisclaimerCmpFooter.defaultProps = defaultProps;
export default DisclaimerCmpFooter;
