import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GroupPersonality } from 'Types/GroupsTypes';
import { backgroundColorParsing, parseColorGradientMixture, valueToObject } from 'Utils/UtilityFunctions';
import { Executive } from 'Types/PersonTypes';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Badge, Modal } from 'antd';
import { selectDeletingLoader, selectGroupShared } from 'Selector/GroupsSelector';
import { actions } from 'Slice/GroupsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompanyDetailFlurry, selectCompanyDetailsCall,
} from 'Selector/CompaniesSelector';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { GROUP_DETAIL_ROUTE, PEOPLE_ROUTE, translateSkipAreas } from 'Utils/Constants';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import {
  secondaryFont, primaryHeadingColor, wMedium,
  maxWidth, retina, primaryFont,
  maxWidth1300, minWidthApp, primaryColor,
  nutenixGradientColor,
} from '../../../Utils/utilsStyle';
import PeopleWidgetWithOutHolo from './PeopleWidgetWithOutHolo';
import BinIconCommon from '../assets/svgIcons/BinIconCommon';
import CrossCircle from '../assets/svgIcons/CrossCircle';
import ShareGroupPeopleModal from '../modal/ShareGroupPeopleModal';
import SharedBadge from './SharedBadge';

type Props = {
  groupPersona?: GroupPersonality[]
  persons?: Executive[]
  companies?: string[]
  groupName?: string,
  groupId?: number,
  members?: number
  isShared?: number,
  sharedBy: string,
  disableNavigation?: boolean
  isSearchPage?: boolean
} | typeof defaultProps;

const defaultProps = {
  groupPersona: [],
  persons: [],
  companies: [],
  groupName: 'Not valid group',
  groupId: 0,
  members: 0,
  isShared: 0,
  sharedBy: '',
  disableNavigation: false,
  isSearchPage: false,
};

const Wrapper = styled.div<{ color: string, }>`
  background: conic-gradient(from -70deg, ${(props) => props.color});
  background-color: ${(props) => props.color};
  padding: 4px;
  border-radius: 12px;
  cursor: pointer;
  height: 100%;
  .card-inner-wrapper{
    background-color:#FFFFFF;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    position: relative;
    .logo-image{
      margin:28px 0 20px 0;
      min-height: 34px;
      .frame-logo {
        background: #FFFFFF;
        border: 1.5px solid #DADADA;
        box-shadow: 0px 0px 17px -2px rgba(0, 0, 0, 0.0371566);
        border-radius: 4px;
        height: 34px;
        width: 56px;
        margin-right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media all and (max-width:${minWidthApp})
        {
          height: 30px;
          width: 45px;
        }
        img{
          max-height: 100%;
          height: 100%;
          padding: 0px 10px;
          width: auto;
          max-width:100px;
          margin:0 2px;
        }
      }
    }
    .buyer-name {
      margin: 0px;
      color:${primaryHeadingColor};
      font-family: ${secondaryFont};
      font-weight:${wMedium};
      font-size:24px;
      line-height:26px;
      text-align: center;
      padding: 0 15px;
      min-height: 52px;
      width: 100%;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        font-size: 20px;
      }
      @media all and (max-width: ${maxWidth1300}){
        font-size: 16px;
        line-height: 19px;
        min-height: 38px;
      }
    }
    .user-images{
      margin-top: 10px;
      margin-bottom:26px;
      position: relative;
      min-height: 45px;
      z-index:0;
      @media all and (max-width:1218px) {
        margin-top: 5px;
        margin-bottom: 40px;
      }
      > div{
        padding:2px !important;
        &:nth-child(1){
          z-index:4;
            margin-left: -8px;
            @media all and (max-width: ${maxWidth}) , ${retina} {
              margin-left: -15px;
            }
        }
        &:nth-child(2){
          z-index:3;
          margin-left: -8px;
        }
        &:nth-child(3){
          z-index:2;
          margin-left: -8px;
        }
        &:nth-child(4){
          z-index:1;
          margin-left: -8px;
        }
      }
    }
  }
  .card-action-sticker{
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 34px;
    width: 62px;
    background: #f1f1f1;
    opacity: 1;
    border-radius: 12px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 0px 10px;
    > div{
      padding: 7px 0px 0px 1px;
    }
    svg{
      width: 13px;
      height: 16px;
      g{
        opacity: 1;
      }
    }
    .share-button-group {
      background: none;
      padding: 0px;
      .icon-mode{
        .icon-mode-text {
          display: none;
        }
        .seperator {
          display: none;
        }
        .share-icon{
          svg {
              height: 18px;
              width: 15px;
              min-width: 15px;
              margin-top: 2px;
            g {
              fill: rgb(66, 75, 80);
              }
            &:hover {
              g {
                fill: ${primaryColor};
              }
            }
          }
        }
      }
    }
    .delt-div {
      padding: 0px 7px 0px 13px;
      svg {
        min-width: 13px;
        margin-top: 6px;
      }
    }
  }
`;

const CustomBadge = styled(Badge)`
  .ant-badge-multiple-words {
    padding: 0px 0px;
  }
  .ant-badge-count span {
    margin-top: 0px;
  }
  .ant-badge-count{
    z-index: 4;
    font-size: 11px;
    font-family: ${primaryFont};
    background:#424B50;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 
    height: 28px;
    width: 28px;
    position: absolute;
    top: 15px;
    right: -5px;
    border-radius: 14px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 10px;
      height: 29px;
      width: 29px;
      min-height: 29px;
      min-width: 29px;
      top:15px;
      border-radius: 15px;
      right: -6px;
    } 
  }
`;

const PopupModal = styled(Modal)`
  .ant-modal-content{
    .ant-modal-footer{
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
`;

const BuyerGroupCard = function BuyerGroupCard(props: Props) {
  const {
    groupPersona,
    persons,
    groupName,
    companies,
    groupId,
    members,
    isShared,
    sharedBy,
    disableNavigation,
    isSearchPage,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const host = window.location.href;

  const deleteLoader = useSelector(selectDeletingLoader);
  const groupShared = useSelector(selectGroupShared);

  const flurryObject = useSelector(selectCompanyDetailFlurry);
  const isCalled = useSelector(selectCompanyDetailsCall);
  const disclessPersonality = useSelector(selectDisclessPersonality);

  useEffect(() => {
    if (isModalVisible && !deleteLoader) {
      setIsModalVisible(deleteLoader);
    }
  }, [deleteLoader]);
  const handleOk = () => {
    if (groupId && !deleteLoader) {
      dispatch(actions.deleteGroup({ groupId }));
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const deleteGroup = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    setIsModalVisible(true);
    dispatch(flurryActions.callFlurryEvent(
      valueToObject(host.includes(`${PEOPLE_ROUTE}`) ? 'buyer_group_delete_group_intent' : 'delete_bgroup_intent', 0, {
        group_id: groupId,
        host,
      }),
    ));
  };

  const redirect = (groupID = 0) => {
    if (groupID && !disableNavigation) {
      navigate(`${GROUP_DETAIL_ROUTE}${groupID}`);
    }
    if (isSearchPage) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('searchPage_buyer_group_clicked', 0, {
          group_id: groupId,
          host,
        }),
      ));
    }
  };

  const shareGroupHandler = (check: boolean) => {
    if (check) {
      dispatch(actions.setSelectedGroups([{ name: groupName || '', id: groupId || 0 }]));
    }
  };

  useEffect(() => {
    if (groupShared) {
      dispatch(actions.setSelectedGroups([]));
    }
  }, [groupShared]);

  return (
    <>
      <PopupModal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} centered cancelText="No" okText="Yes" closeIcon={<CrossCircle />} width="530px">
        <p className="text_logout_popup main-message">Are you sure you want to delete the group?</p>
      </PopupModal>
      <Wrapper
        color={disclessPersonality ? nutenixGradientColor
          : parseColorGradientMixture(groupPersona || [])}
        className="buyers-card-item"
        onClick={() => redirect(groupId)}
        onKeyPress={() => redirect(groupId)}
        role="button"
        tabIndex={0}
      >
        <div className="d-flex justify-content-center flex-column align-items-center card-inner-wrapper">
          <div
            className="d-flex justify-content-center logo-image"
            aria-label="redirect-in-group"
          >
            {companies?.slice(0, 3).map((company) => ( // show 3 logo as per sajjad
              <div className="frame-logo">
                <img src={company} alt="Company" />
              </div>
            ))}
            {/* alt empty */}
          </div>
          <span
            className={`buyer-name text-truncate-two-line ${translateSkipAreas}`}
            aria-label="redirect-in-group"
          >
            {groupName}
          </span>

          <div
            className="d-flex justify-content-center user-images"
            aria-label="redirect-in-group"
          >
            {persons?.slice(0, 3).map((person: Executive) => (
              <PeopleWidgetWithOutHolo
                key={person.personId}
                logo={person.personImage}
                persona={person.archeType.color}
                diameter="30px"
                diameterSm="30px"
                diameterXsm="30px"
                margin="0"
                wrapperColor={backgroundColorParsing(person.personalityTypes)}
              />
            ))}
            <CustomBadge
              showZero={false}
              overflowCount={99}
              className={classNames({ zeroAppend: persons?.length === 0 })}
              count={members}
            >
              <span />
            </CustomBadge>
          </div>
          <div className="data-footer-main">
            {isShared ? <SharedBadge label={`Shared by ${sharedBy}`} withAbsolute /> : null}
            <div
              className="card-action-sticker"
              onClick={(e) => e.stopPropagation()}
              onKeyPress={(e) => e.stopPropagation()}
              role="button"
              tabIndex={0}
            >
              <ShareGroupPeopleModal
                onClickHandler={() => {
                  dispatch(flurryActions.callFlurryEvent(
                    valueToObject(host.includes(PEOPLE_ROUTE) ? 'peopleDashboard_buyerGroup_share_intent' : 'share_bgroup_intent', 0, {
                      ...flurryObject,
                      ...isCalled && { isCalled },
                      group_id: groupId,
                      host,
                    }),
                  ));
                  shareGroupHandler(true);
                }}
                onCancelHandler={() => shareGroupHandler(false)}
                iconMode
              />
              <div
                onClick={deleteGroup}
                onKeyPress={deleteGroup}
                role="button"
                tabIndex={0}
                className="delt-div"
              >
                <BinIconCommon />
              </div>
            </div>
          </div>

        </div>
      </Wrapper>
    </>
  );
};
BuyerGroupCard.defaultProps = defaultProps;

export default BuyerGroupCard;
