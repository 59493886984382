import { SecondaryColor, primaryFont, wMBold } from 'Utils/utilsStyle';
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  padding: 4px 10px 4px 5px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid ${SecondaryColor};
  background: #FFF;
  margin-right: 5px;
  cursor: pointer;
  max-width: fit-content;
  max-height: 25px;
  min-height: 25px;
  white-space: nowrap;
  &.group {
    img {
      height: 18px;
      width: 18px;
    }
  }
  &.document {
    .personality-holo-tag {
       border: 0px;
    }
  }
`;

const PersonalityHolo = styled.img<{ personality: string }>`
  border: 1.5px solid ${(props) => props.personality};
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

const Title = styled.h6`
  margin: 0px;
  margin-left: 4px;
  color: #001760;
  text-align: center;
  font-family: ${primaryFont};
  font-size: 12px;
  font-style: italic;
  font-weight: ${wMBold};
  line-height: normal;
  letter-spacing: -0.567px;
  max-width: 160px;
  overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
  padding-right: 3px;
`;

type Props = {
  personality: string;
  imageSrc: string;
  name: string;
  onClick: () => void;
  tagType: string;
};

const TagPill = function TagPill(props: Props) {
  const {
    personality, imageSrc, name,
    onClick, tagType,
  } = props;

  return (
    <StyledWrapper
      className={`tagged-pill ${tagType}`}
      onMouseDown={(e: React.MouseEvent) => {
        onClick();
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <PersonalityHolo src={imageSrc} alt="image" personality={personality} className="personality-holo-tag" />
      <Title>{`@${name}`}</Title>
    </StyledWrapper>
  );
};

export default TagPill;
