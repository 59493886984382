import React from 'react';

type Props = {
  color?: string,
  isMob?: boolean,
};
const defaultProps = {
  color: '#fff',
  isMob: false,
};

const ToggleIconClose = function ToggleIconClose(props: Props) {
  const { color, isMob } = props;
  return (
    <div>
      {isMob ? (
        <svg width="14" height="52" viewBox="0 0 14 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_1146_8962)">
            <path d="M0 13C0 9.68629 2.68629 7 6 7H12V45H6C2.68629 45 0 42.3137 0 39V13Z" fill="#001760" />
          </g>
          <path d="M9.85627 25.9625C9.84809 25.7175 9.74938 25.484 9.57892 25.3064L4.73784 20.3074C4.3514 19.9088 3.71218 19.8962 3.31008 20.2793C2.90798 20.6623 2.89529 21.296 3.28172 21.6946L7.44833 26L3.28172 30.3054C2.89529 30.704 2.90798 31.3377 3.31008 31.7207C3.71218 32.1038 4.3514 32.0912 4.73784 31.6926L9.57892 26.6936C9.76754 26.4973 9.86764 26.2334 9.85627 25.9625Z" fill="white" />
          <defs>
            <filter id="filter0_d_1146_8962" x="-12" y="0" width="26" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1146_8962" />
              <feOffset dx="-5" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1146_8962" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1146_8962" result="shape" />
            </filter>
          </defs>
        </svg>
      ) : (

        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="56" viewBox="0 0 48 56" fill="none">
          <g filter="url(#filter0_d_603_149982)">
            <path d="M13.0002 15.7223C13.0002 11.1273 16.7252 7.40234 21.3202 7.40234H45.2402V47.9623H21.3202C16.7252 47.9623 13.0002 44.2374 13.0002 39.6423V15.7223Z" fill="#01165B" />
          </g>
          <path d="M34.8392 27.2083C34.8293 27.5056 34.7095 27.7889 34.5027 28.0044L28.6288 34.0699C28.16 34.5535 27.3844 34.5688 26.8965 34.104C26.4086 33.6392 26.3932 32.8703 26.8621 32.3867L31.9176 27.1628L26.8621 21.939C26.3932 21.4553 26.4086 20.6865 26.8965 20.2217C27.3844 19.7569 28.16 19.7721 28.6288 20.2558L34.5027 26.3212C34.7315 26.5595 34.853 26.8796 34.8392 27.2083Z" fill={color} />
          <defs>
            <filter id="filter0_d_603_149982" x="0.520245" y="0.122344" width="46.8" height="55.1186" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
              <feMorphology radius="1.04" operator="erode" in="SourceAlpha" result="effect1_dropShadow_603_149982" />
              <feOffset dx="-5.2" />
              <feGaussianBlur stdDeviation="4.16" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_603_149982" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_603_149982" result="shape" />
            </filter>
          </defs>
        </svg>
      )}
    </div>
  );
};

ToggleIconClose.defaultProps = defaultProps;
export default ToggleIconClose;
