import GilroyWhiteSvg from 'Components/common/assets/svgIcons/GilroyWhiteSvg';
import {
  maxWidth, maxWidth1300, retina, secondaryFont, wMBold,
} from 'Utils/utilsStyle';
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: calc(100% - 80px);
.gilroy-white-svg {
  width: 105px;
  height: 79px;
  transform: rotate(333deg);
  @media all and (max-width: ${maxWidth1300}) {
    width: 90px;
    height: 63px;
  }
}
.heading-empty-state {
  font-family: ${secondaryFont};
  font-weight: ${wMBold};
  font-size: 22px;
  line-height: 44.418px;
  letter-spacing: -0.879px;
  color: #667982;
  @media all and (max-width: ${maxWidth}), ${retina} {
    font-size: 20px;
    line-height: 40px;
  }
  @media all and (max-width: ${maxWidth1300}) {
    font-size: 18px;
    line-height: 38px;
  }
}
`;

const EmptyStatePanel = function EmptyStatePanel() {
  return (
    <StyledWrapper className="history-empty-panel">
      <GilroyWhiteSvg />
      <h1 className="heading-empty-state">Begin your conversation!</h1>
    </StyledWrapper>
  );
};

export default EmptyStatePanel;
